div.poetry {
  padding: 30px 40px;
  background: $tm-background-blue;
  .theory-component {
    margin: 0;
  }
  .readspeaker-button {
    margin-top: 0;
  }
  h2,h3,h4 {
    font-weight: bold;
    font-size: 1.25em;
    margin-bottom: 0.8em;
  }
  .stanza {
    margin-bottom: 20px;
    .verseline {
      display: block;
    }
  }
  cite {
    font-weight: 200;
    line-height: 1.5;
    font-size: 0.8em;
    padding-left: 6em;
    margin-top: 0.3em;
    color: $asset-caption-text-color;
    width: 100%;
    display: block;
    text-align: right;
  }
}
