.asset {
  display: table;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
  position: relative;
  margin-bottom: 1rem;
  max-width: $image-max-width;
}

.asset__grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  &--two-columns {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  align-items: flex-end;
  gap: 1rem;
}

.asset__image-copyright {
  line-height: 0;
  cursor: pointer;
  position: absolute;
  right: 0.25rem;
  bottom: 0.25rem;
}

.asset--audio,
.asset--video,
.asset--equation {
  width: 100%;
  min-width: 18rem;
}

.asset--drawing-interaction {
  max-width: $interaction-image-max-width;
  width: 100%;
  @include tablet() {
    width: auto;
  }
}

.asset__image {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  background-color: $tm-neutral-black;
}

.asset__image-container {
  position: relative;
}

.asset__zoom-trigger {
  cursor: zoom-in;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.asset__video-placeholder {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  background: $audio-background-color;

  .bb_iawr {
    position: absolute !important;
    top: 0;
    left: 0;
  }
}

.asset__audio-placeholder {
  width: 100%;
  height: $audio-control-height !important;
  overflow: hidden;
  background-image: none;
  background: $audio-background-color;

  .bb_iawr,
  .bb-media {
    // Needs to be adjusted for mobile TODO
    height: $audio-control-height !important;
  }
}

.asset--wide {
  clear: both;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  width: auto;
  max-width: none !important;

  .is-breakpoint-mobile & {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: auto;
  }

  @include tablet() {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .has-reading-progress & { margin-left: 0; }
}

.caption {
  display: table-caption;
  caption-side: bottom;
  font-size: 0.8125rem;
  padding: 0.75rem 0;
  line-height: 1.5;
  background-color: $tm-neutral-light;
  font-style: normal;
  .section--example & {
    background-color: $example-caption-background-color;
  }
  .section--case & {
    background-color: $case-caption-background-color;
  }
  .section--explanation & {
    background-color: $explanation-caption-background-color;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }

  .caption__meta {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding: 0 0.75rem 0 0;
    margin-bottom: 0.75rem;
  }

  .caption__body {
    display: inline-block;
    padding: 0 0.75rem;
    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .caption__title {
    font-size: 1em;
    line-height: 1.5;
    margin: 0 0 0.15rem 0;
    color: $body-color;
  }

  .caption__label,
  .caption__title {
    font-weight: $weight-bold;
  }

  .caption__label {
    flex-shrink: 0;
    padding: 0.2em 2em;
    margin-top: -0.15em;
    text-transform: uppercase;
    font-size: 0.875em;
    font-weight: $weight-bold;
    color: $white;

    background-color: $tm-neutral-black; // Override per chapter index
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
  }

  .caption__description,
  .caption__source {
    color: $asset-caption-text-color;
  }

  .caption__description {
    margin-bottom: 0.25rem;
  }

  .caption__source {
    margin-bottom: 0.75rem;
    padding: 0 0.75rem;
    text-align: right;
    font-size: 0.75em;

    a { display: inline-block; }

    &:not(:first-child) {
      padding-top: 0;
      margin-top: -0.375rem;
    }
  }

  .caption__readspeaker {
    margin-left: auto;
  }
}

.caption--top {
  caption-side: top;
}

// Equations rendered by MathJax.js
math { display: none; } // Hide math elements before MathJax is initialized
.MathJax_SVG_Display svg { max-width: 100%; }

.asset__equation-symbols {
  width: unset;
  margin: baseline(0.5) 0;

  th, td {
    vertical-align: baseline;
    font-size: 0.8125rem;
    padding: baseline(0.125) 0;
  }

  th {
    font-family: $family-serif; // Use serif font for math symbols
    font-weight: $weight-bold;
    font-size: 1em;
    font-style: italic;
    text-align: left;
    padding-right: baseline(0.5);
  }
}

.question__interaction {
  .asset {
    margin-bottom: 0;
    .MathJax_SVG_Display {
      margin: 0;
    }
  }
}
