.my-content__container {
  max-width: $tablet;
  margin: 0 auto;

  padding-left: 1rem;
  padding-right: 1rem;
  @include tablet-landscape() {
    padding-left: 0;
    padding-right: 0;
  }
}

.my-content__item-container {
  position: relative;
  transition: transform 0.1s ease-in-out;
  &:hover,
  &:active {
    color: $body-color;
    transform: scale(1.025);
  }
}

.my-content__item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: $white;
  border: 0.25rem solid $tm-neutral;
  border-radius: 0.5rem;
  overflow: hidden;
  color: $body-color;
}

.my-content__item-icon {
  flex-shrink: 0;
  flex-grow: 0;
  width: 5rem;
  background-color: $tm-blue;
  color: $white;
  font-size: 2rem;

  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 25.5 25.5' xml:space='preserve'%3E%3Cellipse fill='none' transform='matrix(9.845139e-02 -0.9951 0.9951 9.845139e-02 -1.1787 24.1954)' class='st0' cx='12.8' cy='12.7' rx='12.8' ry='12.8'%3E%3C/ellipse%3E%3Cpath fill='%23ffffff' d='M20.8,6.1h-0.9V5.2c0-0.3-0.2-0.6-0.6-0.6c0,0,0,0,0,0c-0.3,0-0.6,0.3-0.6,0.6v1.2l-0.2,0.3l0,0 c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6c0,0,0,0,0,0v1.2L17,8.2C13.9,5.5,9.3,5.7,6.5,8.6c-3,3.1-3,7.9,0,11 c3,3,7.8,3.1,10.8,0.1c0,0,0.1-0.1,0.1-0.1c2.9-2.9,3-7.5,0.4-10.6l0.3-0.3h1.2c0.3,0,0.6-0.3,0.5-0.7c0-0.3-0.2-0.5-0.5-0.5l0,0 l0.3-0.2h1.2c0.3,0,0.6-0.3,0.5-0.7C21.3,6.3,21.1,6.1,20.8,6.1 M13.5,11.8c-1.1-0.7-2.4-0.5-3.3,0.4c-1,1.1-1,2.8,0,3.8 c1,1,2.7,1.1,3.8,0c0,0,0,0,0,0c0.9-0.9,1-2.3,0.4-3.4l1.6-1.6c1.5,2,1.4,4.8-0.4,6.6c-1.9,1.9-5,1.9-6.9,0c0,0,0,0,0,0 c-1.9-1.9-1.9-5.1,0-7c1.8-1.7,4.6-1.9,6.5-0.4L13.5,11.8z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px 48px;
}

.my-content__item-title {
  padding: 0.25rem 1.5rem;
  min-height: 4.75rem;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}

