.button--inline-audio {
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1rem;
  &-playing i {
    animation: pulse-fast 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

@keyframes pulse-fast {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}


