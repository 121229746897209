$carousel-controls-color: $base-text-color;

.carousel {
	.carousel__controls {
		color: $carousel-controls-color;
	}

  .carousel__arrow {
    cursor: pointer;
    font-size: 3rem;
    position: absolute;
    margin-top: -0.1875rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel__arrow--left {
    left: 1rem;
  }

  .carousel__arrow--right {
    right: 1rem;
  }
}

.carousel .carousel__slides {
  align-items: center;
  display: flex;
  margin: 0;
}
.carousel--align-top .carousel__slides {
  align-items: flex-start;
}

.carousel .carousel__slide {
  align-items: center;
  display: flex;
  justify-content: center;
	margin: 0 !important;
  padding: .25rem 0;

  .question-index__link {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
}

.question-index--simplified {
	flex-basis: calc(100% - 14rem);
	flex-grow: 0;
	flex-shrink: 1;
  height: 100%;
  margin-right: 2rem;
  margin-top: 2px;
	overflow: hidden;
	width: 100%;
}

.carousel-navigation {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-navigation-item + .carousel-navigation-item {
  margin-left: 1rem;
  @include mobile() {
    margin-left: 1rem;
  }
}

.carousel-navigation-item {
  cursor: pointer;
  color: $white;
  font-family: $family-serif;
  font-size: 1rem;
  padding: 0.25rem;
  line-height: 100%;
  font-weight: normal;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-basis: auto;
  min-width: 2rem;
  min-height: 2rem;
  &__number {
    display: flex;
    flex-shrink: 0;
    min-width: 1.5rem;
    padding-top: 0.25rem;
    min-height: 1.5rem;
    align-items: center;
    background-color: $white;
    color: $tm-blue;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    justify-content: center;
  }
  &__title {
    display: block;
    padding: 0.25rem 0.25rem 0 0.5rem;
    min-width: 0;
    margin: 0;
    @include truncate-text();
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  &--active {
    background-color: rgba(255, 255, 255, 0.4);
    font-weight: bold;
    .carousel-navigation-item__number {
      color: $white;
      background-color: $tm-blue;
    }
  }
}

.carousel-navigation-item--with-number .carousel-navigation-item__title {
  @include mobile() {
    display: none;
  }
}
