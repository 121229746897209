// The summary displaying the score, start/end times and badge
.selftest-results {
  background-color: $tm-neutral-light;
  padding: 1rem;

  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include tablet() {
    flex-direction: row;
    align-items: center;
    margin-left: 2.5rem;
  }

  &--simplified {
    background-color: transparent;

    .page-content {
      flex: 1;
      padding: 0 4rem;
    }
  }
}

.classification-results {
  background-color: $tm-neutral-light;
}

.classification-results__table {
  td, th {
    padding: 0.25rem;
  }
}


.selftest-results__times {
  margin-top: 1rem;

  @include tablet() {
    margin-top: 0;
    width: 12.5rem;
  }
}

.selftest-results__time {
  display: flex;
  align-items: flex-start;
  color: $tm-neutral-extraheavy;
  font-size: 0.875em;

  .icon {
    padding-top: 0.33em;
  }
}

.selftest-results__score {
  padding-left: 5.375rem;
  font-size: 0.875rem;

  @include tablet() {
    padding-left: 6.25rem;
    font-size: 1rem;
  }
}

.selftest-results__badge {
  position: absolute;
  background-repeat: no-repeat;

  background-size: 4.375rem;
  height: 4.375rem;
  width: 4.375rem;

  @include tablet() {
    background-size: 8.75rem;
    height: 8.75rem;
    width: 8.75rem;
    top: 50%;
    margin-top: -4.375rem;
    left: -3rem;
  }

  &--perfect { background-image: url(../images/badge-perfect.svg); }
  &--good { background-image: url(../images/badge-good.svg); }
  &--satisfactory { background-image: url(../images/badge-satisfactory.svg); }
  &--unsatisfactory { background-image: url(../images/badge-unsatisfactory.svg); }
  &--poor { background-image: url(../images/badge-poor.svg); }
}

.selftest-results__stars-bar {
  display: inline-block;
  position: relative;
  width: 11rem;
  height: 2.0625rem;
  background-color: $tm-neutral;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: url('../images/star-progress-mask.svg');
  }
}

.selftest-results__stars-meter {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0; // width overwritten by inline style depending on score
  z-index: 1;

  @include score-background();
}
