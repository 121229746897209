.speaking-interaction__content {
  display: inline-block;
}

.speaking-interaction__phrase {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.speaking-interaction-word {
  display: inline-flex;
  position: relative;
  line-height: 1;
  font-weight: $weight-bold;
  font-size: 1.75rem;
  padding: 0.15em 0;
  border-bottom: 4px solid transparent;
}

.speaking-interaction-word--incorrect,
.speaking-interaction-word--correct {
  cursor: pointer;
}

.speaking-interaction-word--incorrect {
  border-bottom-color: $exercise-incorrect-dark;
  &.speaking-interaction-word--selected,
  &:hover {
    background-color: $exercise-incorrect-light;
  }
}

.speaking-interaction-word--correct {
  border-bottom-color: $exercise-correct-medium;
  &.speaking-interaction-word--selected,
  &:hover {
    background-color: $exercise-correct-light;
  }
}

.speaking-interaction-word--not-detected {
  border-bottom-style: dotted;
}

.record-button {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.record-button__feedback-message {
  margin-top: 0.25rem;
  text-align: center;
}

.speaking-word-feedback {
  margin-top: -0.33rem;
  .score-label {
    font-size: 1.1rem;
    padding: 0.25em 0.5em;
    margin-right: 0.5rem;
  }
}

.speaking-word-feedback__word {
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: $weight-bold;
}

.speaking-word-feedback__phonemes {
  display: flex;
}

$speaking-word-score-categories: (
  poor: $exercise-incorrect-dark,
  unsatisfactory: $exercise-incorrect-dark,
  satisfactory: $exercise-correct-medium,
  good: $exercise-correct-medium,
  perfect: $exercise-correct-medium
);

.speaking-word-feedback__phoneme {
  flex: 1 0 auto;
  height: 4px;
  margin-right: 0.25rem;
  &:last-child { margin-right: 0; }
  @each $category, $color in $speaking-word-score-categories {
    &--#{$category} {
      background-color: $color;
    }
  }
}

