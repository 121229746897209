$_padding: 0.75rem;

.offsetted-anchor {
  display: block;
  position: absolute;
  visibility: hidden;
  left: 0;
  top: -($navbar-height + $_padding);
}

.offsetted-anchor--with-tabs {
  top: -($navbar-height + $theory-tabs-height + $_padding);
}

// The scroll parent in splitscreen does not contain the navbar: use padding only
.offsetted-anchor--splitscreen {
  top: -$_padding;
}
