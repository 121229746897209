.graphic-associate {
  position: relative;
  display: inline-block;
  max-width: $interaction-image-max-width;
}

.graphic-associate__board {
  position: absolute;
  user-select: none;
  overflow: visible;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.graphic-associate__image {
  max-width: 100%;
  height: auto;
  width: auto;
}

.graphic-associate__hotspot {
  fill: $white;
  stroke: $body-color;
  stroke-width: 8;
  transition: stroke-width 200ms;

  &--editable { cursor: pointer; }
  &--focus {
    stroke-width: 8;
    stroke: $hotspot-focussed-color;
  }
  &--active {
    stroke: $interaction-active-color;
    stroke-width: 14;
  }
}

.graphic-associate-line {
  stroke: $body-color;
  stroke-width: 8;
  stroke-linecap: round;

  &--correct,
  &--predefined {
    stroke: $exercise-correct-medium;
  }

  &--incorrect { stroke: $exercise-incorrect-medium; }
  &--inactive { pointer-events: none; }
  &--editable { cursor: pointer; }
  &--selected { stroke: $primary-button-color; }
  &--drawing { stroke: $interaction-active-color; }

  &--missed {
    stroke: $exercise-correct-medium;
    stroke-linecap: square;
    stroke-dasharray: 8;
    stroke-width: 4;
  }
}

.graphic-associate-line__icon {
  fill: $white;
  &--missed { fill: $exercise-correct-medium; }
}

.graphic-associate-line__remove {
  cursor: pointer;

  .graphic-associate-line__remove-circle {
    fill: $primary-button-color;
    transition: fill 200ms;
  }

  &:hover {
    .graphic-associate-line__remove-circle {
      fill: darken($primary-button-color, 7.5%);
    }
  }
}

.graphic-associate-line__feedback {
  &--correct { fill: $exercise-correct-medium; }
  &--incorrect { fill: $exercise-incorrect-medium; }
  &--missed {
    fill: $exercise-correct-light;
    stroke: $exercise-correct-medium;
    stroke-dasharray: 8;
    stroke-width: 4;
  }
}
