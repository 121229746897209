
nav.navbar {
  &--my-edition {
    background-color: rgb(105, 148, 186);
  }
}

.navbar__my-edition-logo {
  font-family: $family-sans-serif;
  color: $white;
  font-size: 1.5rem;
  height: 50px;
  line-height: 50px;
}

.no-methods {
  font-style: italic;
}

.stream-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stream-progress__method-card {
  flex-shrink: 0;
  align-self: flex-start;
}

.stream-progress__content {
  align-self: flex-start;
  flex-grow: 1;
  padding-top: 2px;
  .paragraph__score {
    padding: 2px 0;
    &--none {
      background-color: $tm-neutral-pale;
    }
  }
}

.stream-progress__breadcrumb-item:after {
  margin-left: -5px;
  margin-right: 0;
}
