$block-regular-background-color: #d6e0e6;
$block-regular-border-color: $theme-chapter-1-color;
$block-started-background-color: #FFFFFF;
$block-completed-background-color: #C2E2F0;

.simplified-chapter-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  .simplified-chapter-block__body {
    transform: scale(1);
    transition: transform .35s ease-in-out;
  }

  .share-item--simplified {
    transition: all .35s ease-in-out;
    left: 100%;
    top: 0%;
    .share-button__icon {
      position: absolute;
      top: .5rem;
      right: .5rem;
    }
  }

  &:hover {
    .simplified-chapter-block__body {
      transform: scale(1.03);
    }
    .share-item--simplified {
      left: 101.5%;
      top: -1.5%;
    }
  }
}

.simplified-chapter-block__body {
  align-items: center;
  color: $base-text-color;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: 6.75rem;
  position: relative;
  width: 100%;

  &-bg {
    background-color: $block-regular-background-color;
    border: .5rem solid $block-regular-border-color;
    border-radius: 1rem;
    content: '';
    display: flex;
    overflow: hidden;
    padding-bottom: calc(100% - 20px);
    width: 100%;
  }
}

.simplified-chapter-block__image {
  width: 100%;
}

.simplified-chapter-block__body-inner {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
}

.simplified-chapter-block--started .simplified-chapter-block__body-bg {
  background-color: $block-started-background-color;
}

.simplified-chapter-block--completed .simplified-chapter-block__body-bg {
  background-color: $block-completed-background-color;
}

.simplified-chapter-block--regular .simplified-chapter-block__body {
  &-bg {
    border-color: #91989c !important;
  }
}

.simplified-chapter-block__actions,
.simplified-chapter-block__header {
  width: 100%;
  text-align: center;
  flex-shrink: 0;
}

.simplified-chapter-block__header-title {
  color: #484848;
  font-size: 15px;
  font-weight: bold;
}

.simplified-chapter-block__actions {
  min-height: 74px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: $tm-background-blue;
}

.simplified-chapter-block__title {
  font-family: $family-sans-serif;
  font-size: 26px;
  font-weight: $weight-bold;
  text-align: center;

  &--medium {
    font-size: 24px;
  }
}

.simplified-chapter-block--paragraph {
  .simplified-chapter-block__body-bg {
    border-radius: .75rem;
    border-width: .25rem;
  }
}
