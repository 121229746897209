@media print
{
  body.with-print-content {
    *:not(.print-content--visible),
    .print-content--hidden {
      display:none !important;
    }
  }
}
@media screen
{
  body.with-print-content {
    .print-content-container {
      display: none;
    }
  }
}

