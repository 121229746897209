// Extented text exercise
.exercise-input-extended-locked {
	border-left: 30px solid $base-text-color;
	display: block;
	position: relative;
	&:before {
		color: #fff;
		content: $icon-document;
		display: block;
		font-family: $icon;
		font-size: 1.2em;
		height: 100%;
		left: -30px;
		top: 5px;
		position: absolute;
		text-align: center;
		vertical-align: top;
		width: 30px;
	}
}
