.js-vms-container.asset__audio-placeholder .bb-wai-aria,
.js-vms-container.asset__audio-placeholder .bb-layer.bb-content-layer,
.js-vms-container.asset__audio-placeholder .bbv-autoplay-only{
  display: none;
}
.js-vms-container.asset__audio-placeholder,
.js-vms-container.asset__audio-placeholder .bbv,
.js-vms-container.asset__audio-placeholder .bb_wrapper .bb-layer,
.js-vms-container.asset__audio-placeholder .bb_iawr{
  overflow: visible!important;
}
