.tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  list-style: none;

  margin-bottom: 0;
  border-bottom: 1px solid $tm-neutral;
}

.tabs__item {
  position: relative;
  z-index: 10;
  margin-bottom: -1px;
}

.tabs--vertical {
  max-width: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 0 none;
  .tabs__item {
    margin-bottom: 0;
  }
}

.tabs__link {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0.75rem 1rem;
  background-color: transparent;
  color: $body-color;
  border-bottom: 1px solid transparent;
  &:hover {
    background-color: $tm-neutral-light;
    border-color: $tm-neutral;
  }

  outline: none;
  &:focus { outline: none; }
}

.tabs__truncated-content {
  display: block;
  @include truncate-text();
}

.tabs__link--active {
  font-weight: $weight-bold;
  color: $white;

  background-color: $tm-neutral-extrablack;
  border-color: $tm-neutral-extrablack;

  &:hover,
  &:active {
    color: $white;
    background-color: $tm-neutral-extrablack;
    border-color: $tm-neutral-extrablack;
  }
}

.tabs--theory-page {
  position: fixed;
  top: $navbar-height;
  min-height: $theory-tabs-height;
  width: 100%;
  z-index: 101;
  background-color: $white;
  padding: 1.125rem 1.5rem 0 3rem;
}
