
.modal-container {
  max-height: min(600px, 100%);

  &:has(tmwc-content-jojoschool-video) {
    width: 900px;
    max-height: min(640px, 100%);
  }

  &--no-max-height {
    max-height: 90%;
  }
}
