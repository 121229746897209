.graphic-hotspot-svg__container {
  display: inline-block;
  position: relative;
  line-height: 0;
  max-width: $interaction-image-max-width;
}

.graphic-hotspot-svg__asset {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: $image-max-height;

  // make sure none of the elements in the <svg> has pointer events by default;
  // (there may be elements in front of hotspots)
  & > * {
    pointer-events: none;
  }
}

.graphic-hotspot-svg__hotspot--selectable {
  cursor: pointer;
  // enable pointer events for hotspot:
  pointer-events: all;
}

.graphic-hotspot-svg__hotspot--selected {
  // note: !important flag is needed here because styling in a "foreign" svg document needs to be overwritten
  fill: $tm-neutral-dark !important;
}

.graphic-hotspot-svg__hotspot--correct {
  fill: $exercise-correct-dark !important;
}

.graphic-hotspot-svg__hotspot--predefined {
  fill: $exercise-correct-dark !important;
}

.graphic-hotspot-svg__hotspot--incorrect {
  fill: $exercise-incorrect-dark !important;
}

.graphic-hotspot-svg__hotspot--missed {
  fill: $exercise-correct-light !important;
  stroke: $exercise-correct-dark !important;
  stroke-dasharray: 2 !important;
  stroke-width: 1 !important;
}

.graphic-hotspot-svg__hotspot-content--inactive {
  color: $tm-neutral-extraheavy;
}

.graphic-hotspot-svg__hotspot-content--selected,
.graphic-hotspot-svg__hotspot-content--incorrect {
  color: $white;
}

.graphic-hotspot-svg__hotspot-content--correct {
  color: $exercise-correct-light;
}

.graphic-hotspot-svg__hotspot-content--predefined {
  color: $exercise-correct-light;
}

.graphic-hotspot-svg__hotspot-content--missed {
  color: $exercise-correct-dark;
}

// note: for the "count" interaction (GraphicHotspotCount), the missed state has an alternative styling:
.graphic-hotspot-svg__hotspot--count-missed {
  fill: $exercise-incorrect-light !important;
}

.graphic-hotspot-svg__hotspot-content--count-missed {
  color: $exercise-incorrect-dark;
}
