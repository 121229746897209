
.topic,
.section,
.subsection {
  position: relative;
  clear: both;
}

.topic { margin-bottom: 3rem; }
.section { margin-bottom: 2rem; }
.subsection { margin-bottom: 1rem; }

.section__origin {
  font-weight: 200;
  font-size: 0.8rem;
  padding-top: 1rem;
  width: 100%;
  display: block;
  text-align: right;
}

.section--intro,
.streamer {
  font-weight: $weight-light;
  font-size: 1.15em;
  line-height: 1.5;

  .is-breakpoint-mobile & { font-size: 1.20em; }
  @include tablet() { font-size: 1.33em; }
}

.section--intro {
  border-bottom: 1px solid $tm-neutral-light;
  & > *:last-child { margin-bottom: 0; }
  padding-bottom: 1.5rem;
}

.section--example,
.section--interaction-example,
.section--explanation,
.section--case,
.section--reading,
.topic--reading {
  padding: 1.5rem !important;
  @include clearfix();
  & *:last-child { margin-bottom: 0; }
}

.section--example,
.section--interaction-example,
.section--explanation,
.section--case {
  h3 { font-family: $family-serif; }

  &.section--with-origin {
    padding: 2.25rem 1.5rem 1.25rem 1.5rem !important;
  }
}

.section--case {
  color: $case-text-color;
  background-color: $case-background-color;
  a {
    color: $case-link-color;
  }
}

.section--explanation {
  color: $explanation-text-color;
  background-color: $explanation-background-color;
  a {
    color: $explanation-link-color;
  }
}

.section--example,
.section--interaction-example {
  color: $example-text-color;
  background-color: $example-background-color;
  a {
    color: $example-link-color;
  }
}

.section-grid {
  display: flex;
  flex-direction: column;

  @include tablet() {
    flex-direction: row;
  }

  .section-grid__main {
    order: 2;
    @include tablet() {
      order: 1;
      flex: 1 1 60%;
    }
  }

  .section-grid__side {
    order: 1;

    @include tablet() {
      order: 2;
      flex: 0 0 40%;
      padding-left: 1rem;
    }
  }

  .is-breakpoint-mobile & {
    flex-direction: column;
    .section-grid__main { order: 2; }

    .section-grid__side {
      order: 1;
      flex: 1 1 auto;
      padding-left: 0;
    }
  }
}

.section > .section--example,
.section > .section--interaction-example,
.section > .section--explanation,
.section > .section--case {
  margin-top: 1.5rem;
  &:first-child { margin-top: 0; }
}

.section--reading,
.topic--reading {
  background-color: $reading-background-color;

  h2 { margin-top: 0 !important; }

  cite {
    display: block;
    margin-top: 1.5rem;
    text-align: right;
    font-weight: 200;
    line-height: 1.5;
    font-size: 0.8em;
    color: $asset-caption-text-color;
  }
}

.topic--theory {
  padding-right: 2.5rem;
}
