.error-page {
  padding: 1.5rem;
  display: flex;
  max-width: 980px;
  margin: 0 auto;
  flex-direction: column;

  @include tablet() {
    padding-top: 3rem;
    padding-bottom: 3rem;
    flex-direction: row;
  }
}

.error-page__title {
  font-family: $family-serif;
}

.error-page__illustration {
  max-width: 16rem;
  margin-bottom: 1rem;
  @include tablet() {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 3.125rem;
    margin-bottom: 0;
  }
}

.error-page__logo {
  max-height: 45px;
}
