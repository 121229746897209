.hero {
  background-color: $white;
  position: relative;

  .hero__body {
    position: relative;
    z-index: 10;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.hero--image {
  background-color: $tm-neutral-extrablack;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
  }
}

.hero--large {
  .hero__body {
    @include tablet() {
      padding-top: 12.5rem;
      padding-bottom: 12.5rem;
    }

    .is-breakpoint-mobile &,
    .is-breakpoint-tablet & {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}
