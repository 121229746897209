$black: #000 !default;
$white: #fff !default;
$dark-grey: #252525 !default;
$anthracite: #343434;

$base-text-color: #32383c !default;
$base-text-color-hover: lighten($base-text-color, 10%) !default;

// ThiemeMeulenhoff colors
$tm-blue: #008fd3 !default;
$tm-blue-dark: #1c437f !default;
$tm-green: #96bd0d !default;
$tm-red: #e2004f !default;
$tm-orange: #f19300 !default;

// All you can eat
$ayce-blue: rgb(27, 117, 226);

// poor: red
$tm-poor: #ff4f4f !default;
$tm-poor-dark: #f13939 !default;
$tm-poor-heavy: #e92f2f !default;
$tm-poor-text: $white !default;

// unsatisfactory: orange
$tm-unsatisfactory: #ff9b19 !default;
$tm-unsatisfactory-dark: #ff9409 !default;
$tm-unsatisfactory-text: #a72700 !default;

// good: green
$tm-good: #5cc652 !default;
$tm-good-dark: #4ab643 !default;
$tm-good-heavy: #39ab4e !default;
$tm-good-light: #d1f2c9 !default;

// perfect: blue
$tm-perfect: #06d0ed !default;
$tm-perfect-dark: #02bae3 !default;

// archived: gray
$tm-archived: #dcdde0 !default;
$tm-archived-dark: #ced0d4 !default;
$tm-archived-text: #737875 !default;

// empty
$tm-empty-dark: #f4f9fa !default;

// text colours
$tm-text-white: $white !default;
$tm-text-white-hover: darken($tm-text-white, 10%) !default;
$tm-text-gray: $anthracite !default;
$tm-text-red: #fc4a00 !default;

// background colors
$tm-background-gray: #c9d3dd !default;
$tm-background-blue: #e5eef5 !default;

// greys
$tm-neutral-pale: #f2f6fa !default;
$tm-neutral-light: #e6eef5 !default;
$tm-neutral: #cfe0e9 !default;
$tm-neutral-dark: #b7c6cf !default;
$tm-neutral-heavy: #90979b !default;
$tm-neutral-extraheavy: #6e7477 !default;
$tm-neutral-black: #33383a !default;
$tm-neutral-extrablack: #222728 !default;
$asset-caption-text-color: #6b7176 !default;

$tm-yellow-light: #fff5bf !default;
$tm-yellow-dark: #ffbc00 !default;

$text-shadow-light: 1px 1px 1px rgba(0, 0, 0, 0.6) !default;

$tm-purple-dark: #6c54c2 !default;
$tm-purple: #9881F1 !default;
$tm-purple-light: #cfc7ee !default;
$link-color: #159ef5 !default;
$question-link-color: $base-text-color !default;
$question-link-background: $tm-neutral-pale !default;
$question-link-hover-color: $tm-text-red !default;
$question-link-hover-background: $tm-neutral-light !default;

$example-background-color: $tm-yellow-light !default;
$example-caption-background-color: #ffef98 !default;
$example-text-color: $base-text-color !default;
$example-link-color: $link-color !default;

$case-background-color: $tm-neutral-light !default;
$case-caption-background-color: $tm-neutral !default;
$case-text-color: $base-text-color !default;
$case-link-color: $link-color !default;

$explanation-background-color: #d3f5d1 !default;
$explanation-caption-background-color: #bfe7bc !default;
$explanation-text-color: $base-text-color !default;
$explanation-link-color: $link-color !default;

$term-popover-text-color: #eef7ff !default;
$term-popover-background-color: $tm-neutral-black !default;

$reading-background-color: #f5f8fb !default;

// Exercise feedback colors
$exercise-correct-dark: #098a16 !default;
$exercise-correct-medium: $tm-good !default;
$exercise-correct-light: #d7f6d6 !default;
$exercise-correct-very-light: mix($exercise-correct-light, $white, 25%) !default;

$exercise-incorrect-dark: #6c54c2 !default;
$exercise-incorrect-medium: $exercise-incorrect-dark !default;
$exercise-incorrect-light: #cfc7ee !default;

$upload-dropzone-background: $tm-yellow-light !default;
$upload-dropzone-border: #ccaf3c !default;

$audio-background-color: #404447 !default;

$yellow-link-active: #fffea1 !default;

$tm-sidebar-background: #ffeb7f !default;
$tm-sidebar-text-color: #53503d !default;
$tm-sidebar-active-background: $anthracite !default;
$tm-sidebar-active-text-color: #b1b7bb !default;

$conversation-speaker-colors: (
  speaker-0: $tm-blue-dark,
  speaker-1: $tm-green,
  speaker-2: $tm-blue,
  speaker-3: $tm-red,
  speaker-4: $tm-orange,
  speaker-5: $tm-blue-dark,
  speaker-6: $tm-green,
  speaker-7: $tm-blue,
  speaker-8: $tm-red,
  speaker-9: $tm-orange,
  speaker-10: $tm-blue-dark,
  speaker-11: $tm-green,
  speaker-12: $tm-blue,
  speaker-13: $tm-red,
  speaker-14: $tm-orange,
) !default;

$zero-width-space: '\200b';

//reset for bulma, as it messed up the body scroll lock
$body-overflow-x: inherit;
$body-overflow-y: inherit;
$body-min-width: unset;

$primary: #fa4b00 !default; // Bulma override
$body-color: $base-text-color !default;
$heading-light-color: #707380 !default;
$breadcrumb-item-color: #d3dde3 !default;
$breadcrumb-item-hover-color: $tm-blue !default;
$warning-color: #fe4e4f !default;
$success-color: $tm-good-dark !default;
$remark-tip-color: $tm-good-dark !default;
$remark-hint-color: #755dcd !default;
$remark-attention-color: $link-color !default;
$modal-background-background-color: rgba($black, 0.5) !default;
$muted-text-color: $tm-neutral-heavy !default;
$table-highlight-color: #f9f27f !default;

// Buttons
$button-font: $family-serif !default;
$button-font-size: 1.2rem !default;
$button-font-weight: 700 !default;
$button-border-radius: 0.1875rem !default;
$button-padding: 0.55em 0.75em 0.5em 0.75em !default;
$button-icon-size: 0.8em !default;
$button-icon-line-height: 0 !default;
$button-icon-margin-top: 0 !default;

$primary-button-color: #f94b1e !default;
$secondary-button-color: $tm-neutral-light !default;
$secondary-button-border: none !default;
$secondary-button-border-hover: none !default;
$secondary-button-padding: $button-padding !default;
$light-button-color: $white !default;
$disabled-button-color: #cfe1e9 !default;
$disabled-button-text-color: #b6c6cf !default;

$image-overlay-dark: rgba(0,0,0,0.65) !default;
$sticky-header-shadow: 0 0 4px rgba(0,0,0,.3) !default;

$tablet-landscape: 1023px !default;
$radius-rounded: 99rem !default;

// Matches height of play/pause controls
$audio-control-height: 1.875rem !default;

$image-max-width: 600px !default;
$image-max-height: 600px !default;

// Interactions
$hotspot-selected-color: $tm-yellow-dark !default;
$hotspot-focussed-color: $tm-blue !default;
$interaction-active-color: #36a9fc !default;
$active-input-background-color: #f7fcff !default;
$inactive-input-border-color: #eaeaea !default;
$interaction-image-max-width: 900px !default;

$input-default-color: $interaction-active-color;
$input-default-border-color: #b6c6d0;
$input-default-border-color-hover: #8f979d;
$input-default-background-color: rgba(250, 250, 250, .75);
$input-not-empty-background-color: rgba(255, 255, 255, 1);
$input-focus-border-color: $interaction-active-color;

// Navbar
$navbar-height: 3.125rem !default;
$navbar-item-height: 50px !default;

$theory-tabs-height: 4.1875rem !default;

$simplified-tip-background-color: #ffeb7F !default;
$simplified-tip-label-color: #6b6545 !default;

// User feedback
$user-feedback-background-color: #fff8d6 !default;
$user-feedback-text-color: #533c09 !default;
$user-feedback-icon-color: $tm-yellow-dark !default;

/* Theming */
$theme-chapter-1-color: #3FA535 !default;

// Question page
$question-page-header-height-mobile: 6.0625rem !default; // breadcrumb on 2 lines
$question-page-header-height: 5rem !default;
$question-page-navigation-width: 7.5rem !default;
$question-page-navigation-width-desktop: 12rem !default;
$question-title-font-weight: 700 !default;
$question-self-score-background: $tm-background-blue !default;

  // Readspeaker
$readspeaker-button-size: 2.125rem !default;
$readspeaker-icon-size: 1.5em !default;
$readspeaker-border-radius: .1875rem !default;
$readspeaker-border: none !default;
$readspeaker-background-color: $tm-neutral-pale !default;
$readspeaker-background-hover-color: $tm-neutral !default;
$readspeaker-pause-play-margin: 0 !default;

// Score label
$score-label-padding: 0.1em 0.25em !default;
$score-label-border-radius: 0 !default;

//Quickscan
$quickscan-dark-grey: #333333 !default;
$quickscan-blue: #0190d4 !default;
$quickscan-almost-white-blue: #e9f6fc !default;
$quickscan-body-color: $quickscan-dark-grey !default;