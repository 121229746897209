/* Single Choice Text QTI type */

.choice-single-text {
    list-style: none;
    margin: 0;
    li {
        list-style: none !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    li + li {
        margin-top: 1em !important;
    }
    label {
        border: 1px solid transparent;
        display: inline-block;
        padding: 0 0.3em 0 2em;
        position: relative;
    }
    .selected-answer {
        color: $exercise-incorrect-dark;
        border-bottom-color: $exercise-incorrect-light;
    }
    .selected-answer.correct,
    .selected-answer.opinion {
        background-color: $exercise-correct-light;
        border-bottom-color: transparent;
        color: $exercise-correct-dark;
    }
    .selected-answer.missed {
        background-color: none;
        border: 1px dashed $exercise-correct-dark;
        color: $exercise-correct-dark;
    }
    .selected-answer:before {
        background-color: $exercise-incorrect-dark;
        color: #fff;
        content: $icon-cross;
        font-family: $icon;
        line-height: 1.8;
        position: absolute;
        top: 0;
        bottom: -1px; // pull it over the bottom border of the parent label
        left: 0;
        text-align: center;
        vertical-align: top;
        width: 1.5em;
    }
    .selected-answer.correct:before,
    .selected-answer.opinion:before {
      background-color: $tm-good;
        content: $icon-checkmark;
    }
    .selected-answer.missed:before {
        background-color: $exercise-correct-light;
        border-right: 1px dashed $exercise-correct-dark;
        bottom: 0;
        color: $exercise-correct-dark;
        content: $icon-checkmark;
    }
    input {
        position: absolute;
        top: 4px;
        left: 5px;
    }
    .selected-answer input {
        left: -9999em;
    }
}
