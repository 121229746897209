//
// Legacy interaction styling
//
@import "interactions/qti-choice-single-text";
@import "interactions/qti-choice-single-image";
@import "interactions/qti-interaction-inputs";
@import "interactions/qti-graphic-hotspot-extra";
@import "interactions/qti-graphic-gap-multiple-match";
@import "interactions/qti/qti-choice-single-image";
@import "interactions/qti/list-additions";
@import "interactions/qti/choice-matrix";
@import "interactions/qti/drawing-interaction";
@import "interactions/qti-choice-multiple";
@import "interactions/qti/qti-choice-multiple";
@import "interactions/qti/qti-extended-text";
@import "interactions/qti/qti-hottext";
@import "interactions/qti-inline-choice";
@import "interactions/qti-order";
@import "interactions/qti/qti-text-entry";
@import "interactions/qti/word-puzzle";

@import "interactions/graphic-associate";
@import "interactions/speaking-interaction";
@import "interactions/math";
@import "interactions/column-calculation";
@import "interactions/graphic-gap-entry";
@import "interactions/graphic-hotspot-svg";
@import "interactions/option-area";
@import "interactions/gap-match";

// Temporary overwrite button text so it is at least readable (TODO restructure question buttons)
.button.button-secondary {
  color: $body-color;
}
