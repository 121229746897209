.media-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $image-overlay-dark;
  z-index: 10004;
}

.media-modal__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../images/loader.gif') no-repeat center center;
  width: 100%;
  height: 100%;
}

.media-modal__image-wrapper {
  line-height: 0;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.media-modal__image {
  max-width: 100vw;
  max-height: 100vh;
  width: auto;
  height: auto;
}

.media-modal__image--zoomable {
  cursor: zoom-in;
}

.media-modal__image--zoomed {
  cursor: zoom-out;
  max-width: unset;
  &.media-modal__image--portrait {
    max-height: unset;
    max-width: 100vw;
  }
}

.media-modal__video {
  width: 100%;
  max-width: 900px;
}
