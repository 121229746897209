.option-area {
  position: absolute;
  overflow: visible;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.option-area--no-interaction {
  pointer-events: none;
  user-select: none;
}
