
.table-of-contents {
  background-color: $tm-neutral-light;

  &__item {
    border-top: 1px solid $white;
  }

  &__link {
    padding: 0.75rem 3rem;
    color: $body-color;
    font-size: 1rem;
    display: flex;
    align-items: baseline;
  }
  &__link--level-1 {
    padding-left: 3rem + $spacer*2;
  }
  &__link--level-2 {
    padding-left: 3rem + $spacer*4;
  }
  &__link--level-3 {
    padding-left: 3rem + $spacer*6;
  }

  &__link:hover,
  &__link--active {
    background-color: $tm-neutral;
  }

  &__link--active { cursor: default; }

  &__number {
    font-family: $family-serif;
    font-weight: $weight-bold;
    white-space: nowrap;
  }

  &__header {
    position: relative;
    padding-bottom: 0.5rem;
  }

  &__title {
    padding: 1.5rem 0.5rem 1rem 3rem;
    margin: 0;
    font-weight: normal;
    font-family: $family-serif;
    color: $heading-light-color;
    @include truncate-text();
  }

  &__title,
  &__items {
    display: none;
    @include tablet-landscape() { display: block; }
    .is-toc-open & { display: block; }
  }
}
.table-of-contents__items {
  &.is-collapsed {
    display: none;
  }

  &--level-0 {
    border-bottom: 1px solid $white;
  }
}
.table-of-contents__collapse {
  position: relative;
}
.table-of-contents__collapse-icon {
  position: absolute;
  left: -20px;
  top: -13px;
}
.table-of-contents__collapse-icon--collapsed {
  display: none;
}
.target-is-collapsed {
  .table-of-contents__collapse-icon--collapsed {
    display: block;
  }
  .table-of-contents__collapse-icon--open {
    display: none;
  }
}

$toggle-button-size: 48px;
.table-of-contents__toggle-button {
  @include tablet-landscape() { display: none; }

  outline: 0;
  position: absolute;
  top: 0;

  color: $body-color;
  @include hamburger($toggle-button-size);
  span { height: 2px; }

  .is-toc-open & {
    span { display: none; }
    @include close-button($toggle-button-size, 2px, $body-color, $percentage: 43%);
    position: absolute;
    padding: 20px;
    right: 0;
    top: 50%;
    margin-top: -(divide($toggle-button-size, 2));
  }

  &:hover,
  &:active {
    background-color: transparent;
  }

  @include desktop() {
    &:hover { background-color: $tm-neutral; }
  }
}
