.flexbox {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.flexbox--column { flex-direction: column; }
.flexbox--row { flex-direction: row; }

.flex { flex: 1 1 0; }
.flex-100 { flex-basis: 100%; }
.flex-wrap--wrap { flex-wrap: wrap; }
.flex-wrap--no-wrap { flex-wrap: nowrap; }

.flex--inline { display: inline-flex; }

.flex-align-items--start { align-items: flex-start; }
.flex-align-items--end { align-items: flex-end; }
.flex-align-items--center { align-items: center; }
.flex-align-items--stretch { align-items: stretch; }

.flex-justify-content--center { justify-content: center !important; }
.flex-justify-content--between { justify-content: space-between !important; }
.flex-justify-content--end { justify-content: flex-end !important; }

.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }

$orders: 1 2 3 4 5;
@each $order in $orders {
  .order-#{$order} {
    order: $order;
  }
  @include mobile() {
    .order-#{$order}--mobile {
      order: $order;
    }
  }
  @include tablet() {
    .order-#{$order}--tablet {
      order: $order;
    }
  }
  @include tablet-only() {
    .order-#{$order}--tablet-only {
      order: $order;
    }
  }
  @include desktop() {
    .order-#{$order}--desktop {
      order: $order;
    }
  }
  @include desktop-only() {
    .order-#{$order}--desktop-only {
      order: $order;
    }
  }
}

