$column-calculation-gap-size: 2rem;

$column-calculation-crossed-line-color: $dark-grey;
$column-calculation-crossed-text-color: $dark-grey;

$column-calculation-border-color: $input-default-border-color;
$column-calculation-border-color-light: $inactive-input-border-color;
$column-calculation-font-color: $black;
$column-calculation-font-color-light: $white;
$column-calculation-input-font-color: $input-default-color;

$column-calculation-helpgap-scale: 0.8;
$column-calculation-helpgap-size: $column-calculation-gap-size * $column-calculation-helpgap-scale;
$column-calculation-helpgap-font-size: 1rem;
$column-calculation-helpgap-margin: divide(($column-calculation-gap-size - $column-calculation-helpgap-size), 2);

.column_calculation {
  @for $i from 1 through 10 {
    &--size-#{$i} {
      .column_calculation__line {
        width: $i * $column-calculation-gap-size;
        &--with-decimals {
          width: ($i * $column-calculation-gap-size) + 1rem;
        }
      }
    }
  }

  &__number {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-left: 1px;
    margin-bottom: 1px;

    &--helpgap {
      width: $column-calculation-helpgap-size;
      height: $column-calculation-helpgap-size;
      margin: $column-calculation-helpgap-margin;
      margin-left: calc(#{$column-calculation-helpgap-margin} - 1px);

      border: 1px $column-calculation-border-color solid;
      color: $column-calculation-input-font-color;
      font-size: $column-calculation-helpgap-font-size;
      text-align: center;
    }
    &--helpgap-first-decimal {
      margin-left: calc((#{$column-calculation-helpgap-margin} * 2) + 1rem);
    }
  }

  &__missed-number {
    display: inline-block;
    margin-right: 3.1rem;
  }

  &__answer-number {
    display: inline-block;
    position: relative;
  }

  &__digit-container {
    display: inline-block;
  }

  &__digit,
  &__decimal-separator {
    position: relative;
    width: $column-calculation-gap-size;
    height: $column-calculation-gap-size;

    font-family: $family-serif;
    font-size: 1.5rem;
    line-height: $column-calculation-gap-size;
    padding: 0;
    text-align: center;
    color: $column-calculation-font-color;

    border: 1px $column-calculation-border-color solid;
    margin: 0 0 0 -1px;

    &--disabled,
    &--none {
      color: $column-calculation-font-color;
      background-color: $white;
    }

    &--missed {
      border-style: dashed;
      color: $exercise-correct-dark;
      border-color: $exercise-correct-dark;
      background-color: $white;
    }

    &--correct {
      color: $exercise-correct-dark;
      border-color: $exercise-correct-medium;
      background-color: $exercise-correct-light;
    }

    &--incorrect {
      color: $exercise-incorrect-dark;
      border-color: $exercise-incorrect-dark;
      background-color: $white;
    }

    &--predefined {
      color: $exercise-correct-dark;
      border-color: $exercise-correct-medium;
      background-color: $exercise-correct-light;
    }

    &--crossed {
      color: $column-calculation-crossed-text-color;

      background: linear-gradient(to top left,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) calc(50% - 1px),
              $column-calculation-crossed-line-color 50%,
              rgba(0, 0, 0, 0) calc(50% + 1px),
              rgba(0, 0, 0, 0) 100%);
    }

    &--can-be-crossed {
      cursor: pointer;
    }

    &--empty {
      @include empty-placeholder();
    }
  }

  &__decimal-separator {
    border: 0 none;
    width: 0.8rem;
    margin-left: 0.2rem;
  }

  &__line {
    border-bottom: 1px $black solid;
    text-align: right;
    height: 6px; // margin-bottom + border-width
    margin-bottom: 6px;
    position: relative;

    > span {
      position: absolute;
      top: -0.5rem;
      right: -0.8rem;
      font-weight: normal;
    }
  }

  &__feedback {
    position: absolute;
    left: -1.6rem;
    width: 1.5rem;
    height: $column-calculation-gap-size;
    margin-left: -2px;

    // Overrides for .interaction-input--regular.X since there is no label.
    &.missed,
    &.correct,
    &.incorrect,
    &.predefined {
      padding: 0;
    }
  }
}

input.column_calculation__digit {
  color: $column-calculation-input-font-color;
  &:focus {
    z-index: 2;
  }
}
