ul.conversation {
  list-style-type: none;
  padding: 20px 40px 20px 20px;
  background: $tm-background-blue;
  li {
    list-style-type: none !important;
    color: #F00;
    width: 100%;
    padding: 0 !important;
    margin: 0 0 20px 0 !important;
    position: relative;
    &:last-child {
      margin-bottom: 0 !important;
    }
    span.speaker,
    span.pronouncement {
      display: block;
      font-size: 1em;
      color: $body-color;
    }
    span.speaker {
      position: absolute;
      left: 0;
      top: 0;
      width: 120px;
      text-align: right;
      font-weight: bold;
      padding-top: 23px;
      line-height: 1.1em;
      img.inline-image {
        height: 30px !important;
      }
      @each $class, $color in $conversation-speaker-colors {
        &.#{$class} {
          color: $color;
        }
      }
    }
    span.pronouncement {
      display: inline-block;
      position: relative;
      background: $white;
      border-radius: 15px;
      margin-left: 135px;
      padding: 15px;
      filter: drop-shadow(0 0 4px rgba($black, 0.1));
    }
    span.pronouncement:after {
      content: '';
      position: absolute;
      left: 0;
      top: 30px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: $white;
      border-left: 0;
      border-bottom: 0;
      margin-top: -5px;
      margin-left: -10px;
    }
  }
}
