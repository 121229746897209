.link--hyper,
.link--term,
.link--list-item {
  color: $link-color;
  font-weight: bold;
  border-bottom: 1px dashed;
}
.link--list-item {
  color: $body-color;
  border-bottom: 1px dashed $link-color;
}

.link--has-broken-reference {
  // When content is not present where term references to, show error indicator
  @include error-marker();
}

.link--cross,
.link--download {
  display: inline;
  font-weight: $weight-bold;
  font-family: $family-sans-serif;
  color: $body-color;
  text-decoration: none;

  .text { border-bottom: 1px solid $body-color; }

  [class^="icon-"] {
    font-size: 1.15em;
    margin-right: 0.25em;
    color: $tm-neutral-heavy;
    line-height: 0.5;
    overflow: visible;
    vertical-align: middle;
  }

  &:hover,
  &:active {
    color: $black;
  }
}

.link--error-page {
  color: $tm-text-red;
  text-decoration: underline;
  font-family: $family-sans-serif;
  font-weight: bold;
  &:hover,
  &:active {
    color: $tm-text-red;
    text-decoration: none;
  }
}

.link--loading {
  position: relative;
  pointer-events: none;
  cursor: default;

  [class^="icon-"] { color: inherit; }

  @include loading-bar($height: 3px, $color: $tm-text-red);
}

// The links in a question context have a different styling, like a button
.is-question-content .link--cross,
.is-question-content .link--download {
  display: inline-block;
  white-space: nowrap;
  background: $question-link-background;
  border-radius: 0.3125rem;
  color: $question-link-color;
  padding: 0.25em 0.75em;
  margin-bottom: 0.25rem;

  .text { border-bottom: 0; }

  [class^="icon-"] {
    color: $primary;
    margin-left: 0;
    order: 1;
  }

  &:hover,
  &:active {
    background-color: $question-link-hover-background;
    color: $question-link-hover-color;
    [class^="icon-"] { color: inherit; }
  }
}

.link--cross-simplified {
  .svg-icon {
    margin: -.5rem 0.75rem -0.3rem 0;
    transition: transform 0.2s;
  }
}

.is-question-content .link--cross-simplified {
  background: $tm-sidebar-background;
  color: $tm-sidebar-text-color;
  padding-left: 1.1rem;

  .svg-icon {
    transform: scale(1.5);
  }

  &:hover,
  &:active {
    background: lighten($tm-sidebar-background, 5%);
    color: $tm-sidebar-text-color;
    .svg-icon {
      transform: scale(1.6);
    }
  }
  &.link--loading {
    @include loading-bar($height: 3px, $color: $tm-sidebar-text-color);
  }
}

.section--explanation,
.section--case {
  .link--hyper,
  .link--term,
  .link--list-item {
    color: $explanation-link-color;
  }
}

.section--explanation,
.section--case {
  .link--hyper,
  .link--term,
  .link--list-item {
    &:hover,
    &:active {
      color: $explanation-text-color;
    }
  }
}

.section--explanation,
.section--case {
  .link--cross,
  .link--download {
    background: none;
    padding: 0;
    margin: 0;
    color: $explanation-link-color;
    margin-right: 0.1rem;
    [class^="icon-"] {
      color: $explanation-link-color;
      margin-left: 0.1rem;
      margin-right: 0rem;
    }
    &:hover,
    &:active {
      background: none;
      color: $explanation-text-color;
      [class^="icon-"] {
        color: $explanation-text-color;
      }
    }
  }
}
