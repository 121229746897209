.graphic-gap-entry__container {
  position: relative;
  display: inline-block;
  line-height: 0;
  max-width: $interaction-image-max-width;
}

.graphic-gap-entry__asset {
  width: auto;
  height: auto;
  max-width: 100%;
}

.graphic-gap-entry__option {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  font-size: 1em;
  line-height: 1;
  background-color: rgba(255, 255, 255, .5);

  &--predefined {
    border: 4px solid $exercise-correct-medium;
    &:before {
      background-color: $exercise-correct-medium;
      color: #fff;
      font-family: $icon;
      line-height: 0.7rem;
      font-size: 0.7rem;
      padding-top: 2px;
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      content: $icon-star;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 0.5rem 0;
    }
  }
}

.graphic-gap-entry__input {
  color: $body-color;
  display: inline-block;
  white-space: normal;
  appearance: none;
  line-height: inherit;
  border-radius: 0;
  font-size: inherit;
  height: 100%;
  width: 100%;
  padding: 0;
  text-align: center;
  border: 1px solid $inactive-input-border-color;
  background-color: transparent;
  transition: background-color 100ms;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus {
    border-color: $input-default-border-color;
    background-color: $active-input-background-color;
  }
}

.graphic-gap-entry__input--no-border {
  border: none;
  background-color: rgba($interaction-active-color, 0.05);
  &:hover,
  &:focus {
    border: 2px solid $input-default-border-color;
    background-color: rgba($interaction-active-color, 0.1);
  }
}

.graphic-gap-entry__input--muted {
  color: $muted-text-color;
}

.graphic-gap-entry__value {
  user-select: none;
  pointer-events: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}

.graphic-gap-entry__value--correct {
  background-color: $exercise-correct-medium;
  color: $white;

  &.graphic-gap-entry__value--no-border {
    background-color: rgba($exercise-correct-medium, 0.25);
    color: $exercise-correct-dark;
  }
}

// for graphic gap entry: do not show any color for predefined gaps:
.graphic-gap-entry__value--predefined {
  background: transparent;
  color: $base-text-color;
}

.graphic-gap-entry__value--incorrect {
  background-color: $exercise-incorrect-dark;
  color: $white;

  &.graphic-gap-entry__value--no-border {
    background-color: rgba($exercise-incorrect-dark, 0.25);
    color: $exercise-incorrect-dark;
  }
}

.graphic-gap-entry__value--missed {
  background-color: $exercise-correct-light;
  color: $exercise-correct-dark;

  &.graphic-gap-entry__value--no-border {
    background-color: rgba($exercise-correct-light, 0.25);
  }
}
