.sequence-result-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100%;

  &__questions {
    @include desktop() {
      width: 14rem !important;
    }

    flex: 1 1 auto;
    background: $tm-neutral-light;
    margin: 0 !important;
    padding: 1rem !important;
  }
}
