@import "~@glidejs/glide/src/assets/sass/glide.core";

.carousel-page .page {
	@media screen and (max-width: 1024px) {
		overflow-x: hidden;
	}
}

@import "lesson-carousel/controls";
@import "lesson-carousel/items";
