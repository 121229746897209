.context-menu {
  position: absolute;
  text-align: left;

  &--item,
  &--item-simplified {
    top: $spacer;
    right: $spacer;
  }
  &--theory {
    top: 45px;
    right: 0;
  }
  &--reference-paragraph,
  &--reference-paragraph-white, {
    top: $spacer;
    right: $spacer;
  }
}
.context-menu__button {
  color: $base-text-color;
  &:hover {
    color: $base-text-color-hover;
  }

  .context-menu--reference-paragraph-white & {
    color: $tm-text-white;
    &:hover {
      color: $tm-text-white-hover;
    }
  }
  cursor: pointer;
}
.context-menu__button-icon {
  .context-menu--theory &,
  .context-menu--my-content & {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.context-menu__content {
  display: flex;
  flex-direction: column;
  z-index: 10004;
  position: absolute;
  background: $white;
  @include dropshadow();
}

.context-menu__section {
  padding: $spacer;
  border-bottom: 1px solid $tm-neutral-light;
  white-space: nowrap;
  &:last-child {
    border-bottom: 0 none;
  }
}
