.math-interaction__element-holder {
  display: flex;
}
.math-interaction__element,
.math-interaction__fraction-container {
  display: flex;
  position: relative;

  &--correct, &--incorrect, &--predefined, &--missed {
    padding: 0.125rem 0.5rem 0.125rem 2rem;

    &:before {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1.5rem;
      font-family: $pika;
      font-size: 0.75rem;
      text-align: center;
    }
  }

  &--correct, &--predefined {
    background: $exercise-correct-light;
    color: $exercise-correct-dark;
    &:before {
      background: $exercise-correct-medium;
      color: $white;
    }
  }
  &--correct {
    &:before {
      content: $pika-check;
    }
  }
  &--predefined {
    &:before {
      content: $pika-star;
    }
  }
  &--incorrect {
    background: $white;
    border-bottom: 1px solid $exercise-incorrect-light;
    color: $exercise-incorrect-dark;
    &:before {
      background: $exercise-incorrect-dark;
      border-bottom: 1px solid $exercise-incorrect-dark;
      color: $white;
      content: $pika-delete;
    }
  }
  &--missed {
    background: $white;
    border: 1px dashed $exercise-correct-dark;
    color: $exercise-correct-dark;
    &:before {
      background: $exercise-correct-light;
      border-right: 1px dashed $exercise-correct-dark;
      color: $exercise-correct-dark;
      content: $pika-check;
    }
  }

  &--input {
    width: 60px;
    text-align: center;
  }
}

.math-interaction__fraction-container {
  &--correct, &--incorrect, &--predefined, &--missed {
    padding: 0.125rem 0 0.125rem 2rem;
    margin-right: 0.5rem;
  }
}

.math-interaction__fraction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .math-interaction__element-holder--number, .math-interaction__element--number {
    line-height: 1;
  }
}

.math-interaction__fraction-sign {
  height: 1px;
  background-color: $body-color;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}
