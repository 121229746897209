.popover {
  position: absolute;
  z-index: 10;
  background-color: $tm-neutral-black;
  color: $white;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.75rem;
}

$_arrow-size: 10px;
.popover__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  border-width: $_arrow-size;
  border-color: transparent;
}

.popover--top .popover__arrow,
.popover--bottom .popover__arrow {
  left: calc(50% - #{$_arrow-size});
}

.popover--top {
  bottom: 100%;
  margin-bottom: 1rem;
  .popover__arrow {
    border-bottom-width: 0;
    border-top-color: $tm-neutral-black;
    bottom: -$_arrow-size;
  }
}

.popover--bottom {
  top: 100%;
  margin-top: 0.5rem;
  .popover__arrow {
    border-top-width: 0;
    border-bottom-color: $tm-neutral-black;
    top: -$_arrow-size;
  }
}
