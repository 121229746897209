
.theory-page-list__item {
  margin: 0.5rem 0 0.5rem 1.33rem;
  padding-left: 0.75rem;
  list-style: square outside;
}

.theory-page-list__link {
  font-weight: $weight-bold;
  color: $body-color;
  border-bottom: 1px dashed currentColor;

  &:hover,
  &:active {
    color: $link-color;
  }
}
