.item-overview {
  &__content,
  &__footer {
    width: 100%;
    font-size: .875em;
    padding: 35px 60px;
    position: relative;
    line-height: 1.5em;
    background: #FFF;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $tm-background-blue;
    font-size: 1.2em;
  }

  &__footer-inner {
    display: flex;
    flex-direction: row;
    max-width: 61.25rem;
    margin: 0 auto;
  }

  &__footer-message {
    padding-top: 0.33rem;
  }

  &__footer-buttons {
    flex-grow: 1;
    text-align: right;
  }

  &__score {
    position: relative;
    display: flex;
  }

  &__content {
    max-width: 61.25rem;
    margin: 0 auto 130px auto;
  }

  &__content-header {
    font-family: $family-serif;
    font-weight: bold;
    font-style: normal;
    color: $tm-neutral-black;
    font-size: 2em;
    line-height: 1.2;
    width: 100%;
    margin-bottom: 30px;
    padding-left: 20px;
  }

  &__base-result {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    padding: 2rem 2rem 2rem 3rem;
    background-color: $tm-background-blue;
  }

  &__base-result-message {
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 0 1rem;
  }

  &__exercise-result {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  &__exercise-result-section {
    min-width: 5.125rem;
    width: 25%;
    flex-direction: column;
    text-align: center;
  }

  &__exercise-result-badge {
    position: relative;
    padding: 0;
    margin: 1rem auto;
    flex: none;
  }
}
