$mail-grey: $tm-neutral;

.mail {
  padding: 1.5rem;
  background-color: $reading-background-color;
  margin-bottom: 2rem;
  &--with-headers {
  }
  .topic--reading & {
    padding: 0;
  }
}

.mail__content {
  padding: 1.875rem 2.5rem;
  border: 1px solid $mail-grey;
  background: $white;
}

.mail__header {
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: right;
}

.mail__headerlabel {
  font-weight: bold;
}

.mail__to,
.mail__from,
.mail__subject,
.mail__attachment {
  display: inline-block;
  padding: 0 0 0 0.3rem;
  margin: 0 0 0.5rem 0;
  width: calc(100% - 150px);
  border: 1px solid $mail-grey;
  background: $white;
  text-align: left;
}

.mail__attachment {
  line-height: 1;
  background: $mail-grey;
  span.icon-paperclip {
    line-height: 1.5rem;
    &:before {
      font-size: 0.8rem;
      margin-right: 0.3rem;
    }
  }
}
