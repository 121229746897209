$modal-color: $exercise-incorrect-dark;

.feedback-about-answer-modal {
  @include dropshadow();
  background: $white;
  border: 1px solid $modal-color;
  margin: 0;
  max-width: 20vw;
  min-width: 250px;
  padding: 0;
  position: fixed;
  right: 1.5rem;
  top: 5rem;
  width: 100%;
  z-index: 999999;
}

.feedback-about-answer-modal__close {
  @include close-button(40px, 1px, $white, $white, 60%);
  position: absolute;
  right: 0;
}

.feedback-about-answer-modal__title {
  background: $modal-color;
  color: $white;
  font-weight: bold;
  padding: 10px;
}

.feedback-about-answer-modal__content {
  max-height: 65vh;
  overflow: auto;
  padding: 1rem;

  p {
    margin: 0;
  }

  .section {
    margin: 0;

    .asset {
      margin: -1.5rem;
    }
  }
}
