// QTI text entry

.text-entry-shadow-input {
    border: 1px solid;
    display: inline-block;
    position: fixed;
    backface-visibility: hidden;
    top: -9999em;
    padding-left: 5px;
    padding-right: 10px; // increase above 5px to increase autogrow threshold
}

.qti-text-entry-wrapper {
// imo QTI text entry specific styles shouldn't be applied to the exercise-answer-reference section
// I leave this commented for now in case of regression issues
//.qti-text-entry-self-score .exercise-answer-reference {
    line-height: 2.2;

    .text-entry-input[type=text] {
        border: 1px solid $input-default-border-color;
        border-radius: 0;
        font-size: inherit;
        line-height: normal;
        padding: 5px;
        width: 75px;
        max-width: 100%;
        appearance: none;
    }
    .text-entry-input[type=text] {
        &:hover {
            border-color: #8f979d;
        }

        &:focus,
        &:active {
            border-color: $interaction-active-color;
            outline: 0;
        }

        &.muted {
            color: $muted-text-color;
        }
    }

    .option {
        border: 1px solid;
        display: inline-block;
        line-height: 1.8;
        padding: 0 .3em;
        position: relative;
        white-space: nowrap;

        &:before {
            color: #fff;
            font-family: $icon;
            line-height: 2.2;
            position: absolute;
            top: -1px; // pull the pseudo element over the pseudo-parent border
            bottom: -1px; // pull the pseudo element over the pseudo-parent border
            left: -1px; // pull the pseudo element over the pseudo-parent border
            text-align: center;
            width: 1.5em;
        }

        &:after {
            content: $zero-width-space;
        }
    }

    .option-fraction {
      border: 0;
      display: inline-flex;
      vertical-align: middle;

      &:after {
        content: "";
      }
    }

    .correct {
        background-color: $exercise-correct-light;
        border-color: transparent;
        color: $exercise-correct-dark;
        padding-left: 1.8em;

        &:before {
            background-color: $tm-good;
            content: $icon-checkmark;
        }
    }

    .predefined {
        background-color: $exercise-correct-light;
        border-color: transparent;
        color: $exercise-correct-dark;
        padding-left: 1.8em;

        &:before {
            background-color: $tm-good;
            content: $icon-star;
        }
    }

    .incorrect {
        border-color: transparent;
        border-bottom-color: $exercise-incorrect-light;
        color: $exercise-incorrect-dark;
        padding-left: 1.8em;

        &:before {
            background-color: $exercise-incorrect-dark;
            content: $icon-cross;
        }
    }

    .missed {
        border: 1px dashed;
        color: $exercise-correct-dark;
        padding-left: 1.8em;

        &:before {
            background-color: $exercise-correct-light;
            border-right: 1px dashed;
            color: $exercise-correct-dark;
            content: $icon-checkmark;
            top: 0;
            bottom: 0;
            left: 0;
        }
    }

    .self-scored {
        border-color: $input-default-border-color;
    }

    table:not(.table):not(.asset__table) {
        margin-top: 2em;

        tr:first-child td {
            padding: .5em;
            vertical-align: top;
        }

        td {
            border: 1px solid #ccc;
            line-height: 1.5;
            vertical-align: middle;
            height: auto;
            font-size: .9em;
            padding: .3em .5em;
        }
    }

}

// Self score
.qti-text-entry-self-score {

    .qti-text-entry-wrapper {
        width: 100%;
    }

    .exercise-content-column {
        .qti-text-entry-wrapper {
            width: 100%;
        }
    }

    .exercise-own-answer-title {
        font-weight: bold;
        font-size: 1.0625em;
        margin: 10px 0 30px;
    }

}
