/* Single Choice Image QTI type */

.question-page--simplified .choice-single-image {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  justify-content: space-between;
  list-style: none;
  margin: 1em 0 !important;

  li {
    display: inline-block;
    font-size: 1rem;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 20px 20px 0 !important;
    vertical-align: top;
  }

  .col-2 {
    flex: 0 1 50%;
  }

  .col-3 {
    flex: 0 1 33.33%;
  }

  label {
    display: flex;
    height: 100%;
    position: relative;

    .exercise-input-marker {
      cursor: pointer;
    }

    input[disabled] ~ .exercise-input-marker {
      cursor: default;
    }
  }

  input {
    position: absolute;
    left: -9999em;
  }

  .asset {
    margin-bottom: 0;
    max-width: none;
    width: 100%;
  }

  .exercise-input-marker {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 150ms ease-in-out 0s;

    &:before {
      background-color: $anthracite;
      color: #fff;
      content: $icon-checkmark;
      font-family: $icon;
      font-size: 1.5em;
      height: 51px; // 50 + 1px, to make the visible marker 50px high
      line-height: 54px;
      position: absolute;
      top: 4px; // 5 - 1px, to overlap the border, necessary for retina displays
      left: 4px; // 5 - 1px, to overlap the border, necessary for retina displays
      text-align: center;
      width: 51px; // 50 + 1px, to make the visible marker 50px wide
    }
  }

  input:checked ~ .exercise-input-marker,
  .correct > .exercise-input-marker,
  .incorrect > .exercise-input-marker,
  .missed > .exercise-input-marker {
    opacity: 1;
  }

  .correct > .exercise-input-marker:before {
    background-color: $tm-good;
  }

  .incorrect > .exercise-input-marker:before {
    background-color: $exercise-incorrect-dark;
    content: $icon-cross;
  }

  .missed > .exercise-input-marker:before {
    background-color: $exercise-correct-light;
    color: $exercise-correct-dark;
    border-right: 1px dashed $tm-good;
    border-bottom: 1px dashed $tm-good;
  }

  img {
    border: 5px solid transparent;
    border-color: $tm-neutral-dark !important;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    width: 100%;
    max-width: 100%;
    pointer-events: none;
    transition: border-color 150ms ease-in-out 0s;
    background-color: transparent !important;
  }

  img:hover {
    border-color: #ccc;
  }

  input:checked + img,
  input:checked + figure > img {
    border-color: $anthracite;
  }

  .correct > input[disabled] + img,
  .correct > input[disabled] + figure > img {
    border-color: $tm-good !important;
  }

  .incorrect > input[disabled] + img,
  .incorrect > input[disabled] + figure > img {
    border-color: $exercise-incorrect-dark !important;
  }

  .missed > input[disabled] + img,
  .missed > input[disabled] + figure > img {
    border-color: $tm-good !important;
    border-style: dotted;
  }
}
