/**
 * Carousel controls
 */
$carousel-controls-color: $body-color;

.carousel--lesson-navigation .carousel__controls {
	align-items: center;
	display: flex;
	color: $carousel-controls-color;
	justify-content: space-between;
	left: 0;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

.carousel--lesson-navigation .carousel__arrow {
	height: 4rem;
	position: absolute;
	transition: all .3s ease-in-out;
	width: 4rem;

	&.glide__arrow--disabled {
		cursor: default;
		color: $tm-neutral-extraheavy !important;
		opacity: .2;
    pointer-events: none;

		.icon--xxl::before {
			font-size: 9rem;

			@media screen and (max-width: 1023px) {
				font-size: 7rem;
			}
		}
	}

	@include tablet() {
		opacity: .6;
	}

	&:not(.glide__arrow--disabled):hover {
		opacity: 1;
	}

	&.carousel__arrow--left {
		@media screen and (min-width: 1024px) {
			left: -75px;
		}

		@media screen and (max-width: 1023px) {
			left: -65px;
		}
	}

	&.carousel__arrow--right {
		@media screen and (min-width: 1024px) {
			right: -75px;
		}

		@media screen and (max-width: 1023px) {
			right: -65px;
		}
	}
}

.carousel--lesson-navigation .carousel__arrow .icon--xxl {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	position: relative;
	width: 100%;

	&::before {
		font-size: 10rem;
		pointer-events: none;
		transition: all .3s ease-in-out;

		@media screen and (max-width: 1023px) {
			font-size: 7.5rem;
		}

		@media screen and (max-width: 600px) {
			font-size: 5rem;
		}
	}
}
