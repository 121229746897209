$_letter-size: 40px;
// Block size for smaller screens
$_letter-size-condensed: 30px;
$_letter-border-size: 2px;
$_letter-border-color: $input-default-border-color;
$_letter-border-color-light: $inactive-input-border-color;
$_letter-border-radius: 6px;
$_letter-font-color: $interaction-active-color;
$_letter-font-color-predefined: $_letter-font-color;

$_number_container_width: 2rem;
$_number_container_width_condensed: 1.2rem;

// Show condensed version from this width and smaller
$_breakpoint: 1024px;

.word-puzzle-table {
  border: none;
  background: transparent;
}

.word-puzzle-option {
  .word-puzzle-clue-container,
  .word-puzzle-letters-container {
    padding-bottom: 0.8em;
    vertical-align: middle;
  }

  .word-puzzle-clue-container {
    display: flex;
    padding-right: 20px;
    @media (max-width: $_breakpoint) {
      padding-right: 15px;
    }
    min-width: 24rem;
  }

  .word-puzzle-number,
  .word-puzzle-clue-content {
    font-family: $family-sans-serif;
    font-size: 1.1em;
    position: relative;
    color: $base-text-color;
    background-color: white;
    padding-right: 10px;
    z-index: 1;

    @media (max-width: $_breakpoint) {
      font-size: 14px;
      padding-right: 6px;
    }
  }

  .word-puzzle-number {
    font-weight: bold;
    &--letters {
      display: inline-block;
      width: $_number_container_width;
      text-align: right;
      line-height: $_letter-size + 4px;
      padding-right: 0;
      text-align: right;
      vertical-align: top;
      @media (max-width: $_breakpoint) {
        width: $_number_container_width_condensed;
        line-height: $_letter-size-condensed + 4px;
      }
    }
  }

  .word-puzzle-letters {
    display: inline-block;
    white-space: nowrap; // Not wrap the crossword!
    position: relative;
    overflow: hidden;
    @include clearfix;

    &.spaces-1 {
      width: (1 * $_letter-size) + 4px;
    }
    &.spaces-2 {
      width: (2 * $_letter-size) + 4px;
    }
    &.spaces-3 {
      width: (3 * $_letter-size) + 4px;
    }
    &.spaces-4 {
      width: (4 * $_letter-size) + 4px;
    }
    &.spaces-5 {
      width: (5 * $_letter-size) + 4px;
    }
    &.spaces-6 {
      width: (6 * $_letter-size) + 4px;
    }
    &.spaces-7 {
      width: (7 * $_letter-size) + 4px;
    }
    &.spaces-8 {
      width: (8 * $_letter-size) + 4px;
    }
    &.spaces-9 {
      width: (9 * $_letter-size) + 4px;
    }
    &.spaces-10 {
      width: (10 * $_letter-size) + 4px;
    }
    &.spaces-11 {
      width: (11 * $_letter-size) + 4px;
    }
    &.spaces-12 {
      width: (12 * $_letter-size) + 4px;
    }
    &.spaces-13 {
      width: (13 * $_letter-size) + 4px;
    }
    &.spaces-14 {
      width: (14 * $_letter-size) + 4px;
    }
    &.spaces-15 {
      width: (15 * $_letter-size) + 4px;
    }
    &.spaces-16 {
      width: (16 * $_letter-size) + 4px;
    }
    &.spaces-17 {
      width: (17 * $_letter-size) + 4px;
    }
    &.spaces-18 {
      width: (18 * $_letter-size) + 4px;
    }
    &.spaces-19 {
      width: (19 * $_letter-size) + 4px;
    }
    &.spaces-20 {
      width: (20 * $_letter-size) + 4px;
    }
    &.spaces-21 {
      width: (21 * $_letter-size) + 4px;
    }
    &.spaces-22 {
      width: (22 * $_letter-size) + 4px;
    }
    &.spaces-23 {
      width: (23 * $_letter-size) + 4px;
    }
    &.spaces-24 {
      width: (24 * $_letter-size) + 4px;
    }

    @media (max-width: $_breakpoint) {
      &.spaces-1 {
        width: (1 * $_letter-size-condensed) + 4px;
      }
      &.spaces-2 {
        width: (2 * $_letter-size-condensed) + 4px;
      }
      &.spaces-3 {
        width: (3 * $_letter-size-condensed) + 4px;
      }
      &.spaces-4 {
        width: (4 * $_letter-size-condensed) + 4px;
      }
      &.spaces-5 {
        width: (5 * $_letter-size-condensed) + 4px;
      }
      &.spaces-6 {
        width: (6 * $_letter-size-condensed) + 4px;
      }
      &.spaces-7 {
        width: (7 * $_letter-size-condensed) + 4px;
      }
      &.spaces-8 {
        width: (8 * $_letter-size-condensed) + 4px;
      }
      &.spaces-9 {
        width: (9 * $_letter-size-condensed) + 4px;
      }
      &.spaces-10 {
        width: (10 * $_letter-size-condensed) + 4px;
      }
      &.spaces-11 {
        width: (11 * $_letter-size-condensed) + 4px;
      }
      &.spaces-12 {
        width: (12 * $_letter-size-condensed) + 4px;
      }
      &.spaces-13 {
        width: (13 * $_letter-size-condensed) + 4px;
      }
      &.spaces-14 {
        width: (14 * $_letter-size-condensed) + 4px;
      }
      &.spaces-15 {
        width: (15 * $_letter-size-condensed) + 4px;
      }
      &.spaces-16 {
        width: (16 * $_letter-size-condensed) + 4px;
      }
      &.spaces-17 {
        width: (17 * $_letter-size-condensed) + 4px;
      }
      &.spaces-18 {
        width: (18 * $_letter-size-condensed) + 4px;
      }
      &.spaces-19 {
        width: (19 * $_letter-size-condensed) + 4px;
      }
      &.spaces-20 {
        width: (20 * $_letter-size-condensed) + 4px;
      }
      &.spaces-21 {
        width: (21 * $_letter-size-condensed) + 4px;
      }
      &.spaces-22 {
        width: (22 * $_letter-size-condensed) + 4px;
      }
      &.spaces-23 {
        width: (23 * $_letter-size-condensed) + 4px;
      }
      &.spaces-24 {
        width: (24 * $_letter-size-condensed) + 4px;
      }
    }
  }

  .word-puzzle-letter,
  .word-puzzle-letter--predefined {
    // Reset appearence for devides
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    outline: 0 none;
    &:focus {
      outline: 0 none;
    }

    position: relative;
    float: left;

    font-family: $family-serif;
    font-size: 30px;
    text-align: center;
    color: $_letter-font-color;
    &::placeholder {
      opacity: 1;
      color: $_letter-font-color-predefined;
      text-align: center;
    }

    width: $_letter-size + 2; // overlapping border
    height: $_letter-size;

    @media (max-width: $_breakpoint) {
      font-size: 20px;
      width: $_letter-size-condensed + 2;
      height: $_letter-size-condensed;
    }

    margin-left: -2px;

    border-left: $_letter-border-size solid $_letter-border-color-light;
    border-right: $_letter-border-size solid $_letter-border-color-light;
    border-top: none;
    border-bottom: none;

    &.word-puzzle-letter-solution {
      background-color: #fffdbe !important;
      border-left-color: #ffdd00;
      border-right-color: #ffdd00;
      z-index: 2;
    }

    &.first {
      border-left: none;
    }
    &.last {
      border-right: none;
    }
    &:focus {
      background-color: $active-input-background-color;
    }
  }

  .word-puzzle-letter--predefined {
    background: $exercise-correct-light;
    color: $exercise-correct-dark;
    border-left-color: $exercise-correct-medium;
    border-right-color: $exercise-correct-medium;
    line-height: 1.4;
    z-index: 1;
    @include icon-before($icon-star);
    &:before {
      background: $exercise-correct-medium;
      color: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -$_letter-border-size;
      width: 0.8rem;
      height: 0.9rem;
      font-size: 0.7rem;
      border-radius: 0 0 2rem 0;
      text-align: left;
      padding-left: 0.15rem;
    }
  }

  //
  // Styling for feedback
  //
  .word-puzzle-letters.correct {
    background-color: $exercise-correct-medium;
    border-color: $exercise-correct-medium;

    .word-puzzle-letter {
      color: #0a8a17;
      &::placeholder {
        color: #0a8a17;
      }

      &:not(.word-puzzle-letter-solution) {
        background-color: $exercise-correct-light;
        border-left-color: #9ee897;
        border-right-color: #9ee897;
      }
    }
  }

  .word-puzzle-letters.missed {
    clear: both;
    display: block;
    margin-top: 0.2em;
    margin-left: $_number_container_width + 0.25rem;
    @media (max-width: $_breakpoint) {
      margin-left: $_number_container_width_condensed + 0.25rem;;
    }

    border: 2px dashed $exercise-correct-medium;

    .word-puzzle-letter {
      color: #0a8a17;
      background-color: white;
      border-left-color: #9ee897;
      border-right-color: #9ee897;
    }
    .word-correct {
      background-color: $exercise-correct-light;
      &:after {
        color: $exercise-correct-medium;
      }
    }
  }

  .word-puzzle-letters.incorrect {
    background-color: $exercise-incorrect-dark;
    border-color: $exercise-incorrect-dark;

    .word-puzzle-letter {
      color: $exercise-incorrect-dark;
      &::placeholder {
        color: $exercise-incorrect-dark;
      }

      &:not(.word-puzzle-letter-solution) {
        border-left-color: $exercise-incorrect-light;
        border-right-color: $exercise-incorrect-light;
      }
    }
  }
}

.word-puzzle-solution-container {
  font-family: $family-sans-serif;
  margin: 2em 0;
  font-size: 1.1em;
  line-height: 1.6em;
  label {
    display: block;
  }
}

.word-puzzle-solution-input-container {
  display: inline-block;
  margin-top: 0.5em;
  @include clearfix();
  overflow: hidden;

  &.correct {
    background-color: $exercise-correct-medium;
    border-color: $exercise-correct-medium;
    .word-puzzle-solution {
      color: #0a8a17;
    }
  }

  &.incorrect {
    background-color: $exercise-incorrect-dark;
    border-color: $exercise-incorrect-dark;
    .word-puzzle-solution {
      color: $exercise-incorrect-dark
    }
  }

  &.missed {
    margin-left: .5em;
    border-color: $exercise-correct-medium;
    border-style: dashed;
    background-color: white;
    .word-puzzle-solution {
      color: #0a8a17
    }

    .word-correct {
      background-color: $exercise-correct-light;
      &:after {
        color: $exercise-correct-medium;
      }
    }
  }
}

.word-puzzle-solution {
  float: left;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0 8px;
  outline: 0 none;
  &:focus {
    outline: 0 none;
  }
  font-family: $family-serif;
  font-size: 30px;
  color: $_letter-font-color;
  height: $_letter-size;
}

.word-puzzle-letters-container {
  position: relative;
  white-space: nowrap;
  &.offset-left-1 {
    padding-left: 1 * $_letter-size;
  }
  &.offset-left-2 {
    padding-left: 2 * $_letter-size;
  }
  &.offset-left-3 {
    padding-left: 3 * $_letter-size;
  }
  &.offset-left-4 {
    padding-left: 4 * $_letter-size;
  }
  &.offset-left-5 {
    padding-left: 5 * $_letter-size;
  }
  &.offset-left-6 {
    padding-left: 6 * $_letter-size;
  }
  &.offset-left-7 {
    padding-left: 7 * $_letter-size;
  }
  &.offset-left-8 {
    padding-left: 8 * $_letter-size;
  }
  &.offset-left-9 {
    padding-left: 9 * $_letter-size;
  }
  &.offset-left-10 {
    padding-left: 10 * $_letter-size;
  }
  &.offset-left-11 {
    padding-left: 11 * $_letter-size;
  }
  &.offset-left-12 {
    padding-left: 12 * $_letter-size;
  }

  @media (max-width: $_breakpoint) {
    &.offset-left-1 {
      padding-left: 1 * $_letter-size-condensed;
    }
    &.offset-left-2 {
      padding-left: 2 * $_letter-size-condensed;
    }
    &.offset-left-3 {
      padding-left: 3 * $_letter-size-condensed;
    }
    &.offset-left-4 {
      padding-left: 4 * $_letter-size-condensed;
    }
    &.offset-left-5 {
      padding-left: 5 * $_letter-size-condensed;
    }
    &.offset-left-6 {
      padding-left: 6 * $_letter-size-condensed;
    }
    &.offset-left-7 {
      padding-left: 7 * $_letter-size-condensed;
    }
    &.offset-left-8 {
      padding-left: 8 * $_letter-size-condensed;
    }
    &.offset-left-9 {
      padding-left: 9 * $_letter-size-condensed;
    }
    &.offset-left-10 {
      padding-left: 10 * $_letter-size-condensed;
    }
    &.offset-left-11 {
      padding-left: 9 * $_letter-size-condensed;
    }
    &.offset-left-12 {
      padding-left: 10 * $_letter-size-condensed;
    }
  }
}

.word-puzzle-letters,
.word-puzzle-solution-input-container {
  border: 2px solid $_letter-border-color;
  border-radius: $_letter-border-radius;
}

.word-puzzle-letters,
.word-puzzle-solution-input-container {
  .word-correct,
  .word-incorrect {
    display: inline;
    float: left;
    position: relative;
    width: $_letter-size;
    height: $_letter-size;
    &:after {
      font-family: $icon;
      font-size: 1.8em;
      line-height: 1;
      position: absolute;
      top: 50%;
      margin-top: -.35em; // this seems spot on to compensate for baseline hight
      left: 50%;
      margin-left: -.35em;
      color: white;
    }
  }

  .word-correct {
    background-color: $exercise-correct-medium;
    &:after {
      content: $icon-checkmark;
    }
  }

  .word-incorrect {
    background-color: $exercise-incorrect-dark;
    &:after {
      content: $icon-cross;
    }
  }
}

.word-puzzle-letters .word-correct,
.word-puzzle-letters .word-incorrect {
  @media (max-width: $_breakpoint) {
    width: $_letter-size-condensed;
    height: $_letter-size-condensed;
    &:after {
      font-size: 1.4em;
    }
  }
}

