// Hottext exercise
$hottext-categories: (
  1: (
    extralight: #F8E085,
    light: $tm-yellow-dark,
    dark: $tm-orange
  ),
  2: (
    extralight: $exercise-correct-light,
    light: $exercise-correct-medium,
    dark: $exercise-correct-dark
  ),
  3: (
    extralight: #F2ACA8,
    light: $tm-poor,
    dark: $tm-poor-heavy
  ),
  4: (
    extralight: #92C5E8,
    light: $tm-blue,
    dark: $tm-blue-dark
  ),
  5: (
    extralight: $tm-purple-light,
    light: $tm-purple,
    dark: $tm-purple-dark
  )
);


.hottext-category-container {
  width: 100%;
  padding: 0.5rem;
  margin-top: 2rem;
  background-color: $tm-background-blue;
}
.hottext-category {
  display: inline-block;
  position: relative;
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  padding: 0.5rem;
  &--active {
    cursor: pointer;
  }
  &__line {
    display: none;
  }
  &__abbreviation {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

@each $value, $colors in $hottext-categories {
  .hottext-category#{$value} {
    background-color: map-get($colors, extralight);
    border: 2px solid map-get($colors, extralight);
  }
  .hottext-category#{$value}.hottext-category--selected {
    color: #FFF;
    background-color: map-get($colors, light);
    border-color: map-get($colors, dark);
  }
  .hottext-category#{$value}__abbreviation {
    color: map-get($colors, dark);
  }
}

.qti-hottext-wrapper {
  line-height: 2.2;
  &--with-categories {
    line-height: 2.8;
  }

  ul {
    list-style: disc;
    margin: .5em 0 1em;
  }

  ol {
    list-style: decimal;
    margin: .5em 0 1em;
  }

  li {
    margin-left: 2em;
    padding-left: .3em;
  }

  .hottext-input[type="checkbox"] {
    position: absolute;
    left: -9999em;
  }

  .hottext-option-container {
    display: inline-block;
    position: relative;
  }

  .option {
    display: inline-flex;
    position: relative;
    cursor: pointer;
    &__line, &__abbreviation {
      position: absolute;
      width: 100%;
      left: 0;
    }
    &__line {
      height: 0;
      bottom: 0.5rem;
      border-bottom: 1px solid;
    }
    &__abbreviation {
      @include truncate-text();
      bottom: 0;
      font-size: 0.5rem;
      text-align: center;
      line-height: 0.5rem;
      background-color: rgba(255,255,255,.5);
    }
  }

  .answer {
    display: inline-flex;
    line-height: 1.8;
    margin: 0;
    padding: 0 .3em 0 1.8em;
    position: relative;

    &:before {
      font-family: $icon;
      line-height: 1.9;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      vertical-align: middle;
      width: 1.5em;
    }

    .option__content-with-category {
      position: relative;
    }
  }

  .predefined {
    background-color: $exercise-correct-light;
    color: $exercise-correct-dark;

    &:before {
      content: $icon-star;
      background-color: $tm-good;
      color: #fff;
    }
  }

  .correct {
    background-color: $exercise-correct-light;
    color: $exercise-correct-dark;

    &:before {
      content: $icon-checkmark;
      background-color: $tm-good;
      color: #fff;
    }
  }

  .incorrect {
    background-color: #fff;
    border-bottom: 1px solid $exercise-incorrect-light;
    color: $exercise-incorrect-dark;
    margin: 0;

    &:before {
      content: $icon-cross;
      background-color: $exercise-incorrect-dark;
      border-bottom: 1px solid $exercise-incorrect-dark;
      color: #fff;
    }
  }

  .missed {
    position: relative;
    background-color: #fff;
    border: 1px dashed $exercise-correct-dark;
    color: $exercise-correct-dark;
    margin: 0;

    &:before {
      content: $icon-checkmark;
      background-color: $exercise-correct-light;
      border-right: 1px dashed $exercise-correct-dark;
      color: $exercise-correct-dark;
    }
  }

  .missed, .incorrect {
    .abbreviation {
      background-color: $white;
    }
  }

  @each $value, $colors in $hottext-categories {
    .option#{$value}.option--checked {
      color: #fff;
      background-color: map-get($colors, light);
    }
    .option#{$value}__line.option__line--checked,
    .option#{$value}__abbreviation.option__abbreviation--checked {
      color: map-get($colors, dark);
      border-color: map-get($colors, dark);
    }
    .option#{$value}__state-category {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      color: map-get($colors, dark);
      text-align: center;
      .option__dotted-line {
        background-color: map-get($colors, dark);
      }
      font-size: 0.7rem;
      line-height: 0.8rem;
      @include truncate-text();
      transform: translateY(-6px);
    }
  }
}
