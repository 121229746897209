.about-page {
  h1 {
    @extend .h2;
    @extend .labeled-title;
  }
  h2 {
    @extend .h3;
    @extend .labeled-title;
  }
  h3 {
    @extend .h4;
    @extend .labeled-title;
  }
  h4 {
    @extend .h5;
    @extend .labeled-title;
  }

  ul {
    list-style: disc outside;
    margin-left: 2rem;
  }
}
