[v-cloak],.d-none {
  display: none;
}

@include until($widescreen) {
  .is-hidden-until-widescreen {
    display: none !important;
  }
}

@media screen and (min-width: $tablet) and (max-width: $widescreen - 1px) {
  .is-hidden-from-tablet-until-widescreen {
    display: none !important;
  }
}
