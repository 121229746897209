.chapter-number {
  min-width: baseline(2.5);
  margin-right: baseline(1);
  padding: 0;
}

.paragraph-number {
  min-width: baseline(2);
  margin-right: baseline(.25);
  padding: 0;
}

.chapters-index {
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: baseline(11);
  padding: 0 0 0 baseline(1.25);
  overflow: auto;

  &__chapter {
    display: block;
    line-height: 2.5;
    padding: 0 baseline(1) 0 baseline(1.65);
    color: $body-color;
    @include truncate-text();
  }

  &__chapter--highlight {
    font-weight: bold;
    background-color: $tm-neutral;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.chapters {

  &--regular {
    padding: 0 baseline(1.25) baseline(1);

    @include tablet() {
      padding-bottom: 50vh;
    }
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.chapter {
  flex: 0 0 100%;

  &--regular {
    margin-bottom: 3rem;
    @include tablet-landscape() {
      margin-bottom: 6rem;
    }
  }

  &--modular {
    @include tablet-landscape() {
      margin-bottom: 3rem;
    }

    @include tablet-only() {
      &:not(:nth-child(2n)) {
        margin-right: 1.5rem;
      }

      flex: 0 0 calc(50% - 1.5rem);
    }

    @include desktop() {
      &:not(:nth-child(3n)) {
        margin-right: 1.5rem;
      }
      flex: 0 0 calc(33.3% - 1.5rem);
    }
  }
}

.chapter__header {
  padding: $spacer*1.5 $spacer*2 $spacer*0.5;
  background-color: $tm-neutral-light;
  cursor: pointer;

  &:hover {
    background-color: $tm-neutral;
  }

  @include desktop() {
    padding: $spacer*2 $spacer*3 $spacer*0.5;
  }
}
.chapter__toggle-collapse {
  font-size: 1.2rem;

  transform: rotate(180deg);

  .is-collapsed & {
    transform: rotate(0deg);
  }
}
.chapter__method-logo {
  height: 2rem;
  background-size: contain;
  background-position: 0 0 !important;
}
.chapter__content {
  .is-collapsed & {
    display: none;
  }

  background-color: $tm-neutral-light;
  border-top: 2px solid $white;
  padding-bottom: $spacer*1.5;

  @include desktop() {
    padding-bottom: $spacer*2;
  }
}
.chapter__content-header {
  padding: $spacer*1 $spacer*2;
  font-size: 0.9rem;

  @include desktop() {
    padding: $spacer*1 $spacer*3;
  }
}

.paragraph {
  padding: 0;
  background-color: $tm-neutral-light;
  -webkit-align-items: stretch;
  align-items: stretch;
  margin-bottom: 4px;

  &--modular {
    margin-bottom: 2px;
  }
  &--highlight {
    background-color: $tm-neutral-pale;
  }

  &:hover {
    background-color: $tm-neutral;
  }
}
  .paragraph__open {
    background-color: $white;
    padding: $spacer*1.5;
    font-size: 1.5rem;
  }
  .paragraph__image {
    width: baseline(4);
    background: no-repeat center;
    background-size: cover;
  }
  .paragraph__content {
    padding: $spacer*1.5 $spacer*2;

    @include desktop() {
      padding: $spacer*2 $spacer*3;
    }
  }
  .paragraph__title {
    color: $text;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  .paragraph__results-links {
    display: flex;
    border-left: 1px solid $white;
    padding: 0.75rem 1rem 0 1rem;
    flex-direction: column;
  }

  .paragraph__results-link {
    flex-grow: 0;
    margin-bottom: 0.75rem;
  }

  .paragraph__score {
    color: $white;
    width: 45px;
    margin-right: 1rem;
    text-align: center;

    @include score-background();
  }
  .paragraph__score--none {
    background-color: $white;
    color: $tm-neutral-black;
  }

  .paragraph__results-text {
    color: $tm-neutral-extraheavy;
    font-size: 0.8rem;
    line-height: 1.2;
    margin-bottom: 2px;
  }
  .paragraph__results-bar--regular {
    @include desktop() {
      max-width: 66%;
    }
  }
