// Positioning is handled by JS: https://github.com/FezVrasta/popper.js

$tooltip-arrow-size: 10px;
$tooltip-horizontal-margin: 5px;

.tooltip-trigger--icon {
  cursor: pointer;
}

.tooltip__content {
  padding: 1rem;
  font-size: 1rem;
  font-weight: $weight-normal;
  background-color: $term-popover-background-color;
  color: $term-popover-text-color;
  box-shadow: none;

  & > * {
    margin-bottom: 0;
  }
}
.tooltip__title {
  color: $link-color;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  border-color: $tm-neutral-black;
  transform: none !important;
}

.tippy-box[data-placement^="top"] .tippy-arrow {
  border-width: $tooltip-arrow-size $tooltip-arrow-size 0 $tooltip-arrow-size;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -$tooltip-arrow-size;
  left: calc(50% - #{$tooltip-arrow-size}) !important;
}

.tippy-box[data-placement^="right"] .tippy-arrow {
  border-width: $tooltip-arrow-size $tooltip-arrow-size $tooltip-arrow-size 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -$tooltip-arrow-size;
  top: calc(50% - #{$tooltip-arrow-size}) !important;
}

.tippy-box[data-placement^="bottom"] .tippy-arrow {
  border-width: 0 $tooltip-arrow-size $tooltip-arrow-size $tooltip-arrow-size;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -$tooltip-arrow-size;
  left: calc(50% - #{$tooltip-arrow-size}) !important;
}

.tippy-box[data-placement^="left"] .tippy-arrow {
  border-width: $tooltip-arrow-size 0 $tooltip-arrow-size $tooltip-arrow-size;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -$tooltip-arrow-size;
  top: calc(50% - #{$tooltip-arrow-size}) !important;
}
