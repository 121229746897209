$option-margin: .75rem;

.graphic-gap-multiple-match__gap-items-wrapper {
  height: 100%;
  position: relative;
}

.graphic-gap-multiple-match__gap-item {
  align-items: center;
  box-shadow: inset 0 0 0 transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  transition: all .3s ease-in-out;

  &--stackable {
    align-items: start;
    align-content: start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 90px));
    grid-template-rows: repeat(auto-fit, minmax(0, 90px));
    justify-content: start;

    .graphic-gap-multiple-match__options-item--regular {
      background-color: $white;
      margin-bottom: $option-margin;
    }
  }

  &:empty {
    border: 1px solid $input-default-border-color;

    &:hover {
      border-color: darken($input-default-border-color, 20);
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
    }
  }

  .graphic-gap-multiple-match__gap-item:not(.graphic-gap-multiple-match__gap-item--stackable) .graphic-gap-multiple-match__options-item--regular {
    position: absolute;
  }

  &--draggable {
    .graphic-gap-multiple-match__options-item--regular {
      cursor: move;
    }
  }

  &--correct,
  &--incorrect,
  &--predefined,
  &--fixed {
    pointer-events: none;
  }

  &--predefined {
    .graphic-gap-multiple-match__options-item--regular {
      background-color: $exercise-correct-medium;
      border-color: transparent;
      color: $white;

      &::before {
        background-color: $exercise-correct-medium;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.25);
        content: '\22C6';
      }
    }
  }

  &--correct {
    .graphic-gap-multiple-match__options-item--regular {
      background-color: $exercise-correct-medium;
      border-color: transparent;
      color: $white;

      &::before {
        background-color: $exercise-correct-medium;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.25);
        content: '\2713';
      }
    }
  }

  &--incorrect {
    .graphic-gap-multiple-match__options-item--regular {
      background-color: $exercise-incorrect-medium;
      border-color: transparent;
      color: $white;

      &::before {
        background-color: $exercise-incorrect-medium;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.25);
        content: '\2421';
      }
    }
  }

  &--skipped {
    .graphic-gap-multiple-match__options-item--regular {
      background-color: $exercise-incorrect-medium;
      border-color: transparent;
      color: $white;
      transform: translateX(-54%);

      &::before {
        background-color: $exercise-incorrect-medium;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.25);
        content: '\2421';
      }
    }

    .graphic-gap-multiple-match__options-item--skipped {
      background-color: $white;
      border-color: $exercise-correct-medium;
      border-style: dashed;
      color: $exercise-correct-medium;
      transform: translateX(60%);

      &::before {
        background-color: $exercise-correct-medium;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.25);
        content: '\2713';
      }
    }
  }

  &--circle {
    border-radius: 100%;
  }
}

.graphic-gap-multiple-match__options-item--image {
  background-color: transparent !important;
  border: none !important;

  .graphic-gap-multiple-match__options-inner & {
    height: auto !important;
    width: auto !important;

    img {
      height: auto;
      max-height: 100%;
      max-width: 120px;
    }
  }

  > img {
    max-width: 120px;
    width: 100%;
  }
}

.graphic-gap-multiple-match__options-inner {
  display: flex;
  flex-flow: row wrap;

  .graphic-gap-multiple-match__options-item {
    cursor: move;
    margin-bottom: $option-margin;
  }

  &--disabled .graphic-gap-multiple-match__options-item {
    cursor: default;
  }
}

.graphic-gap-multiple-match__options-item {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: $option-margin;
  user-select: none;

  &:hover {
    z-index: 99999;
  }

  &--skipped,
  &--regular {
    border: 1px solid $hotspot-selected-color;
    min-height: 2.5rem;
    min-width: 2.5rem;
    position: relative;

    .graphic-gap-multiple-match__gap-item--single-answer &.graphic-gap-multiple-match__options-item--image {
      height: 100%;
      width: 100%;
    }

    &:not(.graphic-gap-multiple-match__options-item--image) {
      line-height: 2.5rem;
      min-height: 2.5rem;
      min-width: 2.5rem;
      padding: 0 .7rem;
    }

    &::before {
      align-items: center;
      color: $white;
      content: '';
      display: flex;
      font-family: 'TMPika';
      height: $option-margin * 2;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      transform: translate(-50%, -50%);
      width: $option-margin * 2;
    }
  }
}

.graphic-gap-multiple-match__gap-items-trashzone {
  position: absolute;
  left: -5rem;
  top: -1rem;
  bottom: -9rem;
  right: -9rem;
  padding: 1rem 9rem 9rem 5rem;
}

.graphic-gap-multiple-match__gap-items-trashzone > .sortable-ghost {
  display: none;
}
