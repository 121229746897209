.theory-page-header {
  background: $white;
  .breadcrumb {
    display: none;
    @include tablet() { display: flex; }
  }
}

.theory-page-header__title {
  padding: 0.5rem 1.5rem;
  max-width: $tablet;
  margin: 0 auto;
}

.theory-page-header__navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $tm-neutral-light;
  padding: 0.25rem 1.5rem;
  @include tablet() {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.theory-page-header__navigation-previous,
.theory-page-header__navigation-next {
  flex-basis: 40%;
}

.theory-page-header__navigation-previous {
  text-align: left;
  padding-right: 1rem;
}

.theory-page-header__navigation-back {
  text-align: center;
  flex-basis: 20%;
}

.theory-page-header__navigation-next {
  text-align: right;
  padding-left: 1rem;
}

.theory-page-header__link {
  font-size: 0.875rem;
  color: $body-color;
  &:hover { color: $black; }

  display: inline-flex;
  align-items: center;
}

.theory-page-header__link--larger {
  font-size: 1rem;
  font-weight: $weight-bold;
}

.theory-page-header__link-icon { font-size: 1.5em; }

.theory-page-header--fixed {
  position: fixed;
  z-index: 10;
  top: $navbar-height;
  left: 0;
  right: 0;

  .breadcrumb { display: none; }

  box-shadow: $sticky-header-shadow;

  .theory-page-header__title {
    @include tablet() {
      padding-top: 0.333rem;
      padding-bottom: 0.333rem;
    }
  }

  .labeled-title {
    @include tablet() {
      font-size: 1.75rem;
    }
  }
}

.theory-page-header--splitscreen {
  position: sticky;
  top: 0;
  z-index: 10;

  .theory-page-header__navigation {
    // Note: add space + height for alignment with close button
    padding-right: 4.25rem;
    min-height: 3.5rem;
    align-items: center;
  }

  .theory-page-header__title {
    max-width: unset;
    padding-top: 0.5rem;
    padding-bottom: 0.333rem;
  }
}

.theory-page-header--singletopic {
  .theory-page-header__title {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 4rem;
  }
}

.theory-page-header--stuck {
  box-shadow: $sticky-header-shadow;
}
