.qti-graphic-hotspot__image {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

.qti-graphic-hotspot__checkbox-wrapper {
  position: absolute;

  .option--checkbox {
    display: block;
    height: 100%;
    width: 100%;

    .hottext-input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    .hotspot {
      background: #999;
      box-shadow: 0 0 0 2px $white;
      height: 100%;
      transition: background, border 0.3s ease-in-out;
      width: 100%;

      &:hover {
        cursor: pointer;
      }

      &--predefined {
        pointer-events: none;
        background: $exercise-correct-medium;
        box-shadow: 0 0 0 2px $exercise-correct-medium;
        color: $white;
        &:before {
          color: $white;
          justify-content: space-around;
          font-family: $pika;
          text-align: center;
          line-height: 1;
          font-size: 1.5rem;
          content: $pika-star;
        }
      }
    }

    &:not(.correct):not(.incorrect):not(.missed) .hottext-input {
      &:checked + .hotspot {
        background: #333;
        border-color: white;
      }
    }

    .hottext-input {
      &--disabled + .hotspot,
      &[disabled] + .hotspot {
        cursor: default;
      }
    }

    &.correct .hotspot {
      background-color: $tm-good-dark;
    }

    &.incorrect .hotspot {
      background-color: $exercise-incorrect-medium;
    }

    &.missed .hotspot {
      background-color: $white;
      border: 2px dashed $exercise-incorrect-medium;

      [class*="icon-"] {
        color: $exercise-incorrect-medium;
      }
    }

    [class*="icon-"] {
      font-size: 1.5rem; // show a bit larger here to make it stand out more
      height: 1.25rem; // fix off-center position for icons to vertically align better
    }
  }
}

.qti-graphic-hotspot__checkbox-wrapper--circle {
  transform: translate(-50%, -50%);

  .hotspot {
    border-radius: 100%;
  }
}
