$modal-default-spacing: 3rem;
$modal-border-radius: 15px;
$green--enabled: #97BE0C;

.modal--card {
  .modal-background {
    position: fixed;

    &:hover {
      cursor: url('../images/close-modal.svg') 24 24, auto; // 24 = 50% of image-dimensions (without unit!), this moves the origin/center of the cursor
    }
  }

  &.is-active {
    display: block;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .modal__inner {
    margin: $modal-default-spacing auto;
    position: relative;
    width: 80%;
    z-index: 99;
  }

  .modal__header {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: flex;
      padding-bottom: 10rem;
    }

    &.modal__header--additional-controls::before {
      padding-bottom: 15rem;
    }

    &::after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100%);
      bottom: 0;
      content: '';
      display: flex;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--no-bg::after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), $quickscan-blue;
    }

    .modal__close {
      @include close-button(3rem, 1px, $white, $white, 100%);

      margin-left: auto;
      margin-right: 1.5rem;
      z-index: 99;
    }
  }

  .modal__header-inner {
    align-items: flex-start;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    padding: $modal-default-spacing;
    right: 0;
    top: 0;
    z-index: 1;

    .modal__lesson-status-button {
      display: inline-flex;
      flex: 0 0 auto;
      transition: all .3s ease-in-out;

      &:not(.toggled) {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
        color: $grey-light;

        .modal__lesson-status-icon path {
          stroke: $grey-light;
        }
      }
    }


    .modal__lesson-status-button:hover,
    .modal__lesson-status-button.toggled {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

      .modal__lesson-status-label {
        color: $green--enabled;
      }

      .modal__lesson-status-icon path {
        stroke: $green--enabled;
      }
    }

    .modal__lesson-status-icon {
      overflow: visible;
      transform: scale(1.25);
    }

    .modal__lesson-status-label {
      font-size: 1.125rem;
    }
  }

  .modal__content {
    margin: 0;
    width: 100%;
    background-color: $white;
    border-bottom-left-radius: $modal-border-radius;
    border-bottom-right-radius: $modal-border-radius;
    overflow: hidden;
    padding: $modal-default-spacing;
  }

  .modal__pretitle {
    color: $white;
    font-size: .75rem;
    margin: 0;
    text-transform: uppercase;
  }

  .modal__title {
    color: $white;
    font-family: $family-serif;
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.25rem;
    margin: 0;
    position: relative;

    @media only screen and (max-width: 530px) {
      font-size: 1.5rem;
    }

    &:not(:first-child) {
      margin-top: .75rem;
    }
  }
}

.modal__status-toggle.question-label {
  align-items: center;
  box-shadow: 0 0 0 rgba(0, 0, 0, .2);
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-left: auto;
  margin-right: 1.5rem;
  min-width: 3rem;
  padding: 0 0.75rem;
  transition: all 0.5s ease-in-out;
  z-index: 99999;

  .card--modal-trigger > & {
    display: flex;
    height: 1.5rem;
    margin-right: 0;
    min-width: 0;
    position: absolute;
    right: .75rem;
    top: .7rem;
    width: 1.5rem;
    z-index: 10;

    &::before {
      font-size: .75rem;
      margin-top: 1px;
    }
  }

  + .modal__close {
    margin-left: 0;
  }

  span {
    color: $white;
    transition: all .5s ease-in-out .6s;
    visibility: hidden;
    width: 0;

    .card--modal-trigger & {
      display: none;
    }
  }

  &:not(.question-label--score-good) {
    background-color: rgba(255, 255, 255, .25);

    &:hover {
      background-color: rgba(255, 255, 255, .5);
    }
  }

  &.question-label--score-good {
    background-color: #97BE0C;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);

    span {
      margin-left: .5rem;
      visibility: visible;
      width: 100%;
    }
  }

  [type=checkbox] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 0;
  }

  &::before {
    color: $white;
    font-size: 1.125rem;
    margin-right: 0;
  }
}

.modal__header--additional-controls .question-label--score-good {
  padding: 0 1rem;
}
