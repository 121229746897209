.progress-summary {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content,
  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.25rem;
  }

  &__header {
    font-weight: bold;
  }

  &__children {
    margin-bottom: 0.75rem;
  }

  &__name {
    cursor: auto;
  }

  &__name,
  &__header-name {
    width: 50%;
    @include truncate-text();

    @include tablet() {
      width: 40%;
    }
    @include desktop() {
      width: 35%;
    }
  }

  &__header-score {
    width: 3rem;
    flex-grow: 0;
  }

  &__percentage {
    font-size: 0.9rem;
    font-weight: bold;
    width: 2.5rem;
    text-align: right;
    margin-top: 4px;
  }

  &--expandable > .progress-summary__content > .progress-summary__name {
    cursor: pointer;
  }

  &--is-expanded > .progress-summary__content > .progress-summary__name {
    font-weight: bold;
  }
}
