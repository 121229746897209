$light-green: $exercise-correct-light;
$medium-green: $exercise-correct-medium;
$dark-green: $exercise-correct-dark;

$light-purple: $exercise-incorrect-light;
$dark-purple: $exercise-incorrect-dark;

$input-default-border-radius: 100%;
$input-focus-border-radius: 0;
$hotspot-height-unitless: 40;
$input-height-unitless: 35;
$border-width-unitless: 1;
$hotspot-height: $hotspot-height-unitless + px;
$input-height: $input-height-unitless + px;
$border-width: $border-width-unitless + px;

.interaction-image-wrapper {
  display: inline-block;
  position: relative;
  max-width: $interaction-image-max-width;
}

.interaction-input {
  @at-root span#{&},
  &[contenteditable=false] {
    user-select: none;
  }
}

.interaction-input--regular {
  align-items: center;
  border: $border-width solid;
  display: inline-flex;
  height: ($input-height-unitless - (2 * $border-width-unitless))+px;
  padding: 0 .3em;
  position: relative;
  white-space: nowrap;

  div.interaction-input--hotspot & {
    box-sizing: border-box;
    height: $input-height;
  }

  &::before {
    align-items: center;
    color: #fff;
    display: flex;
    font-family: $icon;
    justify-content: center;
    position: absolute;
    padding-top: 1px;
    top: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    width: calc(1.5em - #{$border-width} - #{$border-width});
  }

  &::after {
    content: $zero-width-space;
  }

  &[type=text] {
    border: $border-width solid $input-default-border-color;
    border-radius: 0;
    font-size: inherit;
    line-height: normal;
    min-width: 75px;
    padding: 5px;
    appearance: none;

    &:hover {
      border-color: $input-default-border-color-hover;
    }

    &:focus,
    &:active {
      border-color: $input-focus-border-color;
      outline: 0;
    }

  }

  &:not(:empty),
  &.not-empty {

    &,
    &:focus {
      border-radius: $input-focus-border-radius;

      @at-root input[type=text]#{&} {
        box-sizing: content-box;
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  &.muted {
    color: $muted-text-color;
  }

  &.predefined,
  &.correct,
  &.incorrect,
  &.missed {
   padding-left: 1.7em;
  }

  &.predefined {
    background-color: $light-green !important;
    border-color: $medium-green;
    color: $dark-green;

    &::before {
      background-color: $medium-green;
      content: $icon-star;
    }
  }

  &.correct {
    background-color: $light-green !important;
    border-color: $medium-green;
    color: $dark-green;

    &::before {
      background-color: $medium-green;
      content: $icon-checkmark;
    }
  }

  &.incorrect {
    border-color: $dark-purple;
    color: $dark-purple;

    &::before {
      background-color: $dark-purple;
      content: $icon-cross;
    }

    &.interaction-input--hotspot {
      border-bottom-color: $dark-purple;
    }
  }

  &.missed {
    border: $border-width dashed;
    color: $dark-green;

    &::before {
      background-color: $light-green;
      color: $dark-green;
      content: $icon-checkmark;
    }
  }

  &.self-scored {
    border-color: $input-default-border-color;
  }
}

.interaction-input--standalone {
  padding: 0;
  &.predefined,
  &.correct,
  &.incorrect,
  &.missed {
    padding-left: calc(1.5em - #{$border-width} - #{$border-width});
  }
}

.interaction-input-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;

  .interaction-input {
    background-color: transparent;
    flex: 0 0 auto;
    font-size: 1rem;
    text-align: center;

    &:not(.correct):not(.incorrect):not(.muted):not(.not-empty) {
      border: 1px solid $black;
      opacity: .3;
      outline: 1px solid $white;
    }

    &:focus,
    &:hover {
      opacity: .7 !important;
      z-index: 9999999999;
    }

    &.not-empty,
    &:focus {
      background-color: $white;
      opacity: 1 !important;
    }
  }
}

.interaction-input--hotspot {
  align-items: center;
  background: $input-default-background-color;
  border: $border-width solid $input-default-border-color;
  border-radius: $input-default-border-radius;
  box-sizing: border-box;
  color: #000;
  display: flex;
  font-family: $family-sans-serif;
  font-size: 1rem;
  height: $hotspot-height;
  justify-content: center;
  min-width: $hotspot-height;
  padding: .45rem;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  transition: height 0.3s ease-in-out;

  @at-root div#{&} {
    background: transparent !important;
    border: none;
    position: absolute;

    .interaction-input--regular {
      background: #ffffff;

      &:not(:last-child) {
        margin-right: 0.125rem;
      }
    }
  }

  &:not([type=text]):empty.pulsating,
  &[type=text].empty.pulsating {
    &:not(:focus) {
      animation: fadein .75s, pulse 3.5s infinite;
    }
  }

  &:not(:empty),
  &[type=text].not-empty {
    background: $input-not-empty-background-color;
    height: ($input-height-unitless - (2 * $border-width-unitless))+px;
    padding-bottom: 0;
    padding-top: 0;

    &,
    &:focus {
      border-radius: $input-focus-border-radius;
    }
  }

  &:focus {
    animation: none !important;
    border-radius: $input-focus-border-radius;
    height: ($input-height-unitless - (2 * $border-width-unitless))+px;
    outline: none;
    padding-bottom: 0;
    padding-top: 0;
  }

  @at-root span#{&}[contenteditable=false] {
    cursor: default;
  }
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeout {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes pulse {
	from {
		box-shadow: 0 0 0 0 rgb(255, 255, 255);
	}
	to {
		box-shadow: 0 0 0 0.75rem rgba(0, 0, 0, 0);
	}
}
