.question__primary-container,
.question__secondary-container {
  position: relative;
  padding: 1.5rem;
  @include tablet() {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
  background-color: $white;

  .question-page--splitscreen-open & {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
}

.question__secondary-container {
  padding-top: 0;
  padding-bottom: 2rem;
  @include last-child-no-bottom-margin();
}

.question__secondary-container--with-result,
.question__secondary-container--submittable {
  padding-top: 2rem;
  background-color: $tm-background-blue;
}
