$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex'

@each $display in $displays
    .is-#{$display}
        display: #{$display} !important
    +mobile
        .is-#{$display}-mobile
            display: #{$display} !important
    +tablet
        .is-#{$display}-tablet
            display: #{$display} !important
    +tablet-only
        .is-#{$display}-tablet-only
            display: #{$display} !important
    +touch
        .is-#{$display}-touch
            display: #{$display} !important
    +desktop
        .is-#{$display}-desktop
            display: #{$display} !important
    +desktop-only
        .is-#{$display}-desktop-only
            display: #{$display} !important
    +widescreen
        .is-#{$display}-widescreen
            display: #{$display} !important
    +widescreen-only
        .is-#{$display}-widescreen-only
            display: #{$display} !important
    +fullhd
        .is-#{$display}-fullhd
            display: #{$display} !important

.is-hidden
    display: none !important

.is-sr-only
    border: none !important
    clip: rect(0, 0, 0, 0) !important
    height: 0.01em !important
    overflow: hidden !important
    padding: 0 !important
    position: absolute !important
    white-space: nowrap !important
    width: 0.01em !important

+mobile
    .is-hidden-mobile
        display: none !important

+tablet
    .is-hidden-tablet
        display: none !important

+tablet-only
    .is-hidden-tablet-only
        display: none !important

+touch
    .is-hidden-touch
        display: none !important

+desktop
    .is-hidden-desktop
        display: none !important

+desktop-only
    .is-hidden-desktop-only
        display: none !important

+widescreen
    .is-hidden-widescreen
        display: none !important

+widescreen-only
    .is-hidden-widescreen-only
        display: none !important

+fullhd
    .is-hidden-fullhd
        display: none !important

.is-invisible
    visibility: hidden !important

+mobile
    .is-invisible-mobile
        visibility: hidden !important

+tablet
    .is-invisible-tablet
        visibility: hidden !important

+tablet-only
    .is-invisible-tablet-only
        visibility: hidden !important

+touch
    .is-invisible-touch
        visibility: hidden !important

+desktop
    .is-invisible-desktop
        visibility: hidden !important

+desktop-only
    .is-invisible-desktop-only
        visibility: hidden !important

+widescreen
    .is-invisible-widescreen
        visibility: hidden !important

+widescreen-only
    .is-invisible-widescreen-only
        visibility: hidden !important

+fullhd
    .is-invisible-fullhd
        visibility: hidden !important
