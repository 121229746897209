.stream-page-scrollspy-container {
  overflow: hidden;
}

.stream-page__header {
  border-bottom: 1px solid $tm-neutral;
}

.stream-page__completed {
  background-color: $tm-background-gray;
}
.stream-page__completed-header {
  cursor: pointer;

  &:hover {
    background-color: $tm-neutral-dark;
  }
}
.stream-page__completed-toggle-collapse {
  font-size: 1.2rem;

  transform: rotate(180deg);

  .is-collapsed & {
    transform: rotate(0deg);
  }
}
.stream-page__completed-content {
  border-top: 2px solid $tm-neutral-light;
  .is-collapsed & {
    display: none;
  }
}
