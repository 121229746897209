.upload-widget {
  &:not(.is-uploading) .upload-widget__dropzone.uploadcare--dragging {
    background-color: $upload-dropzone-background;

    .upload-widget__drop-message { display: inline-block; }
    .upload-widget__dropzone-inner { border-color: $upload-dropzone-border; }

    .upload-widget__upload-button,
    .upload-widget__progress {
      display: none !important;
    }
  }
}

.upload-widget__preview-loading {
  display: none;
}

.upload-widget__preview {
  display: none;
}
.upload-widget__preview-image-container {
  background-color: $tm-neutral-pale;
  display: flex;
  justify-content: center;
}
.upload-widget__preview-link {
  display: inline-block;
  line-height: 0;
  position: relative;

  &:after {
    content: $pika-zoom-in;
    font-family: $pika;
    font-size: 26px;
    line-height: 38px;
    height: 36px;
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: center;
    width: 36px;
    color: $body-color;
    opacity: 0.75;
    background: $white;

    transition: opacity 300ms;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}
.upload-widget__preview-image {
  width: 100%;
  height: auto;
}

.upload-widget__dropzone {
  background-color: $tm-background-blue;
  padding: 1.6rem;
  height: 300px;
  width: 100%;
  text-align: center;
}
.upload-widget__dropzone-inner {
  border: 4px dashed $tm-background-gray;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload-widget__drop-message {
  display:none;
  color: $upload-dropzone-border;
  font-family: $family-serif;
  font-size: 1.5rem;
}
.upload-widget__progress {
  display: none;
}
.upload-widget__progress-label {
  font-family: $family-serif;
  font-size: 1.5rem;
}
.upload-widget__progress-bar {
  display: inline-block;
  width: 300px;
  height: 5px;
  background-color: $tm-neutral;
  border-radius: 3px;
  overflow: hidden;
}
.upload-widget__progress-meter {
  width: 0;
  height: 100%;
  background: $tm-blue;
}
.upload-widget__error-message {
  display: none;
}


// Modify uploadcare specific stuff, TODO details;
.uploadcare--powered-by { display: none; }
.uploadcare--dialog__container { border-radius: 0; }

.uploadcare--dialog {
  .uploadcare--menu__items {
    background-color: $tm-background-gray;
    font-family: $family-sans-serif;
  }
  .uploadcare--menu__item { color: $base-text-color; }
  .uploadcare--panel__content { color: $base-text-color; }
  .uploadcare--panel { font-family: $family-serif; }

  .uploadcare--button_primary,
  .uploadcare--footer__button {
    @extend .button;
    @extend .button--primary;
    font-weight: normal;
    border-color: transparent;
    outline: 0;
  }

  .uploadcare--dialog__close {
    position: absolute;
    right: 0;
    top: 0;
    color: $base-text-color;
    padding: 0;
    font-size: 3.2rem;

    & > svg { display: none; }
    &:after {
      font-family: $icon;
      content: $icon-close;
    }
  }
}
