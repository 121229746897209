.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @include tablet() { flex-direction: row; }

  &__card {
    padding: 0 0.5rem;
    margin-bottom: 1rem;

    width: 100%;
    @include tablet() { width: 50%; }
    @include widescreen() { width: 33.33%; }
  }
}
