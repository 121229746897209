.question__body,
.question__interaction {
  clear: both;
  @include clearfix(); // TODO: make clearfix obsolete; floats are only used at '.list'-definition (_list.scss:20)
}

.question__body {
  .question-intro {
    margin-bottom: 2rem;
  }
}

.question__controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include tablet() {
    flex-direction: row;
  }

  .is-breakpoint-mobile & {
    flex-direction: column;
  }
}

.button--sequence-next-exercise {
  margin-left: auto;
}

.question__navigation {
  display: flex;
  justify-content: flex-end;

  &.question__navigation--simplified:not(:first-child) {
    margin-left: 1rem;
  }
}

.question-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include tablet-landscape() {
    flex-direction: row;
  }
}

.question-grid__main {
  order: 2;

  @include tablet-landscape() {
    flex-basis: 60%;
    order: 1;
  }
}

.question-grid__side {
  order: 1;

  @include tablet-landscape() {
    order: 2;
    margin-left: 1.5rem;
    flex-basis: 40%;
  }
}
