$_arrow-size: 30px;

.theory-related-links {
  clear: both;
  position: relative;
  padding: 3rem 0 1.5rem 0;
  background-color: $tm-neutral-light;

  &:before {
    // TODO to mixin
    display: block;
    position: absolute;
    content: "";

    width: 0;
    height: 0;
    border: $_arrow-size solid transparent;
    border-top-color: $white;

    top: 0;
    left: 50%;
    margin-left: -$_arrow-size;
  }

  li {
    &.link { margin: baseline(0.25) 0; }
    &.linklist { margin: baseline(1) 0 }
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }

  a {
    display: inline-block;
    margin-bottom: baseline(0.125);
  }

  .linklist-title {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: baseline(0.125);
  }

  .linklist-description { margin-bottom: baseline(0.5); }
}

// When related links are displayed as part of the sections (not as bottom of page);
// adjust whitespace and arrow size
.topic > .theory-related-links {
  padding: 2.25rem 1.125rem 1.125rem 1.125rem;
  margin-bottom: 1.5rem;
  &:before {
    border-width: divide($_arrow-size, 1.5);
    margin-left: -(divide($_arrow-size, 1.5));
  }
}

.theory-related-links .container {
  padding-left: 0;
  padding-right: 0;
  @include tablet() {
    padding-left: baseline(1);
    padding-right: baseline(1);
  }
}
