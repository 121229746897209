.loader {
  background: url('../images/loader.gif') no-repeat center center;
  width: 16px;
  height: 11px;

  &--centered {
    margin: 0 auto;
  }

  &--inline {
    display: inline-block;
  }
}

