// Typekit fonts
$proxima-nova: proxima-nova, sans-serif;
$ceacilia: caecilia, serif;

// Google fonts
$pacifico: 'Pacifico', cursive;

$family-sans-serif: $proxima-nova;
$family-serif: $ceacilia;

// Pika
@font-face {
  font-family: 'pika';
  src: url('../fonts/pika/pika.eot');
  src: url('../fonts/pika/pika.eot?#iefix') format('embedded-opentype'),
  url('../fonts/pika/pika.woff') format('woff'),
  url('../fonts/pika/pika.ttf') format('truetype'),
  url('../fonts/pika/pika.svg#pika') format('svg');
  font-weight: normal;
  font-style: normal;
}
$pika: pika;

// TMPika: (slightly modified (old) version of Pika font, includes some custom icons)
@font-face {
  font-family: 'TMPika';
  src: url('../fonts/tm-pika/tm-pika.woff') format('woff'),
  url('../fonts/tm-pika/tm-pika.ttf') format('truetype');
}

@font-face {
  font-family: 'TMPika';
  src: url('../fonts/tm-pika/tm-pika.woff') format('woff'),
  url('../fonts/tm-pika/tm-pika.ttf') format('truetype');
  font-weight: 700; // to avoid faux-bold
}
$icon: TMPika;

@font-face {
  font-family: 'din';
  src: url('../fonts/din/din-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'din';
  src: url('../fonts/din/din-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
$din: din;
