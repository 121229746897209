:root {
  --topOffset: 0;
}

$column-gap: 0.75rem;
$container-offset: (2 * $gap) !default;

.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;

  &.is-fluid {
    max-width: none;
    padding-left: $gap;
    padding-right: $gap;
    width: 100%;
  }

  @include desktop {
    max-width: $desktop - $container-offset;
  }
  @include until-widescreen {
    &.is-widescreen {
      max-width: $widescreen - $container-offset;
    }
  }
  @include until-fullhd {
    &.is-fullhd {
      max-width: $fullhd - $container-offset;
    }
  }
  @include widescreen {
    max-width: $widescreen - $container-offset;
  }
  @include fullhd {
    max-width: $fullhd - $container-offset;
  }
}
@import "~bulma/sass/grid/columns.sass";

$container-padding: 1.5rem;

.page {
  position: relative;
}

// Set general properties for the container
.container {
  max-width: $desktop;
  padding-left: $container-padding;
  padding-right: $container-padding;

  @include fullhd() {
    max-width: $fullhd;
  }
}

.container-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh - #{$navbar-height});

  body.with-redesign-transition-navbar & {
    height: calc(100vh - 5rem);
    @media screen and (min-width: 1440px) {
      height: calc(100vh - 6.75rem);
    }
  }
}

.container-grid {
  height: calc(100vh - #{$navbar-height});
  display: grid;
  overflow: auto;
  grid-auto-rows: max-content;

  body.with-redesign-transition-navbar & {
    height: calc(100vh - 5rem);
    @media screen and (min-width: 1440px) {
      height: calc(100vh - 6.75rem);
    }
  }
}

.container-fluid {
  width: 100%;
  padding-left: baseline(1.25);
  padding-right: baseline(1.25);
}

.container--sticky {
  background-color: $white;
  border-bottom: 1px solid $grey-dark;
  padding: 0.5rem 0;
  position: sticky;
  top: var(--topOffset);
  z-index: 99;
}

.container--carousel {
  max-width: 960px;
  padding: 0 4.5rem;
}

.container--terms {
  margin: 0;
  padding: 1.5rem 1.5rem 1.5rem 4rem;
}

.container--narrow {
  max-width: $tablet;
  @include until($tablet) {
    .has-reading-progress & {
      margin-left: 1.5rem;
    }
  }
}

.container--with-navigation-bottom {
  @include tablet() {
    padding-bottom: $navbar-height * 2;
  }
}

.tab-pages {
  &--theory { padding-top: $theory-tabs-height; }
}

.page-header {
  backface-visibility: hidden;
  padding: baseline(0.5) baseline(1.25);
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  @include from($tablet) {
    flex-direction: row;
  }

  body.modal-opened & {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .15);
    z-index: 999;
  }
}

@include tablet() {
  .columns.is-full-height {
    min-height: calc(100vh - (#{$navbar-height} - 0.75rem));
    max-height: calc(100vh - (#{$navbar-height} - 0.75rem));
    height: calc(100vh - (#{$navbar-height} - 0.75rem));
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    .column {
      overflow-y: auto;
    }
  }
}

.stick-to-top {
  background-color: $white;
  position: sticky;
  top: $navbar-height;
  z-index: 2;

  &.is-stuck {
    box-shadow: $sticky-header-shadow;
  }

  &--splitscreen {
    top: 0;
  }
}

.usabilla__overlay iframe {
  height: inherit;
}

.has-background-grey-lighter {
  background-color: $tm-neutral-light;
}
.has-border-bottom {
  border-bottom: 1px solid $tm-neutral-light;
}
.overflow-auto {
  overflow: auto;
}
.is-relative {
  position: relative;
}
