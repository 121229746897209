.question-title {
  position: relative;
  font-size: 1rem !important;
  padding-left: 4.375rem;
  margin-left: -4.375rem;
  padding-right: 3.5rem; /* prevent RS control from overlapping the title */

  .question-title__label {
    // TODO fix responsive
    display: none;
    @include desktop() {
      display: block;
    };

    position: absolute;
    top: baseline(-1);
    left: 0;
    background: url('../images/number-corner.svg') no-repeat bottom right;
    background-size: 95%;
    font-family: $family-serif;
    font-weight: $question-title-font-weight;

    height: 60px;
    line-height: 65px;
    width: 60px;

    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: .4153rem;
    &--length-1,
    &--length-2,
    &--length-3 {
      font-size: 1rem;
    }

    &--length-4,
    &--length-5 {
      font-size: .7615rem;
    }
    &--length-6 { font-size: .66rem; }
  }

  // The title component on a self test page only shows a flag icon:
  .question-title__label--self-test .icon {
    color: $tm-neutral-dark;
    &:before { font-size: 1.5rem; }
  }

  .question-title__text {
    font-size: 1.625rem;
    font-family: $family-serif;
    font-weight: $question-title-font-weight;
  }
}
