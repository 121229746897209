.rubric__options {
  display: flex;
  flex-direction: column;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  @include tablet-landscape() {
    flex-direction: row;
  }
}

.rubric__level {
  flex-grow: 0;
  text-align: right;
  margin-bottom: -0.5rem;
  margin-right: -0.25rem;
  @include empty-placeholder();
}

.rubric__option {
  position: relative;
  flex: 1 1 auto;
  background: $white;
  border-radius: $button-border-radius;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:hover:not(.rubric__option--selected) {
    background-color: $tm-neutral;
  }
}

.rubric__option--selected {
  background-color: $interaction-active-color;
  color: $white;
  .rubric__level {
    color: rgba($white, 0.8);
    @include icon-before($pika-check, $margin-right: 0.25rem, $size: 1.25em);
  }
}

.rubric__option--disabled {
  pointer-events: none;
  cursor: default;
}

.rubric__navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.rubric__navigation-item {
  flex: 1 1 auto;
  width: 33%;
  text-align: center;

  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
}

.rubric-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid $tm-neutral;

  @include tablet-landscape() {
    align-items: center;
    flex-direction: row;
  }
}

.rubric-result__bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  @include tablet-landscape() {
    width: auto;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}

.rubric-result__option {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
  margin-bottom: 0.5rem;
  padding: 0.1rem;
  background: $tm-neutral;
  color: $tm-neutral-extraheavy;
  @include empty-placeholder();
  @include tablet-landscape() {
    margin-bottom: 0;
  }
}

.rubric-result__option--selected {
  background-color: $tm-neutral-extraheavy;
  color: $white;
  @include icon-before($pika-check, $margin-right: 0.25rem, $size: 1em);
}
