.tip--simplified {
  display: inline-block;
  background-color: $simplified-tip-background-color;
  color: $simplified-tip-label-color;
  border-radius: 6px;
  padding: 0 0.75rem 0 3.5rem;
  position: relative;
  font-size: 1.1rem;

  .tip__checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .tip__label {
    padding: 0.75rem 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    position: relative;
    cursor: pointer;
    font-weight: $weight-bold;

    @include with-hoverable-icon-right();
    .icon-tm-chevron-right {
      font-size: 1.5em;
      line-height: 0;
    }
    .tip__content-close-button { display: none; }

    &:before {
      position: absolute;
      content: "";
      height: 46px;
      width: 42px;
      left: -50px;
      top: 50%;
      margin-top: -22px;
      display: block;
      background-repeat: no-repeat;
      background-position: 7px 2px;
      background-image: url('../images/foldable-tip-bulb-off.svg');
    }
  }

  .tip__content {
    display: none;
    min-width: 10rem;
    max-width: 37.5rem;
    padding-bottom: 1rem;
    & > *:last-child { margin-bottom: 0; }
  }

  .tip__checkbox:checked + .tip__label {
    display: block;

    .icon-tm-chevron-right { display: none; }
    .tip__content-close-button {
      @include close-button(20px, 2px, $simplified-tip-label-color);
      display: block;
      position: absolute;
      top: 1rem;
      right: 0.25em;
    }

    &::before {
      background-position: 0 0;
      background-image: url('../images/foldable-tip-bulb-on.svg');
    }
  }

  .tip__checkbox:checked ~ .tip__content { display: block; }
}
