@charset "utf-8";

// Web components (https://github.com/Thiememeulenhoff/frontend)
@import "~@thiememeulenhoff/themes/base.css";
// End web components

@import "theme-mixins";

@import "fonts";
@import "variables";
@import "mixins";
@import 'vue-animations';
@import 'loaders';

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

@import "type";
@import "icons";
@import "layout";

@import "score";

// Helpers
@import "helpers/interaction";
@import "helpers/_visibility.scss";
@import "helpers/_visibility.sass";
//
// Content elements (markup created in XSLT)
//
@import "content/sections";
@import "content/link";
@import "content/inline-image";
@import "content/conversation";
@import "content/poetry";
@import "content/mail";
@import "content/asset";
@import "content/quote";
@import "content/table";
@import "content/list";
@import "content/tip";

@import "components/container-with-index";
@import "components/card-container";
@import "components/spacing";

@import "components/svg-icon";
@import "components/inline-svg";
@import "components/flexbox";
@import "components/navbar";
@import "components/button";
@import "components/inline-audio-button";
@import "components/hero";
@import "components/labeled-title";
@import "components/resource-title";
@import "components/content-navigation-bar";
@import "components/modal";
@import "components/modal--card";
@import "components/breadcrumb";
@import "components/related-links";
@import "components/tooltip";
@import "components/full-screen-background";
@import "components/paragraph-goals";
@import "components/application-sidebar";
@import "components/paragraph-item";
@import "components/content-card";
@import "components/tabs";
@import "components/term-definition-list";
@import "components/embedded-topic";
@import "components/offsetted-anchor";
@import "components/popover";
@import "components/media-modal";
@import "components/table";

@import "components/reading-progress-indicator";
@import "components/chapters-exercises";
@import "components/table-of-contents";
@import "components/results-bar";
@import "components/loader";
@import "components/notification";
@import "components/notification-toaster";
@import "components/form";
@import "components/self-score-rating";
@import "components/score-label";
@import "components/readspeaker";
@import "components/rubric";

@import "components/question";
@import "components/question-container";
@import "components/question-index";
@import "components/question-title";
@import "components/question-label";
@import "components/question-score";
@import "components/selftest-results";
@import "components/theory-page-list";
@import "components/theory-page-header";
@import "components/sequence-result";
@import "components/user-feedback";
@import "components/progress-summary";
@import "components/progress-modal";

@import "components/upload-widget";
@import "components/dropdown";
@import "components/guided-tour";
@import "components/count-indicator";
@import "components/notifications";
@import "components/centered-container";
@import "components/context-menu";
@import "components/share-button";
@import "components/search";
@import "components/mathlive";

@import "pages/error-page";
@import "pages/stream-page";
@import "pages/question-page";
@import "pages/results-page";
@import "pages/about-page";
@import "pages/select-stream";

@import "components/my-edition";

@import "simplified/all";

@import "interactions";

@import "groupservices";

@import "print";
