.embedded-topic {
  position: relative;
  margin-bottom: 1.5rem;
  padding: 1.5rem 1.5rem 6rem 1.5rem;

  .embedded-topic__content {
    & > *:first-child { margin-top: 0; }
    & > *:last-child { margin-bottom: 0; }
  }

  .embedded-topic__less-more {
    position: absolute;
    z-index: 2;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 1.5rem;
  }

  .embedded-topic__show-more { display: none; }
  .embedded-topic__show-less { display: inline-block; }

  &.is-collapsed {
    overflow: hidden;
    max-height: 300px;

    .embedded-topic__show-more { display: inline-block; }
    .embedded-topic__show-less { display: none; }

    .embedded-topic__show-more { display: inline-block }
    .embedded-topic__show-less { display: none; }

    &:after {
      position: absolute;
      z-index: 1;
      display: block;
      content: "";
      top: 25%;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.invalid-embedded-content-marker {
  @include error-marker();
}
