blockquote {
  position: relative;
  clear:both;
  background: transparent;
  border: none;
  margin: baseline(1.5) 0;
  padding: 0;
  font-size: 1rem;
  line-height: inherit;
  font-family: $family-sans-serif;
  font-style: normal;
  font-weight: bold;
}

.blockquote-content p:first-of-type:before,
.blockquote-content p:last-of-type:after {
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 2.875em;
  color: $tm-neutral-heavy;
  line-height: 0.33em;
  top: 10px;
}

.blockquote-content p:first-of-type:before {
  content: open-quote;
  margin-right: 6px;
  margin-left: -3px;
}

.blockquote-content p:last-of-type:after {
  content: close-quote;
  margin-right: -3px;
  margin-left: 6px;
}


blockquote cite {
  display: block;
  font-style: normal;
  font-weight: $weight-semibold;
  color: $tm-neutral-heavy;
  font-size: 0.75rem;
  margin-top: 0.75rem;

  &:before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: .4em;
    margin-right: 0.5em;
    border-top: 1px solid $tm-neutral-heavy;
  }
}

.section--explanation,
.section--example,
.section--interaction-example {
  blockquote:before { color: $tm-neutral-light; }
  blockquote cite {
    color: $tm-neutral-light;
    &:before { border-top-color: $tm-neutral-light; }
  }
}


