.question-score {
  position: relative;
  display: flex;
}

.question-score__badge {
  @include square(64px);
  margin-right: baseline(0.5);
  flex-shrink: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;

  @include tablet() {
    @include square(128px);
    margin-right: baseline(1);
  }

  &--perfect { background-image: url('../images/badge-perfect-question.svg'); }
  &--task-complete { background-image: url('../images/badge-task-complete.svg'); }
}

.question-score__badge--offsetted {
  @include tablet() {
    margin-top: -2.5rem;
  }
}

.question-score__score {
  font-size: 1.5rem;
  .score-label {
    padding: 0.25em 0.5em;
    &.score-block {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.question-score--simplified {
  flex-direction: row;
  align-items: center;
}

.question-score--simplified,
.item-overview__exercise-result {
  .question-score__badge {
    @include square(5.125rem);
    flex-shrink: 0;
    background-size: 5.125rem;
    background-color: transparent;

    &--perfect { background-image: url('../images/simplified/smiley-perfect.svg') }
    &--good { background-image: url('../images/simplified/smiley-good.svg') }
    &--unsatisfactory { background-image: url('../images/simplified/smiley-satisfactory.svg') }
    &--poor { background-image: url('../images/simplified/smiley-poor.svg') }
    &--none { background-image: url('../images/simplified/smiley-perfect.svg') }
  }
}
.question-score--simplified {
  .question-score__badge {
    margin-right: 1rem;
  }
}
