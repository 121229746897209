@import "qti/qti-choice-single-text";

.choice-single-text label {
  p:last-of-type { margin-bottom: 0; }
}

.choice-single-text {
  &.list--horizontal {
    @media (min-width: 768px) {
      display: flex;
    }
  }

  .choice-single-text__option {
    list-style: none;
    margin: 0;
    padding: 0;

    &:not(.list-item--horizontal):not(:last-child) {
      margin-bottom: 1rem;
    }

    &.list-item--horizontal {
      @media (min-width: 768px) {
        margin-top: 0 !important;

        &:not(:last-child) {
          margin-right: 1rem !important;
        }
      }
    }
  }
}
