// Een <ul> of <ol> tag krijgt altijd een class mee waarin het type staat gedefinieerd;
// bullet|small-arabic|number|blanco, bepaal de list-type op basis van deze class
$list-item-styles: (
  bullet: disc,
  small-arabic: lower-latin,
  number: decimal,
  blanco: none
);

// Checkmarks en hyphen (streepjes) zitten niet default in de styling van lists;
// gebruik een pseudo-element om deze typen weer te geven
@mixin list-item-pseudo($content) {
  & > li {
    list-style: none outside;

    &:before {
      content: $content;
      display: inline-block;
      float: left;
      margin-left: -1.5em;
      width: 1.5em;
    }
  }
}

.list {
  // Reset list style
  list-style: none outside;
  margin: 1em 0;
  padding: 0;

  li {
    margin-left: 2em;
    padding-left: 0.3em;

    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    p:last-child {
      margin-bottom: 0;
    }
  }

  @each $name, $type in $list-item-styles {
    &.list--#{$name} {
      & > li {
        list-style: $type outside;
      }
    }
  }

  &.list--checklist {
    @include list-item-pseudo('✓');
  }

  &.list--hyphen {
    @include list-item-pseudo('\2013');
  }
}
