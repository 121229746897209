$gutter-size: 0.75rem;

.question-page {
  display: flex;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;

  top: $navbar-height + $question-page-header-height-mobile;
  @include tablet() {
    top: $navbar-height + $question-page-header-height;
  }

  padding: 0.5rem;
  @include desktop() { padding: 1.125rem; }

  flex-direction: row;
  @include tablet() {
    flex-direction: row;
  }
}

.question-page__index {
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;

  @include tablet() {
    display: block;
    width: $question-page-navigation-width;
    flex-grow: 0;
    margin-bottom: 0;
    margin-right: 0.375rem;
    padding-right: 0.125rem;
    padding-left: 0.125rem;
  }

  @include desktop() {
    width: $question-page-navigation-width-desktop;
    margin-right: 1rem;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}
.question__navigation-dropdown {
  display: block;

  .question-index__label {
    display: block;
  }

  @include tablet() {
    display: none;
  }
}

.question-pages__panes {
  display: flex;
  flex-direction: row;
  width: 100%;
  @include mobile() {
    flex-direction: column;
  }
}

.question-page__body-content {
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  position: absolute;
  width: 100%;
  top: 0;
  bottom:0;

  .no-scroll & {
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: unset;
  }
}
.question-page__body-pane,
.question-page__theory-pane {
  flex: 1;
  position: relative;
}
.question-page__theory-pane {
  background: $white;
}

.question-page__body-pane--loading {
  pointer-events: none;
}

.question-page__body-pane--loading,
.question-page__theory-pane--loading {
  @include loading-bar($height: 5px, $color: $primary);
}

.question-page__close-button {
  z-index: 10;
  position: absolute;
  right: 1.25rem;
  top: 0.5rem;
}

.question-page__theory-content {
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 0;
  bottom:0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &--error {
    padding: 1rem 1.5rem;
  }
}

//
// Gutter between panes when splitscreen is open
//
.question-page__gutter {
  position: relative;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 400"><path d="M48 152c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM0 48c0 26.5 21.5 48 48 48s48-21.5 48-48S74.5 0 48 0 0 21.5 0 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" fill="%23c9d3dd"/></svg>');
  background-size: 6px auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: ew-resize;

  &--vertical {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 96"><path transform="rotate(90 200,200)" d="M48 152c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM0 48c0 26.5 21.5 48 48 48s48-21.5 48-48S74.5 0 48 0 0 21.5 0 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" fill="%23c9d3dd"/></svg>');
    background-size: auto 6px;
    background-repeat: no-repeat;
    cursor: ns-resize;
  }

  &--dragging,
  &:hover {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 400"><path d="M48 152c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM0 48c0 26.5 21.5 48 48 48s48-21.5 48-48S74.5 0 48 0 0 21.5 0 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" fill="%23ffffff"/></svg>');
    &.question-page__gutter--vertical {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 96"><path transform="rotate(90 200,200)" d="M48 152c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM0 48c0 26.5 21.5 48 48 48s48-21.5 48-48S74.5 0 48 0 0 21.5 0 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" fill="%23ffffff"/></svg>');

    }
  }

  &--touch {
    background-size: 8px auto;
  }
}

// When splitscreen is open, reduce padding etc:
.question-page--splitscreen-open {
  .question-title__label { display: none; }

  @include until($widescreen) {
    .question-page__index { display: none; }
    .question__navigation-dropdown { display: block; }
  }
}
