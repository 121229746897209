.full-screen-background {
  z-index: -1;
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $tm-neutral-dark no-repeat top center;
  background-size: cover;
}

.full-screen-background--darker {
  &:after {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $image-overlay-dark;
  }
}
