/* Single Choice Image QTI type */

.choice-single-image {
    list-style: none;
    font-size: 0;
    margin: 1em 0 !important;
    li {
        display: inline-block;
        font-size: 1rem;
        list-style: none !important;
        margin: 0 !important;
        padding: 0 20px 20px 0 !important;
        vertical-align: top;
    }
    .col-2 {
        width: 50%;
    }
    .col-3 {
        width: 33.33%;
    }
    label {
        cursor: pointer;
        display: inline-block;
        position: relative;
    }
    input {
        position: absolute;
        left: -9999em;
    }
    .exercise-input-marker {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 150ms ease-in-out 0s;
        &:before {
            background-color: $anthracite;
            color: #fff;
            content: $icon-checkmark;
            font-family: $icon;
            font-size: 1.5em;
            height: 51px; // 50 + 1px, to make the visible marker 50px high
            line-height: 54px;
            position: absolute;
            top: 4px; // 5 - 1px, to overlap the border, necessary for retina displays
            left: 4px; // 5 - 1px, to overlap the border, necessary for retina displays
            text-align: center;
            width: 51px; // 50 + 1px, to make the visible marker 50px wide
        }
    }

  input:checked ~ .exercise-input-marker,
    .correct > .exercise-input-marker,
    .opinion > .exercise-input-marker,
    .incorrect > .exercise-input-marker,
    .missed > .exercise-input-marker {
        opacity: 1;
    }
    .correct > .exercise-input-marker:before,
    .opinion > .exercise-input-marker:before {
        background-color: $tm-good;
    }
    .incorrect > .exercise-input-marker:before {
        background-color: $exercise-incorrect-dark;
        content: $icon-cross;
    }
    .missed > .exercise-input-marker:before {
        background-color: $exercise-correct-light;
        color: $exercise-correct-dark;
        border-right: 1px dashed $tm-good;
        border-bottom: 1px dashed $tm-good;
    }
    img {
        border: 5px solid transparent;
        border-radius: 3px;
        cursor: pointer;
        display: block;
        height: auto;
        max-width: 100%;
        pointer-events: none;
        transition: border-color 150ms ease-in-out 0s;
        background-color: transparent !important;
    }
    img:hover {
        border-color: #ccc;
    }
    input[disabled] + img:hover,
    input[disabled] + figure > img:hover {
        border-color: transparent;
    }
    input:checked + img,
    input:checked + figure > img {
        border-color: $anthracite;
    }
    input[disabled] + img,
    input[disabled] + figure > img {
        border-color: transparent;
        cursor: default;
    }
    .correct > input[disabled] + img,
    .correct > input[disabled] + figure > img,
    .opinion > input[disabled] + img,
    .opinion > input[disabled] + figure > img {
        border-color: $tm-good;
    }
    .incorrect > input[disabled] + img,
    .incorrect > input[disabled] + figure > img {
        border-color: $exercise-incorrect-dark;
    }
    .missed > input[disabled] + img,
    .missed > input[disabled] + figure > img {
        border-color: $tm-good;
        border-style: dotted;
    }
}
