.application-sidebar {
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 210px;
  right: 0;
  z-index: 40;

  .button-sidebar {
    background-color: $tm-sidebar-background;
    border-radius: 5px 0 0 5px;
    color: $tm-sidebar-text-color;
    font-size: 0.75rem;
    text-transform: uppercase;
    transition: all 100ms ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 65px;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon-tm-bookmark {
      display: block;
      font-size: 2rem;
      padding-top: 6px;
      line-height: 24px;
    }

    &:hover,
    &:active,
    &.active {
      background-color: $tm-sidebar-active-background;
      color: $tm-sidebar-active-text-color;
      outline: 0;
    }
  }
}
