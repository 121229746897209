$_bar-height: 6em;
$_bar-width: 4px;
$_container-max-width-desktop: 6.8em;
$_container-max-width-widescreen: 9em;

.reading-progress-indicator {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);

  color: $body-color;
  width: $_bar-width;
  max-width: $_bar-width;

  /* Small fix for offscreen indicator in first 30px width in tablet mode */
  @media screen and (min-width: $tablet) and (max-width: 800px) {
    left: 14px;
  }

  @include desktop() {
    margin-left: 0;
    width: $_container-max-width-desktop;
    max-width: $_container-max-width-desktop;
  }

  @include widescreen() {
    margin-left: 0;
    width: $_container-max-width-widescreen;
    max-width: $_container-max-width-widescreen;
  }

  &.is-fixed {
    z-index: 1;
    position: fixed;
    transform: translateX(0);
    top: $navbar-height;
    padding-top: baseline(1);
    padding-bottom: baseline(1);
    left: baseline(0.75);

    margin-top: 0;
    transition: margin-top .25s linear;

    @include until($tablet) {
      margin-left: divide($_bar-width, 2);
    }
    @include desktop() {
      left: calc(50% - ((#{$tablet} - (#{$container-padding} + #{$container-padding})) / 2) - #{$_container-max-width-desktop} - #{$container-padding});
    }
    @include widescreen() {
      left: calc(50% - ((#{$tablet} - (#{$container-padding} + #{$container-padding})) / 2) - #{$_container-max-width-widescreen} - #{$container-padding});
    }
  }

  li {
    position: relative;
    margin-bottom: baseline(0.5);

    font-family: $family-sans-serif;
    font-weight: $weight-semibold;
    font-size: 0.75rem;

    &:last-child { margin-bottom: 0; }

    &.is-read {
      .rpi-progress-bar { background-color: $tm-good-dark; }
      .rpi-progress-meter { display: none; }
    }
  }

  .rpi-section-link {
    position: relative;
    display: block;
    color: currentColor;
    line-height: baseline(0.75);

    height: 100%;
    min-height: 4em;

    @include tablet() {
      min-height: $_bar-height;
    }
  }

  .rpi-section-label {
    display: none;
    margin-left: baseline(0.5);

    @include desktop() {
      display: inline-block;
    }
  }

  .rpi-progress-bar {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    vertical-align: top;
    width: $_bar-width;

    height: 100%;
    min-height: 4em;
    @include tablet() {
      min-height: $_bar-height;
    }

    background-color: $tm-neutral;
    margin-right: baseline(0.25);
    border-radius: 0;
    overflow: hidden;
  }

  .rpi-progress-meter {
    display: block;
    width: 100%;
    height: 0;
    background-color: $tm-neutral-dark;
    transition: height .25s linear;
  }
}
