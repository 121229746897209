.paragraph-goals {
  @include container-fluid();
  min-height: baseline(9);
  padding-top: 2rem;
  padding-bottom: baseline(1.5);

  @include tablet() {
    padding-left: 8.125rem;
    padding-right: 8.125rem;
    padding-bottom: baseline(2);
  }

  color: $white;
  // TODO check if SVG fallback not needed anymore:
  //background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLW9wYWNpdHk9Ii41Ii8+PHN0b3Agb2Zmc2V0PSIzNSUiIHN0b3Atb3BhY2l0eT0iLjM1Ii8+PHN0b3Agb2Zmc2V0PSI3NSUiIHN0b3Atb3BhY2l0eT0iLjEzIi8+PHN0b3Agb2Zmc2V0PSI4MyUiIHN0b3Atb3BhY2l0eT0iLjA4Ii8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLW9wYWNpdHk9IjAiLz48L2xpbmVhckdyYWRpZW50PjxwYXRoIGZpbGw9InVybCgjYSkiIGQ9Ik0wIDBoMXYxSDB6Ii8+PC9zdmc+);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .35) 35%, rgba(0, 0, 0, .13) 75%, rgba(0, 0, 0, .08) 83%, rgba(0, 0,0,0) 100%);

  .paragraph-goals__body {
    margin-top: 1.5rem;
    @include tablet() {
      margin-top: 2.25rem;
      margin-left: 4rem;
    }
  }

  .paragraph-goals__title {
    font-family: $pacifico;
    font-weight: $weight-normal;
    font-style: normal;
    line-height: 1.2;
    padding-bottom: 0;

    text-shadow: 2px 2px 1px rgba(0,0,0,0.6);
    transform: rotate(-1.5deg);

    font-size: 2.25rem;
    @include tablet() {
      font-size: 3rem;
    }
  }

  p {
    text-shadow: $text-shadow-light;
    margin-bottom: 1rem;
  }

  ul,
  ul.list {
    margin: 0 !important;
    p:last-child { margin: 0; }

    @each $name, $type in $list-item-styles {
      &.#{$name} > li { list-style: none !important; }
    }

    & > *, & > li {
      list-style: none !important;
      margin-left: 0 !important;
      padding-left: 1.3em !important;
      position: relative;

      text-shadow: $text-shadow-light;

      &:before {
        display: block;
        position: absolute;
        top: 0.5em;
        left: 0;

        font-size: 0.675rem;
        margin-right: baseline(2);

        font-family: $pika;
        content: $pika-play;
        color: $white;
      }
    }
  }
}
