.term-definition-list {
  margin: 0 -0.5rem;
  padding: 0.5rem;

  transition: background-color 100ms;
  &:hover { background-color: $tm-neutral-light; }

  &__term { font-weight: bold; }

  @include tablet() {
    display: flex;
    align-items: baseline;

    &__term {
      display: block;
      min-width: 300px;
      max-width: 300px;
      width: 300px;
    }
  }
}

