.labeled-title {
  display: flex;
  align-items: flex-start;
  line-height: 1.1em;
  font-family: $family-serif;
  font-weight: $weight-normal;
  color: $body-color;
  text-rendering: optimizeLegibility;
  @include empty-placeholder();
  a & {
    &:hover {
      color: $link-color;
    }
  }
  .content-card__header--image & {
    color: $white;
  }
}

.labeled-title__text {
  word-break: normal;
  &--boxed {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.max-lines-one {
  -webkit-line-clamp: 1;
}
.max-lines-two {
  -webkit-line-clamp: 2;
}

.labeled-title__divider {
  margin: 0 0.75rem;
}

.labeled-title__label {
  font-size: .675em;
  font-family: $family-sans-serif;
  font-weight: $weight-bold !important;
  color: $white !important;
  text-transform: uppercase;
  border-radius: 2px;
  line-height: 1em;
  background-color: $tm-neutral-black;
  text-align: center;
  white-space: nowrap;
  padding: .2em .75em;
  margin-right: .5em;
  margin-left: .5em;
  @include empty-placeholder();
}

.labeled-title__text--sans-serif { font-family: $family-sans-serif; }
.labeled-title__text--bold { font-weight: $weight-bold; }

.labeled-title--small {
  .labeled-title__text {
    margin-top: 0.175em;
    font-family: $family-sans-serif;
  }
  .labeled-title__label {
    font-size: 1em;
  }
}

.labeled-title--with-button {
  .labeled-title__text { align-self: center; }
  .labeled-title__button-container {
    flex-shrink: 0;
    align-self: center;
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.labeled-title--hoverable {
  .labeled-title__text-content { border-bottom: 1px dashed currentColor; }

  &:hover {
    .labeled-title__text-content { color: $link-color; }
  }
}
