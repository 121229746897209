.choice-single-text,
.choice-multiple {
  li {
    label {
      p:first-of-type {
        margin-top: 0 !important;
      }
      ul, ol {
        margin: 0 !important;
        li, li + li {
          margin-top: 0em !important;
        }
      }
      ul.bullet {
        li, li + li {
          list-style: disc !important;
          margin-left: 30px !important;
          padding-left: 10px !important;
        }
      }
      ol {
        li, li + li {
          list-style: decimal !important;
          margin-left: 30px !important;
          padding-left: 10px !important;
        }
      }
    }
  }
}
