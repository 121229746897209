.simplified-paragraph-block {
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-items: center;
  @include tablet() {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.simplified-paragraph-block__body {
  font-size: 1.375rem;
  font-family: $family-serif;
}

.simplified-paragraph-block__body,
.simplified-paragraph-block__actions {
  padding: 1rem;
}

.simplified-paragraph-block__actions {
  background: $tm-background-blue;
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet() { width: auto; }
}

.simplified-paragraph-block--completed {
  background-color: $tm-good-light;

  .simplified-paragraph-block__actions { background-color: $tm-good-light; }

  .simplified-chapter-block__completed-label {
    margin: 0;
    @include tablet() { margin-right: 1.5rem; }
  }
}
