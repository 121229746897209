// 'fade' transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease-in-out;
}
.slide-fade-leave-active {
  transition: all .3s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

/* Swipe two components left and right (one into view, other out of view) (START) */
.switch-enter-active { animation: swipe-enter-right .5s; }
.switch-leave-active { animation: swipe-leave-left .5s; }

.reverse-switch-enter-active { animation: swipe-enter-left .5s; }
.reverse-switch-leave-active { animation: swipe-leave-right .5s; }
/* Swipe (END) */

.scale-fade-enter-active,
.scale-fade-leave-active {
  animation: fade-in .3s;

  .v2-modal-dialog {
    animation: scale-in .35s;
  }
}

.scale-fade-enter,
.scale-fade-leave-to {
  animation: fade-in .3s reverse;

  .v2-modal-dialog {
    animation: scale-in .35s reverse;
  }
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* LIST ENTER (FADE IN/OUT RIGHT)*/
.list-enter-active:not(.animation-disabled) {
  animation: add-item .5s;
}

.list-leave-active:not(.animation-disabled) {
  position: absolute !important;
  animation: add-item .5s reverse;
}

.list-move:not(.animation-disabled) {
  transition: transform .5s;
}

@keyframes add-item {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(.9)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}

/* Swipe animations (used at ~:23-:29) */
@keyframes swipe-enter-right {
  0% { transform: translateX(0) }
  100% { transform: translateX(-100%) }
}

@keyframes swipe-leave-left {
  0% { transform: translateX(0) }
  100% { transform: translateX(-100%) }
}

@keyframes swipe-enter-left {
  0% { transform: translateX(-200%) }
  100% { transform: translateX(-100%) }
}

@keyframes swipe-leave-right {
  0% { transform: translateX(0) }
  100% { transform: translateX(100%) }
}
