.interaction-container-single-choice-matrix,
.interaction-container-multiple-choice-matrix {
  table {
    margin: 1em 0;
    th, td {
      padding-right: 1.5em;
      padding-bottom: 0.7em;
      vertical-align: middle;
      &:last-child {
        padding-right: 0;
      }
      text-align: center;
      &.value {
        min-width: 24px;
      }
    }
    th {
      font-weight: normal;
    }
    tr.statement > th,
    tr.multiple-choice-matrix-item > th {
      text-align: left;
      &.choice-matrix-row-marker {
        max-width: 1rem;
        padding-right: 0.5rem;
      }
    }
  }

  label.correct,
  label.opinion,
  label.incorrect,
  label.missed {
    position: relative;
    input {
      display: none;
    }

    &:before {
      content: "";
      font-family: $icon;
      color: #fff;
      display: inline-block;
      width: 24px;
      line-height: 28px;
      height: 25px;
      vertical-align: middle;
      text-align: center;
    }

    &.correct:before,
    &.opinion:before {
      color: $white;
      background-color: $exercise-correct-medium;
      content: $icon-checkmark;
    }

    &.predefined:before {
      content: $icon-star;
    }

    &.incorrect:before {
      content: $icon-cross;
      background-color: $exercise-incorrect-dark;
    }

    &.missed:before {
      content: $icon-checkmark;
      background-color: $exercise-correct-light;
      border: 1px dashed $exercise-correct-dark;
      color: $exercise-correct-dark;
    }
  }
}
