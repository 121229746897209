.question-label {
  @include empty-placeholder();
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0.785em 0.6em;
  min-width: 3em;
  text-align: center;
  font-family: $family-serif;
  font-weight: $weight-bold;
  color: $tm-neutral-extraheavy;
  background-color: $white;
  border-radius: 99999em;

  &--type-selfscore-on-scale,
  &--type-selfscore-rubric { @include icon-before($icon: $pika-star, $margin-right: 0.5em); }
  &--type-selfscore-complete,
  &--type-opinion { @include icon-before($icon: $pika-check, $margin-right: 0.5em); }
  &--type-self-test { @include icon-before($icon: $pika-flag, $margin-right: 0.5em); }
  &--type-overview { @include icon-before($icon: "\e138", $margin-right: 0.5em); }
  &--white { background-color: $white !important; }

  &--type-average {
    font-family: $family-sans-serif;
    background-color: unset;
    color: $base-text-color;
  }

  &--type-overview,
  &--type-selfscore-on-scale,
  &--type-selfscore-rubric,
  &--type-selfscore-complete,
  &--type-self-test,
  &--type-average {
    &:before {
      vertical-align: baseline;
    }
  }

  @each $category, $colors in $score-categories {
    &--score-#{$category} {
      color: map-get($colors, color);
      background-color: map-get($colors, background-color);
    }
  }

  &--small {
    font-size: 0.675rem;
    padding-bottom: 0.65em;
    &:before {
      margin-top: -0.25em;
    }
  }

  &--progress {
    padding: 0.4rem 0.5rem;
  }
}

a.question-label {
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
    &:before { transform: scale(1.05); }

    color: $tm-neutral-extrablack;
    background-color: $tm-empty-dark;
  }

  @each $category, $colors in $score-categories {
    &--score-#{$category}:hover {
      color: map-get($colors, color) !important;
      background-color: map-get($colors, background-color-hover) !important;
    }
  }
}
