.select-stream {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    padding: 2rem 0 1rem 0;
    border-bottom: 1px solid $tm-neutral-light;
  }

  &__caption {
    padding: 1rem 0;
  }
  &__caption-text {
    font-size: 0.9rem;
    font-weight: normal;
  }
  &__content {
    padding: 1rem 0 5rem 0;
    flex-grow: 1;
    background-color: $tm-neutral-light;
  }
  &__content-title {
    padding: 0 0 1rem 0;
  }
}

.stream,
.stream-cluster {
  display: flex;
  position: relative;
  background-color: $white;
  flex-direction: column;
  padding: 0.25rem 2rem;
  border-top: 1px solid $tm-neutral-light;

  @include tablet() {
    flex-direction: row;
  }

  &:hover {
    background-color: $tm-neutral-pale;
  }

  &__name, &__cluster-name, &__education-years, &__links {
    font-family: $family-serif;
    color: $body-color;
    font-weight: $weight-normal;
    margin: 0;
  }

  &__icon {
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__cluster-name {
    font-weight: $weight-bold;
    margin-top: 0.2rem;
    .is-collapsed & {
      font-weight: $weight-normal;
    }
  }

  &__name {
    @include tablet() {
      border-left: none;
      flex: 1;
    }
  }
  &__links {
    @include tablet() {
      flex: 0 0 20%;
    }
  }
  &__education-years {
    @include tablet() {
      flex: 0 0 20%;
    }
  }

  &--in-cluster {
    border-top: 0 none;
    padding: 0.5rem 2rem;
  }

  &__name--in-cluster {
    font-size: 0.9rem;
  }
}

.stream-cluster-streams {
  background-color: $white;
  border-top: 1px solid $tm-neutral-light;
  .is-collapsed & {
    display: none;
  }
}

.stream-cluster {
  padding: 0.25rem 0.75rem;
  position: relative;
  background-color: $white;
  margin-bottom: 0;
  &:hover {
    background-color: $white;
  }
  &:after {
    border: 2px solid $body-color;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
  }

  .is-collapsed & {
    &:after {
      top: calc(50% + 3px);
      transform: rotate(-135deg);
    }
  }
}
