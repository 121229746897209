.notifications-icon {
  .icon-bell {
    margin-top: 0.2rem;
  }
  @include mobile() {
    display: none;
  }
}

.notifications-icon__new-notifications-marker {
  @include empty-placeholder();
  display: block;
  line-height: 0;
  background-color: $tm-red;
  border-radius: 99999em;
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  top: 14px;
  right: 6px;
  @include desktop() {
    right: 11px;
  }
}

.notifications-preview {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  width: 15rem;
  background-color: $white;
  border: 1px solid $tm-neutral-light;
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  @include mobile() {
    display: none;
  }
  &__notifications {
    overflow-y: auto;
  }
}

.notifications__title,
.notifications__notification,
.notifications__show-all,
.notifications__read-all,
.notifications__empty,
.notifications__footer {
  display: flex;
  width: 100%;
  color: $tm-neutral-black;
  padding: 0.5rem 1rem;
  border-top: 1px solid $tm-neutral-light;
  font-family: $family-sans-serif;
  font-size: 0.8125rem;
  &:first-child {
    border-top: 0 none;
  }
}
.notifications__notification {
  border-top: 1px solid $tm-neutral-light;
}

.notifications__observer {
  display: block;
  width: 100%;
  height: 1px;
}
.notifications__footer {
  position: relative;
  padding: 0;
  min-height: 1rem;
  border-bottom: 0 none;
}

.notifications__show-all,
.notifications__read-all {
  text-align: center;
  font-weight: $weight-bold;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  border-top: 1px solid $tm-neutral-light;
  border-bottom: 0 none;
}

.notifications__read-all {
  padding: 0.75rem 1rem;
  .icon-check {
    margin-right: 0.25rem;
  }
}

.notifications__empty {
  text-align: center;
  padding: 0.25rem 1rem;
}

.notifications__title {
  border-bottom: 1px solid $tm-neutral-light;
  font-family: $family-serif;
  font-weight: $weight-bold;
  font-size: 1rem;
  padding: 0.75rem 1rem 0.5rem 1rem;
}

.notifications__notification-title,
.notifications__notification-message,
.notifications__notification-time
{
  display: block;
  font-weight: $weight-normal;
  width: 100%;
}
.notifications__notification-message-method {
  font-weight: $weight-bold;
}

.notifications__notification-time {
  margin-top: 0.25rem;
  font-size: 0.7rem;
  color: $tm-neutral-heavy;
}

.modal--notifications {
  .notifications {
    overflow-y: auto;
  }
}

.notifications__link {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  color: $tm-neutral-black;
}
.notifications__delete,
.notifications__seen {
  display: flex;
  margin: 0;
  flex-shrink: 0;
  flex-grow: 0;
  padding-left: 1rem;
  text-align: right;
  vertical-align: middle;
  .icon-trash,
  .icon-check {
    align-self: center;
    color: $tm-neutral-extraheavy;
    &:hover {
      color: $tm-neutral-heavy;
    }
    &--read {
      color: $tm-good-heavy;
      pointer-events: none;
      &:hover {
        color: $tm-good-heavy;
      }
    }
  }
}
