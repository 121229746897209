$modal-content-spacing-mobile: 40px !default;
$modal-content-spacing-tablet: 40px !default;
$modal-content-width: 640px !default;
$modal-content-margin-mobile: 20px !default;
$modal-z: 10004 !default;

.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: $modal-z;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  &.is-active,
  &--active {
    display: flex;
  }
}
.modal--fullscreen {
  z-index: 99;

  .modal__background {
    background-color: $white;
  }
}

.modal-background,
.modal__background {
  background-color: $image-overlay-dark;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.modal-content,
.modal__content {
  margin: 0 $modal-content-margin-mobile;
  overflow: auto;
  position: relative;
  width: 100%;

  @include tablet() {
    margin: 0 auto;
    width: $modal-content-width;
  }
}
.modal__content {
  background: $white;

  &--loading {
    @include loading-bar($height: 5px, $color: $primary);
  }
}

.modal--notifications {
  -webkit-overflow-scrolling: touch; // add smooth scrolling to content-container for iPad/Mobile Safari (iOS 12)
  overflow: auto;
  z-index: 10004;

  .modal__content {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 90vw;
    max-height: 60%;
    position: relative;
    overflow: hidden;
  }
}

.confirm-modal {
  &__content {
    padding: baseline(1.5);
    background: $white;
  }

  &__primary-action {
    margin-right: auto;
  }
  &__secondary-action {
    font-family: $family-serif;
    font-size: baseline(0.75);
  }
}

.modal--content {
  .modal__content {
    display: flex;

    @include tablet() {
      width: 900px;
    }
  }
  .modal__inner-content {
    flex: 1;
    overflow: auto;
    padding: baseline(1.5) baseline(2.5);
  }
}

.assignment-modal {
  z-index: 10003;

  &__content {
    height: calc(100vh - 40px);
    width: calc(100vw - 40px);
    max-width: 1200px;

    background: $white;

    display: flex;
    flex-direction: column;
  }

  &__body-container {
    flex: 1;
    overflow: hidden;
  }
  &__body {
    height: 100%;
    overflow: auto;
    position: relative;

    &--loading {
      @include loading-bar($height: 5px, $color: $primary);
    }
  }

  &__body-content {
    height: 100%;
  }
}

.modal-content {
  position: relative;
}

.modal--loading .modal-content {
  position: relative;
  @include loading-bar($height: 5px, $color: $primary);
}

.modal-content__primary { background: $white; }
.modal-content__actions { background-color: $tm-background-blue; }
