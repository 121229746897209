/**
 * Carousel items
 */
$tile-background-color: $white;
$tile-color: $white;
$tile-background-color-started: lighten($tm-blue, 10);
$tile-color-started: $white;
$tile-background-color-completed: $tm-good-light;
$tile-color-completed: $body-color;

.carousel--lesson-navigation {
	cursor: grab;

	@media screen and (min-width: 961px) {
		margin-top: 15vh;
	}

	@media screen and (max-width: 960px) {
		margin-top: 8vh;
	}

	@media screen and (max-width: 600px) {
		margin-top: 0;
		margin-bottom: 5vh;
	}
}

.carousel--lesson-navigation .carousel__slides {
	margin: 0;
	padding: 2rem 0;
}

.carousel--lesson-navigation .carousel__slide {
	position: relative;
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin: 0 !important;
  padding: .25rem 0;
}

.carousel--lesson-navigation .carousel-slide__inner {
  width: 9.5rem;
  height: 9.5rem;
  max-width: 100%;
  font-size: 1rem;

  @include mobile() {
    font-size: 0.6rem;
	}

  .share-holder .share-button .icon-share,
  .carousel-slide__content {
    &, &:hover {
      color: $tm-blue;
    }
  }
  .share-holder .share-button .icon-share:hover {
    color: lighten($tm-blue, 10%);
  }

  &--started .share-holder .share-button .icon-share,
	&--started .carousel-slide__content {
    &, &:hover {
      background: $tile-background-color-started;
      color: $tile-color-started;
    }
  }
  &--started .share-holder .share-button .icon-share:hover {
    color: darken($tile-color-started, 5%);
  }

  &--completed .share-holder .share-button .icon-share,
	&--completed .carousel-slide__content {
    &, &:hover {
      background: $tile-background-color-completed;
      color: $tile-color-completed;
    }
	}
  &--completed .share-holder .share-button .icon-share:hover {
    color: lighten($tile-color-completed, 10%);
  }
}

.share-lesson--simplified {
  transition: all .35s ease-in-out;
  left: 100%;
  top: 0%;
  .share-button__icon {
    position: absolute;
    top: .7rem;
    right: .7rem;
  }
}

.carousel-slide__inner {
	color: $tm-blue;
	display: flex;
	font-family: $family-serif;
	font-weight: bold;
	line-height: 1;
	transition: padding .3s ease-in-out;
	width: 18.5%;
  position: relative;

	@include tablet() {
		padding: .35rem;
    .carousel-slide__content {
      transform: scale(1);
      transition: transform .35s ease-in-out;
    }
    &:hover .carousel-slide__content {
      transform: scale(1.1);
    }
    &:hover .share-lesson--simplified {
      left: 105%;
      top: -5%;
    }
	}

	&::before {
		content: '';
		display: flex;
		padding-top: 90%;

		@media screen and (min-width: 601px) {
			padding-top: 100%;
		}
	}
}

.carousel--lesson-navigation .carousel-slide__content {
	align-items: center;
	background: $white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 100%;
}

.carousel--lesson-navigation .carousel-slide__lesson-label,
.carousel--lesson-navigation .carousel-slide__lesson-number {
	font-weight: bold;
}

.carousel--lesson-navigation .carousel-slide__lesson-label {
	font-size: 2em;
}

.carousel--lesson-navigation .carousel-slide__lesson-number {
	font-size: 3em;
}
