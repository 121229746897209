$spacer: 0.5rem;
$spacers: (
    0: 0,
    half: ($spacer * 0.5),
    1: ($spacer),
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5),
    6: ($spacer * 6),
    7: ($spacer * 7),
    8: ($spacer * 8),
    9: ($spacer * 9),
    10: ($spacer * 10)
);

@mixin spacers($infix) {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {

      .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      .#{$abbrev}t#{$infix}-#{$size},
      .#{$abbrev}y#{$infix}-#{$size} {
        #{$prop}-top: $length !important;
      }
      .#{$abbrev}r#{$infix}-#{$size},
      .#{$abbrev}x#{$infix}-#{$size} {
        #{$prop}-right: $length !important;
      }
      .#{$abbrev}b#{$infix}-#{$size},
      .#{$abbrev}y#{$infix}-#{$size} {
        #{$prop}-bottom: $length !important;
      }
      .#{$abbrev}l#{$infix}-#{$size},
      .#{$abbrev}x#{$infix}-#{$size} {
        #{$prop}-left: $length !important;
      }

      .-#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      .-#{$abbrev}t#{$infix}-#{$size},
      .-#{$abbrev}y#{$infix}-#{$size} {
        #{$prop}-top: -$length !important;
      }
      .-#{$abbrev}r#{$infix}-#{$size},
      .-#{$abbrev}x#{$infix}-#{$size} {
        #{$prop}-right: -$length !important;
      }
      .-#{$abbrev}b#{$infix}-#{$size},
      .-#{$abbrev}y#{$infix}-#{$size} {
        #{$prop}-bottom: -$length !important;
      }
      .-#{$abbrev}l#{$infix}-#{$size},
      .-#{$abbrev}x#{$infix}-#{$size} {
        #{$prop}-left: -$length !important;
      }
    }
  }

  // Some special margin utils
  .m#{$infix}-auto { margin: auto !important; }
  .mt#{$infix}-auto,
  .my#{$infix}-auto {
    margin-top: auto !important;
  }
  .mr#{$infix}-auto,
  .mx#{$infix}-auto {
    margin-right: auto !important;
  }
  .mb#{$infix}-auto,
  .my#{$infix}-auto {
    margin-bottom: auto !important;
  }
  .ml#{$infix}-auto,
  .mx#{$infix}-auto {
    margin-left: auto !important;
  }
}


@include spacers('');

@include tablet() {
  @include spacers('-tablet');
}
@include desktop() {
  @include spacers('-desktop');
}

.last-child-mb-0 {
  @include last-child-no-bottom-margin();
}
