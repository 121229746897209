.resource-title {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid $tm-neutral-light;
  padding-bottom: 1rem;
  font-family: $family-serif;

  &__number {
    font-weight: $weight-bold;
    font-size: 2em;
    margin-top: -0.033em;
    margin-right: 0.33em;
  }

  &__text {
    font-weight: $weight-normal;
    margin-top: 0.25em;
  }

  &--no-border {
    padding: 0;
    border-bottom: 0;
  }

  .question-page__theory-pane & {
    padding-right: 5rem;
  }
}

