ul.qti-order {
  display: flex;
  flex: 1;
  position: relative;

  li.sortable-placeholder {
    @include empty-placeholder();
    display: block;
    border-radius: 6px;
    background: $tm-yellow-light;
    border: 1px dashed $tm-yellow-dark;
  }

  li:not(.sortable-placeholder) {
    padding: 0;
    position: relative;
    display: flex;
    flex: 1;
    & > * {
      pointer-events: none;
    }
    &.ui-sortable-handle {
      cursor: move;
    }
    .sort__content {
      display: flex;
      flex: 1;
      align-items: center;
      position: relative;
      border: 1px solid $input-default-border-color;
      background: $white;
      &--hidden {
        visibility: hidden;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }

    &.ui-sortable-helper {
      .sort__content {
        border: 1px solid $input-default-border-color-hover;
        background-color: rgba($white, 0.9);
        box-shadow: -0.5em 1em 10px rgba(0,0,0,0.08);
        transform: translate(0) rotate(-2deg) scale(0.99);
        transform-origin: 0 20%;
        margin-left: .5%;
      }
      img {
        opacity: 0.7;
      }
    }

    &.image {
      figure.asset {
        margin: 0 auto;
      }
      .sort__content {
        padding: 0.6rem 1.2rem;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          max-height: 200px;
          width: auto;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }
    }
    &.opinion,
    &.incorrect,
    &.correct,
    &.predefined,
    &.missed {
      .sort__content:before {
        font-family: $icon;
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        line-height: 34px;
        text-align: center;
        vertical-align: middle;
        color: #FFF;
      }
    }
    &.incorrect .sort__content {
      border: 1px solid $exercise-incorrect-dark;
      &:before {
        content: $icon-cross;
        background: $exercise-incorrect-dark;
      }
    }
    &.correct .sort__content,
    &.opinion .sort__content {
      border: 1px solid $tm-good;
      &:before {
        content: $icon-checkmark;
        background: $tm-good;
      }
    }
    &.incorrect .sort__content--missed {
      background: $white;
      border: 1px dashed $exercise-correct-dark;
      color: $exercise-correct-dark;
      &:before {
        background: $exercise-correct-light;
        border-right: 0 none;
        color: $exercise-correct-dark;
        content: $icon-checkmark;
      }
    }
    &.predefined {
      .sort__content {
        border: 1px solid $tm-good;
        &:before {
          content: $icon-star;
          background: $tm-good;
        }
      }
    }
  }
}

ul.qti-order--vertical {
  flex-direction: column;
  li {
    .sort__content {
      padding: 1.5rem 40px;
      justify-content: flex-start;
      & + .sort__content {
        margin-left: 1rem;
      }
    }
    & + li {
      margin: baseline(0.5) 0 0 0;
    }
  }
}

ul.qti-order--horizontal {
  flex-direction: row;
  li {
    flex-direction: column;
    .sort__content {
      padding: 2rem 40px;
      justify-content: center;
      & + .sort__content {
        margin-top: 1rem;
      }
    }
    & + li {
      margin: 0 0 0 baseline(0.5);
    }
  }
}
