@function tint($color, $percent) {
  @return mix(white, $color, $percent);
}

@mixin theme-chapter-colours($colors) {
  @each $name, $color in $colors {
    .has-#{$name}-color { color: $color !important; }
    .has-#{$name}-bg { background-color: $color !important; }
    .has-#{$name}-border { border-color: $color !important; }

    .is-#{$name}-container {
      .caption__label { background-color: $color; }
      .rpi-progress-bar { background-color: #bae8fe; }
      .rpi-progress-meter { background-color: $color; }
    }

    // Geef embedded content een achtergrond tint welke correspondeert met de hoofdstukkleur
    .embedded-topic.is-#{$name}-container {
      background-color: tint($color, 94%);
      &:after { background: linear-gradient(rgba(tint($color, 94%), 0), rgba(tint($color, 94%), 1) 100%); }
      figcaption { background-color: transparent; }
    }

    .is-#{$name}-page {
      .modal-content .title-label { background-color: $color; }
    }
  }
}

@mixin theme-background($image: null, $color: '#ffffff') {
  @if ($image) {
    .full-screen-background { background-image: url($image); }
  } @else {
    .full-screen-background { background-color: $color; }
  }
}

@mixin theme-stream-colours($colors) {
  @each $name, $color in $colors {
    .has-#{$name}-color { color: $color !important; }
    .has-#{$name}-bg { background-color: $color !important; }
    .has-#{$name}-border { border-color: $color !important; }
  }
}

@mixin navbar-theme(
  $logo,
  $logo-size,
  $brand-divider-color,
  $primary-color,
  $primary-background-color,
  $navbar-primary-background-hover-color,
  $secondary-color,
  $secondary-background-color,
  $secondary-background-is-current-color,
  $item-color,
  $item-hover-color,
  $item-active-color,
  $item-active-text-color,
  $item-icon-color,
  $item-icon-hover-color,
  $item-background-hover-color,
  $item-divider-color
) {
  nav.navbar {
    background-color: $secondary-background-color;
    @include mobile() { background-color: $primary-background-color; }

    .notifications-preview__show-all {
      background-color: $secondary-background-color;
      color: $item-color;
      border-color: $secondary-background-color;
    }

    div.navbar-brand {
      .navbar-method {
        background-color: $primary-background-color;
        border-color: $brand-divider-color;
        background-image: $logo;
        background-size: $logo-size;
      }
      .navbar-stream-name {
        background-color: $primary-background-color !important;
        color: $primary-color !important;
        &:hover {
          background-color: $navbar-primary-background-hover-color !important;
        }
      }
      .navbar-item.has-dropdown:hover .navbar-link.navbar-stream-name,
      .navbar-item.has-dropdown.is-active .navbar-link.navbar-stream-name {
        background-color: $navbar-primary-background-hover-color !important;
      }
      .navbar-burger span { background-color: $primary-color; }

      .navbar-item--stream .navbar-item.is-current {
        background-color: darken(rgb(255,255,255), 5%);
      }
    }

    div.navbar-menu {
      .navbar-start,
      .navbar-end {
        .navbar-item,
        .navbar-link {
          background-color: $secondary-background-color;
          color: $item-color;
          &:after { border-color: $item-icon-color; }
          border-right-color: $item-divider-color !important;
          .icon { color: $item-icon-color; }

          @include tablet() {
            &.notifications-icon.is-active,
            &:hover {
              background-color: $item-background-hover-color;
              color: $item-hover-color;
            }
          }
        }

        // TODO figure out color for bottom border of active item
        .navbar-item:active,
        .navbar-item.is-active {
          &:after {
            background-color: $item-icon-color;
          }
        }

        .navbar-item.is-current {
          background-color: $item-active-color !important;
          color: $item-active-text-color !important;
          .icon {
            color: $item-active-text-color !important;
          }
          .navbar-link {
            background-color: $item-active-color !important;
            color: $item-active-text-color !important;
          }
        }

        .navbar-item {
          &.has-dropdown {
            &:hover,
            &.is-active {
              .navbar-link {
                background-color: $item-background-hover-color;
                color: $item-hover-color;
              }
              &:after {
                background-color: $item-icon-color;
              }
            }
          }
        }
      }
    }
  }
}

