.count-indicator {
  @include empty-placeholder();
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: $white;
  background-color: $tm-red;
  border-radius: 99999em;
  font-size: 0.6rem;
  width: 1rem;
  height: 1rem;
  font-weight: $weight-bold;
}

.count-indicator--for-notifications-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
