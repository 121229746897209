.drawing-interaction-container {
  display: flex;
  justify-content: center;
}

.drawing-interaction-board {
  position: relative;
  line-height: 0;
  cursor: crosshair;
  user-select: none;
  display: inline-block;
  &.readonly { cursor: default; }
  .drawing-interaction-image {
    width: auto;
    height: auto;
    max-width: 100%;
  }
  .svg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  svg#drawing {
    -ms-touch-action: none;
    touch-action: none;
  }
}

.drawing-interaction-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: $tm-background-blue;
  line-height: 1;
  position: relative;
  min-width: 700px;
  @media (max-width: 699px) {
    min-width: 100%;
    //min-width: unset;
    flex-direction: column;
  }
}

.drawing-interaction-tools {
  padding-left: 30px;
}

.drawing-interaction-buttons,
.drawing-interaction-colors,
.drawing-interaction-tools {
  display: flex;
  flex-direction: row;
  height: 70px;
  align-items: center;
}

.drawing-interaction-colors {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.drawing-interaction-color {
  @include circle(32px);
  display: inline-block;
  background-color: transparent;
  border: 8px solid currentColor;
  margin-right: 5px;
  &:last-child { margin-right: 0; }
  cursor: pointer;
  transition: transform 100ms ease-out;
  &.selected {
    transform: scale(1.1);
    background-color: currentColor;
  }
  &[data-opacity="0.1"] { opacity: 0.5; }
  &[data-opacity="0.2"] { opacity: 0.5; }
  &[data-opacity="0.3"] { opacity: 0.5; }
  &[data-opacity="0.4"] { opacity: 0.5; }
  &[data-opacity="0.5"] { opacity: 0.5; }
  &[data-opacity="0.6"] { opacity: 0.6; }
  &[data-opacity="0.7"] { opacity: 0.7; }
  &[data-opacity="0.8"] { opacity: 0.8; }
  &[data-opacity="0.9"] { opacity: 0.9; }
  &[data-opacity="1"] { opacity: 1; }
}

.drawing-interaction-marker {
  display: inline-block;
  cursor: pointer;
  height: 56px;
  background: $tm-neutral-dark;
  transform: rotate(30deg);
  margin-right: 15px;
  transition: all 100ms ease-out;
  border-radius: 2em;

  &.width-3 { width: 6px; }
  &.width-15 { width: 14px; }
  &.width-30 { width: 26px; }
  &.selected {
    transform: scale(1.05) rotate(30deg);
    background-color: $tm-neutral-black;
  }
}

.button-drawing-interaction {
  position: relative;
  float: left;
  margin-left: 0 !important;
  height: 70px;
  min-width: 70px;
  text-align: center;
  outline: none;
  text-transform: lowercase;
  border-radius: 0;
  font-family: $family-sans-serif;
  font-size: 16px;
  background-color: transparent;
  line-height: 1;
  font-weight: normal;

  &:disabled {
    background-color: transparent;
  }

  &:not([disabled]):not(.disabled) {
    &:active,
    &:hover {
      background-color: hsl(18, 100%, 49%);
      color: #fff;
    }
  }

  &.icon-before {
    padding: 0 10px;
    &:before {
      font-family: TMPika !important;
      padding: 0;
      font-size: 2em;
      left: auto;
      top: auto;
      margin: 0;
      text-align: center;
      width: 100%;
      position: relative;
      display: block;
    }
    &.icon-undo:before {
      content: "\21BA";
    }
    &.icon-trash:before {
      content: "\E0D0";
    }
  }

}
