$question-label-margin-bottom: 0.6rem;

.paragraph-item-container {
  width: 100%;
  padding-left: baseline(1.25);
  padding-right: baseline(1.25);
  @include tablet() { padding-right: baseline(0.25); }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.paragraph-item__questions { margin-bottom: -$question-label-margin-bottom; }

.paragraph-item {
  position: relative;

  align-self: flex-start;
  margin-bottom: baseline(1);
  width: 100%;

  @include tablet() {
    width: 15rem;
    margin-right: baseline(1);

    &--wide { width: 31.5rem; }

    &--full-width { width: 100%; }
  }

  background-color: $white;

  &__header,
  &__footer {
    display: block;
    padding: baseline(0.75);
  }

  &__header {
    padding-bottom: baseline(0.375);
    width: 100%;
    color: $base-text-color;
    cursor: auto;
    &[href] {
      cursor: pointer;
      &:hover {
        color: $base-text-color-hover;
      }
    }
  }

  &__footer { background-color: $tm-neutral-light; }

  &__title {
    display: flex;
    align-items: flex-start;
  }

  &__title-text {
    word-break: normal;
  }

  &__content {
    font-size: 0.875rem;
    margin-bottom: baseline(0.375);
    & > *:last-child { margin-bottom: 0; }
  }

  &--completed {
    .paragraph-item__title-label { color: $tm-good-dark; }
    .paragraph-item__footer { background-color: $tm-good-light; }
  }
}

.paragraph-item__title-label {
  margin-right: 0.375rem;
  font-size: 1.5rem;
  margin-top: 0.05rem;
}

.paragraph-item__question-container {
  display: inline-flex;
  flex-direction: column;

  margin: 0 0.25rem $question-label-margin-bottom 0;
}

.paragraph-item__question-score-label {
  width: 100%;
  margin-top: divide($question-label-margin-bottom, 2);
  padding: 0 0.5rem;
  text-align: center;
  background-color: $white;
  color: $tm-neutral-black;
  font-weight: bold;

  @include score-label();
}
