.question-page--simplified {
  position: relative;
  top: 0;

  @include desktop() {
    max-width: 61.25rem;
    margin: 0 auto;
  }

  .question-pages__panes {
    flex: 1;
  }

  .question-page__body-pane, .question-page__body-content {
    /**
    iOS bugfix, when using -webkit-overflow-scrolling: touch, fixed elements are not displayed correctly outside the rect of this div
    this will disable smooth scrolling in the question, but that is a tradeoff needed to support split screen
    a better solution would be to move the fixed element outside the .question-page__body-pane, but that is not possible,
    because the fixed element contains a button which needs to be in the form inside the .question-page__body-pane
     */
    -webkit-overflow-scrolling: auto;
  }

  &.question-page--splitscreen-open {
    max-width: unset;
  }

  .application-sidebar {
    top: 190px;
  }
  .button-sidebar {
    width: 85px;
    height: 85px;

    .svg-icon {
      height: 48px;
      margin-bottom: 0.25rem;
    }
  }
}


.question__controls--simplified {
  flex-direction: row;

  .is-breakpoint-mobile & {
    flex-direction: row;
  }
}

.question__secondary-container--sticky {
  background-color: $tm-background-blue;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 99;

  -webkit-transform: translateZ(0px);

  //-webkit-transform: translate3d(0,0,0);
}

.question__secondary-container-inner {
  .question__secondary-container--sticky & {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    @include desktop() {
      max-width: 61.25rem;
    }
  }
}

