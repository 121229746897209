.inline-choice__option {
  @include inline-option();
}

.inline-choice__option--correct {
  @include inline-option--correct();
}

.inline-choice__option--predefined {
  @include inline-option--predefined();
}

.inline-choice__option--incorrect {
  @include inline-option--incorrect();
}

.inline-choice__option--missed {
  @include inline-option--missed();
}
