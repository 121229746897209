.form-group { margin-bottom: $spacer; }

.form-group--horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-group__label {
  display: block;
}

.form-group__field {
  border: 1px solid $input-default-border-color;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  display: block;
  font-family: $family-sans-serif;
  width: 300px;

  .form-group--large & { font-size: 2rem; }
}

.form-group__field--short { width: 50px; }
.form-group__field--width-auto { width: auto; }

// hide number spinner in number fields, as it is ugly :)
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.form-group__field--textarea {
  width: 100%;
  height: 4rem;
  line-height: 1.5;
}
.form-group__field--readonly {
  background: $white;
  border: none;
  border-bottom: 1px solid $input-default-border-color;
  color: $tm-neutral-extraheavy;
  resize: none;
  &:focus,
  &:active {
    outline: none;
  }
}
.form-group__field--textarea-focus-grow {
  transition: all ease-in-out 150ms;
}
.form-group__field--textarea-focus-grow-maximized, .form-group__field--textarea-focus-grow:focus, .form-group__field--textarea-focus-grow.changed {
  height: 8.5rem;
}

.form-group__field--select {
  display: inline-block;
}
