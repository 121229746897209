.user-feedback {
  background-color: $user-feedback-background-color;
  color: $user-feedback-text-color;
  &--result,
  &--question {
    max-width: 37.5rem;
    margin-bottom: 1rem;
  }
  &--simplified {
    width: 100%;
  }
}

.user-feedback__icon {
  color: $user-feedback-icon-color;
  font-size: 1.5em;
}

.user-feedback__date {
  font-size: 0.875rem;
}
