.gap-match * {
  user-select: none;
}

.gap-match__option-area {
  align-items: center;
  background-color: $tm-neutral-light;
  display: flex;
  flex-flow: row wrap;
  min-height: 4rem;
  padding: 0.5rem;

  &--area-active, &--active {
    background-color: $hotspot-selected-color
  }

  &--vertical {
    flex-flow: column wrap;
  }

  .gap-match--associate & {
    border-radius: 9px;
    padding: 1rem;

    &--area-active, &--active {
      background-color: $tm-background-gray;
    }
  }
}

.draggable-option-container {
  display: inline-block;

  .gap-match__dropzone-area--vertical &,
  .gap-match__option-area--vertical & {
    display: block;
    width: 100%;
  }

  .gap-match--associate & {
    display: flex;
  }
}

.draggable-option {
  position: relative;
  background-color: $white;
  border: 1px solid $hotspot-selected-color;
  display: flex;
  align-items: stretch;
  white-space: nowrap;
  margin: 0.25rem;
  cursor: pointer;
  z-index: 10;
  line-height: 1.5;

  &--open {
    &:hover,
    &--dragging,
    &--selected {
      outline: 3px solid $hotspot-selected-color;
    }
  }

  &--dragging {
    font-size: 1em !important;
    white-space: nowrap;
    position: fixed;
    z-index: 1000;
  }

  &--selected { background-color: $hotspot-selected-color; }

  &--open {}
  &--predefined, &--correct, &--incorrect, &--missed {
    border: none;
    @include icon-before($icon: $pika-check, $color: $white);

    &:before {
      display: flex;
      align-items: center;
      padding: 0.125rem 0.35rem;
    }
  }
  &--correct, &--predefined {
    background: $exercise-correct-light;
    &:before {
      background-color: $exercise-correct-medium;
    }
  }

  &--predefined {
    &:before {
      content: $pika-star;
    }
  }

  &--incorrect {
    background: $exercise-incorrect-light;
    &:before {
      background-color: $exercise-incorrect-medium;
      content: $pika-delete;
    }
  }

  &--missed {
    background: $exercise-correct-very-light;
    border: 1px dashed $exercise-correct-dark;
    &:before {
      color: $exercise-correct-dark;
      border-right: 1px dashed $exercise-correct-dark;
      background-color: $exercise-correct-light;
    }
  }

  .gap-match__dropzone & {
    font-size: 0.825em;
    margin: 0;
  }

  .gap-match--associate & {
    white-space: normal;
    width: 100%;
  }
}
.draggable-option__content {
  &--text {
    padding: 0.125rem 0.5rem;
  }
  &--image {
    display: inline-flex;
    align-items: center;
  }

  @include truncate-text();
  .gap-match__dropzone & {
    max-width: 200px;
  }

  .draggable-option--predefined &,
  .draggable-option--correct &,
  .draggable-option--missed & {
    color: $exercise-correct-dark;

  }
  .draggable-option--incorrect & {
    color: $exercise-incorrect-dark;
  }

  .gap-match--associate & {
    white-space: normal;
  }
}

.gap-match__dropzone-area {
  position: relative;
}

.gap-match__dropzone-area--with-image {
  display: inline-block; // Same dimensions as child element (background, image, etc)
  line-height: 0;
  margin-bottom: 1rem;
  max-width: $interaction-image-max-width;
}

.gap-match__dropzone-area--vertical {
  width: 70%;

  .gap-match__dropzone-content {
    width: 57%;
  }
}

.gap-match__image {
  height: auto;
  width: auto;
  max-width: 100%;
  z-index: 1;
}

$_dropzone-size: 5rem;

.gap-match__dropzone {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  position: absolute;

  width: $_dropzone-size;
  height: $_dropzone-size;
  margin-left: -(divide($_dropzone-size, 2));
  margin-top: -(divide($_dropzone-size, 2));

  &:hover {
    z-index: 900;
  }

  &--clickable {
    cursor: pointer;
  }

  &--active::before {
    background-color: #F00 !important;
    box-shadow: 0 0 0 35px rgba(251, 182, 0, 0.8), 0 0 0 10px rgba(0, 0, 0, 0.3) !important;
  }

  &::before {
    background: $anthracite;
    border-radius: 100%;
    box-shadow: 0 0 0 20px rgba(251, 182, 0, 0.2), 0 0 0 10px rgba(0, 0, 0, 0.1);
    content: '';
    display: block;
    height: 9px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: box-shadow 100ms ease-in-out, background 100ms ease-in-out;
    width: 9px;
  }

  &:hover:empty {
    cursor: pointer;
  }

  &:hover:empty:before,
  &--area-active::before {
    box-shadow: 0px 0px 0 25px rgba(251, 182, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
  }
}

.gap-match__text-gap {
  display: inline-block;
  position: relative;

  border: 1px solid $input-default-border-color;
  cursor: pointer;
  line-height: 1.8;
  z-index: 1;

  &:before {
    content: $zero-width-space;
    display: inline-block;
    min-width: 5em;
  }

  .draggable-option {
    margin: 0;
  }

  &--has-option:not(&--dragging) {
    border: none;
    &:before {
      display: none;
    }
  }

  &--active {
    border-color: $interaction-active-color;
    outline: 3px solid $interaction-active-color;
  }
}

/** graphic associate */
.gap-match__dropzone-container--associate {
  .gap-match__dropzone-content {
    background-color: $tm-neutral-light;
    border-radius: 9px 0 0 9px;
  }
}

.gap-match__associate-gap {
  border-left: 2px solid $white;
  background: url('../images/placeholder-dropzone.png') center no-repeat $tm-neutral-pale;
  background-size: 3em;
  border-radius: 0 9px 9px 0;
  width: 43%;
  display: flex;
  padding: 1rem;

  &--has-option {
    background-image: none;
  }

  .draggable-option {
    margin: 0;
    flex: 1;
  }

  &--active {
    background-color: $tm-background-gray;
  }
}



// still used in applications/webapp/src/Edition/Content/Renderer/xslt/question/answer-gapmatch.xsl
// I cannot find an interaction with predefined gap matches though
.gapmatch-text-option--predefined {
  @include inline-option--predefined();
  line-height: 1.5;
}
