$offset-top: $navbar-height + $theory-tabs-height;
$container-with-index-height: calc(100vh - #{$offset-top});

$toc-width-closed: 48px;
$toc-width: 320px;
$toc-width-wide: 400px;

.container-with-index {
  position: relative;

  @include desktop() { max-width: $desktop; }
  @include widescreen() { max-width: $widescreen; }
  @include fullhd() { max-width: $fullhd; }

  &__index {
    position: fixed;
    z-index: 100;
    height: $container-with-index-height;
    overflow-y: auto;
    background-color: $tm-neutral-light;

    width: $toc-width-closed;
    @include tablet-landscape() { width: $toc-width; }
    @include fullhd() { width: $toc-width-wide; }
  }

  &__content {
    padding: 1.5rem;
    margin-left: $toc-width-closed;
    @include tablet-landscape() { margin-left: $toc-width; }
    @include fullhd() { margin-left: $toc-width-wide; }
  }
}

.container-with-index__section {
  position: relative;
  &--chapter { margin-bottom: 4rem; }
}

.is-toc-open {
  overflow: hidden;
  .container-with-index__index {
    width: 100%;
    top: $offset-top;
  }
}
