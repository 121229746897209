@mixin tablet-landscape() {
  @media screen and (min-width: $tablet-landscape) {
    @content
  }
}

@mixin container-fluid() {
  width: 100%;
  padding-left: baseline(1.25);
  padding-right: baseline(1.25);
}

@mixin truncate-text() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes is-loading {
  0% { left:0; width: 0; }
  50% { left:25%; width: 75%; }
  100% { left: 100%; width: 0; }
}

@mixin loading-bar($height: 3px, $color: $body-color) {
  &:before {
    content: "";
    width: 100%;
    height: $height;
    position: absolute;
    z-index: 10;
    top: 0;
    animation: is-loading 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    left: 0;
    background-color: $color;
  }
}

@keyframes is-loading-circle {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@mixin loading-circle($height: 3px, $color: $body-color) {
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    border: $height solid transparent;
    border-left-color: $color;
    transform: translateZ(0);
    animation: is-loading-circle 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@mixin responsive-square($width: 100%) {
  &:after {
    z-index: 1;
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }
}

@mixin empty-placeholder() {
  // a non breaking space, so empty elements will still display
  &:empty:before { content: "\00a0"; }
}

@mixin close-button($size, $thickness, $color, $color-hover: $color, $percentage: 100%) {
  display: inline-block;
  width: $size;
  height: $size;
  position: relative;

  &:before, &:after {
    content: '';
    display: block;
    width: $percentage;
    height: $thickness;
    background: $color;
    top: 50%;
    left: divide(100%-$percentage, 2);
    position: absolute;
  }

  &:before {
    transform: rotate(45deg);
    margin-top: divide(-$thickness, 2);
  }

  &:after {
    transform: rotate(-45deg);
    margin-top: divide(-$thickness, 2);
  }

  &:hover {
    cursor: pointer;
    &:before, &:after {
      background: $color-hover;
    }
  }
}

@mixin with-hoverable-icon() {
  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    display: inline-block;
    transition: transform 100ms;
  }
}

@mixin with-hoverable-icon-left() {
  @include with-hoverable-icon();

  &:hover > [class^="icon-"]:before,
  &:hover > [class*=" icon-"]:before,
  &:active > [class^="icon-"]:before,
  &:active > [class^=" icon-"]:before {
    transform: translateX(-0.075em);
  }
}
@mixin with-hoverable-icon-right() {
  @include with-hoverable-icon();

  &:hover > [class^="icon-"]:before,
  &:hover > [class*=" icon-"]:before,
  &:active > [class^="icon-"]:before,
  &:active > [class^=" icon-"]:before {
    transform: translateX(0.075em);
  }
}

@mixin force-size($width, $height) {
  width: $width;
  min-width: $width;
  max-width: $width;
  height: $height;
  min-height: $height;
  max-height: $height;
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin circle($size) {
  @include square($size);
  border-radius: 100%;
}

@mixin dropshadow() {
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .2))
}

@mixin error-marker() {
  display: inline-flex;
  align-items: center;
  color: $white;
  background-color: $tm-poor-heavy;
  padding: 0 0.1875rem;
  border-bottom: none;
  &:before {
    margin-right: 0.5rem;
    content: $pika-alert;
    font-family: $pika;
  }
}

@mixin icon-before($icon, $margin-right: 0, $color: currentColor, $size: 0.75em) {
  &:before {
    font-size: $size;
    font-family: $pika !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    vertical-align: middle;
    line-height: 0.5;

    content: $icon;
    color: $color;
    margin-right: $margin-right;
  }
  &:empty:before { content: $icon; }
}

@mixin last-child-no-bottom-margin() {
  & > *:last-child {
    margin-bottom: 0 !important;
  }
}


@mixin inline-option() {
  display: inline-block;
  padding: 0.125rem 0.5rem 0.125rem 2rem;
  margin-bottom: 0.2rem;
  position: relative;
  &:before {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1.5rem;
    font-family: $pika;
    font-size: 0.75rem;
    text-align: center;
  }
}

@mixin inline-option--correct() {
  @include inline-option();
  background: $exercise-correct-light;
  color: $exercise-correct-dark;
  &:before {
    background: $exercise-correct-medium;
    color: $white;
    content: $pika-check;
  }
}

@mixin inline-option--incorrect() {
  @include inline-option();
  background: $white;
  border-bottom: 1px solid $exercise-incorrect-light;
  color: $exercise-incorrect-dark;
  &:before {
    background: $exercise-incorrect-dark;
    border-bottom: 1px solid $exercise-incorrect-dark;
    color: $white;
    content: $pika-delete;
  }
}

@mixin inline-option--missed() {
  @include inline-option();
  background: $white;
  border: 1px dashed $exercise-correct-dark;
  color: $exercise-correct-dark;
  &:before {
    background: $exercise-correct-light;
    border-right: 1px dashed $exercise-correct-dark;
    color: $exercise-correct-dark;
    content: $pika-check;
  }
}

@mixin inline-option--predefined() {
  @include inline-option--correct();
  &:before {
    content: $pika-star;
  }
}
