// Pika icons
$pika-check: "\28";
$pika-star: "\e137";
$pika-pause: "\e0cb";
$pika-play: "\e0e2";
$pika-replay: "\e0fe";
$pika-play-video: "\e0e3";
$pika-alert: "\69";
$pika-lock: "\e090";
$pika-unlock: "\e16c";
$pika-zoom-in: "\e194";
$pika-flag: "\e046";
$pika-delete: "\e00c";
$pika-traffic: "\e106";

[class^="icon-"],
[class*=" icon-"] {
  vertical-align: middle;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:before {
    font-family: $pika !important;
    font-size: 1em;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-add-calendar:before {
  content: "\61";
}
.icon-add-cart:before {
  content: "\62";
}
.icon-add-file:before {
  content: "\63";
}
.icon-add-user:before {
  content: "\64";
}
.icon-address:before {
  content: "\65";
}
.icon-airplane:before {
  content: "\66";
}
.icon-airplay:before {
  content: "\67";
}
.icon-alarm-clock:before {
  content: "\68";
}
.icon-alert:before {
  content: $pika-alert;
}
.icon-anatomical-heart:before {
  content: "\6a";
}
.icon-apple:before {
  content: "\6b";
}
.icon-apron:before {
  content: "\6c";
}
.icon-arrival:before {
  content: "\6d";
}
.icon-ascend:before {
  content: "\6e";
}
.icon-atm:before {
  content: "\6f";
}
.icon-audio-book:before {
  content: "\70";
}
.icon-backspace:before {
  content: "\71";
}
.icon-badminton:before {
  content: "\72";
}
.icon-balloon:before {
  content: "\73";
}
.icon-ballpoint-pen:before {
  content: "\74";
}
.icon-ban:before {
  content: "\75";
}
.icon-bandage:before {
  content: "\76";
}
.icon-bank:before {
  content: "\77";
}
.icon-banknote:before {
  content: "\78";
}
.icon-bar-chart:before {
  content: "\79";
}
.icon-barbecue:before {
  content: "\7a";
}
.icon-barbecue-fire:before {
  content: "\41";
}
.icon-barbecue-utensils:before {
  content: "\42";
}
.icon-barcode-scanner:before {
  content: "\43";
}
.icon-barometer:before {
  content: "\44";
}
.icon-baseball-glove:before {
  content: "\45";
}
.icon-bathroom-scale:before {
  content: "\46";
}
.icon-bbq-apron:before {
  content: "\47";
}
.icon-beer:before {
  content: "\48";
}
.icon-bell:before {
  content: "\49";
}
.icon-bell-disabled:before {
  content: "\4a";
}
.icon-bike:before {
  content: "\4b";
}
.icon-binoculars:before {
  content: "\4c";
}
.icon-bird:before {
  content: "\4d";
}
.icon-book:before {
  content: "\4e";
}
.icon-bookmark:before {
  content: "\4f";
}
.icon-books:before {
  content: "\50";
}
.icon-bowling:before {
  content: "\51";
}
.icon-box:before {
  content: "\52";
}
.icon-boxing-glove:before {
  content: "\53";
}
.icon-briefcase:before {
  content: "\54";
}
.icon-broken-bone:before {
  content: "\55";
}
.icon-bullseye:before {
  content: "\56";
}
.icon-bus:before {
  content: "\57";
}
.icon-bus-2:before {
  content: "\58";
}
.icon-business-user:before {
  content: "\59";
}
.icon-calculator:before {
  content: "\5a";
}
.icon-calendar:before {
  content: "\30";
}
.icon-call-bell:before {
  content: "\31";
}
.icon-call-center:before {
  content: "\32";
}
.icon-camera:before {
  content: "\33";
}
.icon-camp-fire:before {
  content: "\34";
}
.icon-camper:before {
  content: "\35";
}
.icon-car:before {
  content: "\36";
}
.icon-cargoship:before {
  content: "\37";
}
.icon-carrot:before {
  content: "\38";
}
.icon-cash-register:before {
  content: "\39";
}
.icon-cell-phone:before {
  content: "\21";
}
.icon-cfl:before {
  content: "\22";
}
.icon-charcoal:before {
  content: "\23";
}
.icon-charge-mobile:before {
  content: "\24";
}
.icon-charging-battery:before {
  content: "\25";
}
.icon-charging-battery-2:before {
  content: "\26";
}
.icon-chat:before {
  content: "\27";
}
.icon-check:before {
  content: $pika-check;
}
.icon-check-calendar:before {
  content: "\29";
}
.icon-check-clipboard:before {
  content: "\2a";
}
.icon-chef:before {
  content: "\2b";
}
.icon-chess:before {
  content: "\2c";
}
.icon-clean-glass:before {
  content: "\2d";
}
.icon-cloche:before {
  content: "\2e";
}
.icon-clock:before {
  content: "\2f";
}
.icon-clockwise:before {
  content: "\3a";
}
.icon-cloud:before {
  content: "\3b";
}
.icon-cloud-download:before {
  content: "\3c";
}
.icon-cloud-folder:before {
  content: "\3d";
}
.icon-cloud-upload:before {
  content: "\3e";
}
.icon-cloudy:before {
  content: "\3f";
}
.icon-cocktail:before {
  content: "\40";
}
.icon-coffee:before {
  content: "\5b";
}
.icon-coffee-mug:before {
  content: "\5d";
}
.icon-coins:before {
  content: "\5e";
}
.icon-colander:before {
  content: "\5f";
}
.icon-columns:before {
  content: "\60";
}
.icon-compass:before {
  content: "\7b";
}
.icon-compass-navigate:before {
  content: "\7c";
}
.icon-compose:before {
  content: "\7d";
}
.icon-computer:before {
  content: "\7e";
}
.icon-computer-2:before {
  content: "\5c";
}
.icon-connection:before {
  content: "\e000";
}
.icon-containers:before {
  content: "\e001";
}
.icon-contract:before {
  content: "\e002";
}
.icon-corn:before {
  content: "\e003";
}
.icon-credit-card:before {
  content: "\e004";
}
.icon-credit-card-2:before {
  content: "\e005";
}
.icon-crop:before {
  content: "\e006";
}
.icon-crosshair:before {
  content: "\e007";
}
.icon-cruise-ship:before {
  content: "\e008";
}
.icon-cursor:before {
  content: "\e009";
}
.icon-dashboard:before {
  content: "\e00a";
}
.icon-database:before {
  content: "\e00b";
}
.icon-delete:before {
  content: $pika-delete;
}
.icon-delete-calendar:before {
  content: "\e00d";
}
.icon-delete-cart:before {
  content: "\e00e";
}
.icon-delete-file:before {
  content: "\e00f";
}
.icon-delete-user:before {
  content: "\e010";
}
.icon-delivery-truck:before {
  content: "\e011";
}
.icon-delivery-van:before {
  content: "\e012";
}
.icon-departure:before {
  content: "\e013";
}
.icon-descend:before {
  content: "\e014";
}
.icon-direct-left:before {
  content: "\e015";
}
.icon-direct-right:before {
  content: "\e016";
}
.icon-directions:before {
  content: "\e017";
}
.icon-disc:before {
  content: "\e018";
}
.icon-dna:before {
  content: "\e019";
}
.icon-do-not-disturb:before {
  content: "\e01a";
}
.icon-doc:before {
  content: "\e01b";
}
.icon-down:before {
  content: "\e01c";
}
.icon-down-left:before {
  content: "\e01d";
}
.icon-down-right:before {
  content: "\e01e";
}
.icon-download:before {
  content: "\e01f";
}
.icon-download-book:before {
  content: "\e020";
}
.icon-download-cart:before {
  content: "\e021";
}
.icon-download-file:before {
  content: "\e022";
}
.icon-download-folder:before {
  content: "\e023";
}
.icon-downwards-bar-chart:before {
  content: "\e024";
}
.icon-downwards-line-chart:before {
  content: "\e025";
}
.icon-drop:before {
  content: "\e026";
}
.icon-dumbbell:before {
  content: "\e027";
}
.icon-duster:before {
  content: "\e028";
}
.icon-e-book:before {
  content: "\e029";
}
.icon-e-reader:before {
  content: "\e02a";
}
.icon-egg-timer:before {
  content: "\e02b";
}
.icon-eight-ball:before {
  content: "\e02c";
}
.icon-eighth-note:before {
  content: "\e02d";
}
.icon-eject:before {
  content: "\e02e";
}
.icon-elevator:before {
  content: "\e02f";
}
.icon-email:before {
  content: "\e030";
}
.icon-empty-cart:before {
  content: "\e031";
}
.icon-eraser:before {
  content: "\e032";
}
.icon-evergreen:before {
  content: "\e033";
}
.icon-exe:before {
  content: "\e034";
}
.icon-exercise:before {
  content: "\e035";
}
.icon-exercise-bike:before {
  content: "\e036";
}
.icon-expand:before {
  content: "\e037";
}
.icon-export-file:before {
  content: "\e038";
}
.icon-eyedropper:before {
  content: "\e039";
}
.icon-eyelift:before {
  content: "\e03a";
}
.icon-facetime:before {
  content: "\e03b";
}
.icon-fan:before {
  content: "\e03c";
}
.icon-fast-forward:before {
  content: "\e03d";
}
.icon-fax:before {
  content: "\e03e";
}
.icon-female-user:before {
  content: "\e03f";
}
.icon-field-hockey:before {
  content: "\e040";
}
.icon-file:before {
  content: "\e041";
}
.icon-firewall:before {
  content: "\e042";
}
.icon-fish:before {
  content: "\e043";
}
.icon-fishes:before {
  content: "\e044";
}
.icon-five-die:before {
  content: "\e045";
}
.icon-flag:before {
  content: $pika-flag;
}
.icon-flame:before {
  content: "\e047";
}
.icon-flash:before {
  content: "\e048";
}
.icon-floorplan:before {
  content: "\e049";
}
.icon-fog:before {
  content: "\e04a";
}
.icon-folder:before {
  content: "\e04b";
}
.icon-font:before {
  content: "\e04c";
}
.icon-football:before {
  content: "\e04d";
}
.icon-fork:before {
  content: "\e04e";
}
.icon-forklift:before {
  content: "\e04f";
}
.icon-form:before {
  content: "\e050";
}
.icon-four-die:before {
  content: "\e051";
}
.icon-fragile:before {
  content: "\e052";
}
.icon-fragile-cargo:before {
  content: "\e053";
}
.icon-fuel:before {
  content: "\e054";
}
.icon-full-battery:before {
  content: "\e055";
}
.icon-garage:before {
  content: "\e056";
}
.icon-gift:before {
  content: "\e057";
}
.icon-globe:before {
  content: "\e058";
}
.icon-globe-location:before {
  content: "\e059";
}
.icon-golf:before {
  content: "\e05a";
}
.icon-gondola-lift:before {
  content: "\e05b";
}
.icon-grass:before {
  content: "\e05c";
}
.icon-green-tag:before {
  content: "\e05d";
}
.icon-gridlines:before {
  content: "\e05e";
}
.icon-hairdryer:before {
  content: "\e05f";
}
.icon-half-heart:before {
  content: "\e060";
}
.icon-half-star:before {
  content: "\e061";
}
.icon-hamburger:before {
  content: "\e062";
}
.icon-hanger:before {
  content: "\e063";
}
.icon-harddrive:before {
  content: "\e064";
}
.icon-healthy-heart:before {
  content: "\e065";
}
.icon-heart:before {
  content: "\e066";
}
.icon-heart-mobile:before {
  content: "\e067";
}
.icon-heart-shape:before {
  content: "\e068";
}
.icon-helicopter:before {
  content: "\e069";
}
.icon-help:before {
  content: "\e06a";
}
.icon-high-battery:before {
  content: "\e06b";
}
.icon-high-volume:before {
  content: "\e06c";
}
.icon-highlight:before {
  content: "\e06d";
}
.icon-highway-sign:before {
  content: "\e06e";
}
.icon-home:before {
  content: "\e06f";
}
.icon-hook:before {
  content: "\e070";
}
.icon-horizontal-bar-chart:before {
  content: "\e071";
}
.icon-hospital:before {
  content: "\e072";
}
.icon-hot-sauce:before {
  content: "\e073";
}
.icon-house-sale:before {
  content: "\e074";
}
.icon-ice-cream:before {
  content: "\e075";
}
.icon-ice-skate:before {
  content: "\e076";
}
.icon-idea:before {
  content: "\e077";
}
.icon-import-file:before {
  content: "\e078";
}
.icon-inbox:before {
  content: "\e079";
}
.icon-index:before {
  content: "\e07a";
}
.icon-info:before {
  content: "\e07b";
}
.icon-island:before {
  content: "\e07c";
}
.icon-jerrycan:before {
  content: "\e07d";
}
.icon-jpg:before {
  content: "\e07e";
}
.icon-jumprope:before {
  content: "\e07f";
}
.icon-key:before {
  content: "\e080";
}
.icon-knife:before {
  content: "\e081";
}
.icon-knob:before {
  content: "\e082";
}
.icon-laptop:before {
  content: "\e083";
}
.icon-layers:before {
  content: "\e084";
}
.icon-layout:before {
  content: "\e085";
}
.icon-leaf:before {
  content: "\e086";
}
.icon-left:before {
  content: "\e087";
}
.icon-left-right:before {
  content: "\e088";
}
.icon-lightbulb:before {
  content: "\e089";
}
.icon-line-chart-clipboard:before {
  content: "\e08a";
}
.icon-line-charts:before {
  content: "\e08b";
}
.icon-link:before {
  content: "\e08c";
}
.icon-list:before {
  content: "\e08d";
}
.icon-loading:before {
  content: "\e08e";
}
.icon-location:before {
  content: "\e08f";
}
.icon-lock:before {
  content: $pika-lock;
}
.icon-lock-file:before {
  content: "\e091";
}
.icon-lock-folder:before {
  content: "\e092";
}
.icon-lock-mobile:before {
  content: "\e093";
}
.icon-locomotive:before {
  content: "\e094";
}
.icon-login:before {
  content: "\e095";
}
.icon-logout:before {
  content: "\e096";
}
.icon-low-battery:before {
  content: "\e097";
}
.icon-low-volume:before {
  content: "\e098";
}
.icon-luggage:before {
  content: "\e099";
}
.icon-map:before {
  content: "\e09a";
}
.icon-map-location:before {
  content: "\e09b";
}
.icon-measuring-cup:before {
  content: "\e09c";
}
.icon-medal:before {
  content: "\e09d";
}
.icon-medical-cross:before {
  content: "\e09e";
}
.icon-medical-thermometer:before {
  content: "\e09f";
}
.icon-medium-battery:before {
  content: "\e0a0";
}
.icon-megaphone:before {
  content: "\e0a1";
}
.icon-merge:before {
  content: "\e0a2";
}
.icon-microphone:before {
  content: "\e0a3";
}
.icon-minisafe:before {
  content: "\e0a4";
}
.icon-minus:before {
  content: "\e0a5";
}
.icon-mobile-dollar-payment:before {
  content: "\e0a6";
}
.icon-mobile-euro-payment:before {
  content: "\e0a7";
}
.icon-money-bag:before {
  content: "\e0a8";
}
.icon-money-withdrawal:before {
  content: "\e0a9";
}
.icon-motorcycle:before {
  content: "\e0aa";
}
.icon-move:before {
  content: "\e0ab";
}
.icon-movie-folder:before {
  content: "\e0ac";
}
.icon-moving-box:before {
  content: "\e0ad";
}
.icon-music:before {
  content: "\e0ae";
}
.icon-navigate:before {
  content: "\e0af";
}
.icon-navigate-down:before {
  content: "\e0b0";
}
.icon-navigate-left:before {
  content: "\e0b1";
}
.icon-navigate-right:before {
  content: "\e0b2";
}
.icon-navigate-up:before {
  content: "\e0b3";
}
.icon-navigation-device:before {
  content: "\e0b4";
}
.icon-navigation-mobile:before {
  content: "\e0b5";
}
.icon-new:before {
  content: "\e0b6";
}
.icon-newspaper:before {
  content: "\e0b7";
}
.icon-next-page:before {
  content: "\e0b8";
}
.icon-no-entrance:before {
  content: "\e0b9";
}
.icon-no-smoking:before {
  content: "\e0ba";
}
.icon-notebook:before {
  content: "\e0bb";
}
.icon-office-building:before {
  content: "\e0bc";
}
.icon-office-building-2:before {
  content: "\e0bd";
}
.icon-olive-oil:before {
  content: "\e0be";
}
.icon-one-die:before {
  content: "\e0bf";
}
.icon-online-banking:before {
  content: "\e0c0";
}
.icon-open-book:before {
  content: "\e0c1";
}
.icon-open-folder:before {
  content: "\e0c2";
}
.icon-outbox:before {
  content: "\e0c3";
}
.icon-outlet:before {
  content: "\e0c4";
}
.icon-oven:before {
  content: "\e0c5";
}
.icon-page-curl:before {
  content: "\e0c6";
}
.icon-paint-roller:before {
  content: "\e0c7";
}
.icon-pan-on-fire:before {
  content: "\e0c8";
}
.icon-paperclip:before {
  content: "\e0c9";
}
.icon-passport:before {
  content: "\e0ca";
}
.icon-pause:before {
  content: $pika-pause;
}
.icon-pdf:before {
  content: "\e0cc";
}
.icon-pencil:before {
  content: "\e0cd";
}
.icon-pencil-2:before {
  content: "\e0ce";
}
.icon-pencil-disabled:before {
  content: "\e0cf";
}
.icon-pepper-mill:before {
  content: "\e0d0";
}
.icon-percentage:before {
  content: "\e0d1";
}
.icon-phone:before {
  content: "\e0d2";
}
.icon-phone-disabled:before {
  content: "\e0d3";
}
.icon-phone-mobile:before {
  content: "\e0d4";
}
.icon-photo:before {
  content: "\e0d5";
}
.icon-picnic-table:before {
  content: "\e0d6";
}
.icon-picture-file:before {
  content: "\e0d7";
}
.icon-picture-folder:before {
  content: "\e0d8";
}
.icon-pie-chart:before {
  content: "\e0d9";
}
.icon-pie-chart-2:before {
  content: "\e0da";
}
.icon-piggy-bank:before {
  content: "\e0db";
}
.icon-pill:before {
  content: "\e0dc";
}
.icon-pill-bottle:before {
  content: "\e0dd";
}
.icon-pin:before {
  content: "\e0de";
}
.icon-pin-note:before {
  content: "\e0df";
}
.icon-pirate-ship:before {
  content: "\e0e0";
}
.icon-pizza:before {
  content: "\e0e1";
}
.icon-play:before {
  content: $pika-play;
}
.icon-play-video:before {
  content: $pika-play-video;
}
.icon-plug:before {
  content: "\e0e4";
}
.icon-plus:before {
  content: "\e0e5";
}
.icon-png:before {
  content: "\e0e6";
}
.icon-power-mobile:before {
  content: "\e0e7";
}
.icon-ppt:before {
  content: "\e0e8";
}
.icon-price-tag:before {
  content: "\e0e9";
}
.icon-printer:before {
  content: "\e0ea";
}
.icon-pushpin:before {
  content: "\e0eb";
}
.icon-puzzle:before {
  content: "\e0ec";
}
.icon-quill:before {
  content: "\e0ed";
}
.icon-quotes:before {
  content: "\e0ee";
}
.icon-radio:before {
  content: "\e0ef";
}
.icon-rain:before {
  content: "\e0f0";
}
.icon-rainbow:before {
  content: "\e0f1";
}
.icon-read-online:before {
  content: "\e0f2";
}
.icon-realty-online:before {
  content: "\e0f3";
}
.icon-receipt:before {
  content: "\e0f4";
}
.icon-record:before {
  content: "\e0f5";
}
.icon-redirect:before {
  content: "\e0f6";
}
.icon-refresh:before {
  content: "\e0f7";
}
.icon-reload:before {
  content: "\e0f8";
}
.icon-remove-calendar:before {
  content: "\e0f9";
}
.icon-remove-cart:before {
  content: "\e0fa";
}
.icon-remove-file:before {
  content: "\e0fb";
}
.icon-remove-user:before {
  content: "\e0fc";
}
.icon-repeat:before {
  content: "\e0fd";
}
.icon-replay:before {
  content: $pika-replay;
}
.icon-reply:before {
  content: "\e0ff";
}
.icon-restaurant:before {
  content: "\e100";
}
.icon-resume:before {
  content: "\e101";
}
.icon-retweet:before {
  content: "\e102";
}
.icon-rewind:before {
  content: "\e103";
}
.icon-ribbon:before {
  content: "\e104";
}
.icon-right:before {
  content: "\e105";
}
.icon-rightward:before {
  content: "\e106";
}
.icon-road:before {
  content: "\e107";
}
.icon-rocket:before {
  content: "\e108";
}
.icon-romantic-book:before {
  content: "\e109";
}
.icon-rows:before {
  content: "\e10a";
}
.icon-rss:before {
  content: "\e10b";
}
.icon-rss-mobile:before {
  content: "\e10c";
}
.icon-rubber-eraser:before {
  content: "\e10d";
}
.icon-rubber-stamp:before {
  content: "\e10e";
}
.icon-safe:before {
  content: "\e10f";
}
.icon-sailboat:before {
  content: "\e110";
}
.icon-salad:before {
  content: "\e111";
}
.icon-salt:before {
  content: "\e112";
}
.icon-sausage:before {
  content: "\e113";
}
.icon-save:before {
  content: "\e114";
}
.icon-scale-down:before {
  content: "\e115";
}
.icon-scale-up:before {
  content: "\e116";
}
.icon-scales-of-justice:before {
  content: "\e117";
}
.icon-scatter-charts:before {
  content: "\e118";
}
.icon-screenshot:before {
  content: "\e119";
}
.icon-search:before {
  content: "\e11a";
}
.icon-search-book:before {
  content: "\e11b";
}
.icon-search-house:before {
  content: "\e11c";
}
.icon-secure-folder:before {
  content: "\e11d";
}
.icon-seedling:before {
  content: "\e11e";
}
.icon-send:before {
  content: "\e11f";
}
.icon-settings:before {
  content: "\e120";
}
.icon-settings-file:before {
  content: "\e121";
}
.icon-share:before {
  content: "\e122";
}
.icon-share-2:before {
  content: "\e123";
}
.icon-shop-online:before {
  content: "\e124";
}
.icon-shopping-bag:before {
  content: "\e125";
}
.icon-shopping-basket:before {
  content: "\e126";
}
.icon-shopping-cart:before {
  content: "\e127";
}
.icon-shower:before {
  content: "\e128";
}
.icon-shrimp:before {
  content: "\e129";
}
.icon-shuffle:before {
  content: "\e12a";
}
.icon-six-die:before {
  content: "\e12b";
}
.icon-skip-back:before {
  content: "\e12c";
}
.icon-skip-forward:before {
  content: "\e12d";
}
.icon-smart-phone:before {
  content: "\e12e";
}
.icon-snow-crystal:before {
  content: "\e12f";
}
.icon-snowy-pine-tree:before {
  content: "\e130";
}
.icon-soccer:before {
  content: "\e131";
}
.icon-solar-panel:before {
  content: "\e132";
}
.icon-spaghetti:before {
  content: "\e133";
}
.icon-spaghetti-2:before {
  content: "\e134";
}
.icon-speaker:before {
  content: "\e135";
}
.icon-spoon-fork:before {
  content: "\e136";
}
.icon-star:before {
  content: $pika-star;
}
.icon-statistics:before {
  content: "\e138";
}
.icon-steak:before {
  content: "\e139";
}
.icon-steering-wheel:before {
  content: "\e13a";
}
.icon-stethoscope:before {
  content: "\e13b";
}
.icon-stock:before {
  content: "\e13c";
}
.icon-stop:before {
  content: "\e13d";
}
.icon-stopwatch:before {
  content: "\e13e";
}
.icon-stove:before {
  content: "\e13f";
}
.icon-street-location:before {
  content: "\e140";
}
.icon-suitcase:before {
  content: "\e141";
}
.icon-sun:before {
  content: "\e142";
}
.icon-sunscreen:before {
  content: "\e143";
}
.icon-supplements:before {
  content: "\e144";
}
.icon-swimming-pool:before {
  content: "\e145";
}
.icon-sync:before {
  content: "\e146";
}
.icon-syringe:before {
  content: "\e147";
}
.icon-table-tennis:before {
  content: "\e148";
}
.icon-tablet:before {
  content: "\e149";
}
.icon-tag:before {
  content: "\e14a";
}
.icon-tape-measure:before {
  content: "\e14b";
}
.icon-target:before {
  content: "\e14c";
}
.icon-taxi:before {
  content: "\e14d";
}
.icon-teddy-bear:before {
  content: "\e14e";
}
.icon-temperature:before {
  content: "\e14f";
}
.icon-tennis:before {
  content: "\e150";
}
.icon-text:before {
  content: "\e151";
}
.icon-text-file:before {
  content: "\e152";
}
.icon-text-file-2:before {
  content: "\e153";
}
.icon-text-mobile:before {
  content: "\e154";
}
.icon-this-side-up:before {
  content: "\e155";
}
.icon-three-die:before {
  content: "\e156";
}
.icon-thumbnails:before {
  content: "\e157";
}
.icon-thumbs-down:before {
  content: "\e158";
}
.icon-thumbs-up:before {
  content: "\e159";
}
.icon-thunderstorm:before {
  content: "\e15a";
}
.icon-tip:before {
  content: "\e15b";
}
.icon-toaster:before {
  content: "\e15c";
}
.icon-tornado:before {
  content: "\e15d";
}
.icon-tractor:before {
  content: "\e15e";
}
.icon-traffic:before {
  content: "\e106";
}
.icon-traffic-camera:before {
  content: "\e15f";
}
.icon-traffic-light:before {
  content: "\e160";
}
.icon-train:before {
  content: "\e161";
}
.icon-train-tunnel:before {
  content: "\e162";
}
.icon-undo:before {
  content: "\21BA";
}
.icon-trash:before {
  content: "\e163";
}
.icon-tree:before {
  content: "\e164";
}
.icon-tropical-fish:before {
  content: "\e165";
}
.icon-truck:before {
  content: "\e166";
}
.icon-tunnel:before {
  content: "\e167";
}
.icon-turkey:before {
  content: "\e168";
}
.icon-twinbed:before {
  content: "\e169";
}
.icon-two-die:before {
  content: "\e16a";
}
.icon-umbrella:before {
  content: "\e16b";
}
.icon-unlock:before {
  content: $pika-unlock;
}
.icon-up:before {
  content: "\e16d";
}
.icon-up-left:before {
  content: "\e16e";
}
.icon-up-right:before {
  content: "\e16f";
}
.icon-upload:before {
  content: "\e170";
}
.icon-upload-file:before {
  content: "\e171";
}
.icon-upload-folder:before {
  content: "\e172";
}
.icon-upwards-bar-chart:before {
  content: "\e173";
}
.icon-upwards-line-chart:before {
  content: "\e174";
}
.icon-user:before {
  content: "\e175";
}
.icon-user-group:before {
  content: "\e176";
}
.icon-user-profile:before {
  content: "\e177";
}
.icon-users:before {
  content: "\e178";
}
.icon-video-camera:before {
  content: "\e179";
}
.icon-view:before {
  content: "\e17a";
}
.icon-vinyl-record:before {
  content: "\e17b";
}
.icon-volume:before {
  content: "\e17c";
}
.icon-walking:before {
  content: "\e17d";
}
.icon-walky-talky:before {
  content: "\e17e";
}
.icon-warehouse:before {
  content: "\e17f";
}
.icon-watch:before {
  content: "\e180";
}
.icon-waterbottle:before {
  content: "\e181";
}
.icon-weather:before {
  content: "\e182";
}
.icon-weather-online:before {
  content: "\e183";
}
.icon-weather-vane:before {
  content: "\e184";
}
.icon-weight:before {
  content: "\e185";
}
.icon-weight-scale:before {
  content: "\e186";
}
.icon-wheelchair:before {
  content: "\e187";
}
.icon-wifi:before {
  content: "\e188";
}
.icon-wifi-book:before {
  content: "\e189";
}
.icon-wifi-envelope:before {
  content: "\e18a";
}
.icon-wifi-open-book:before {
  content: "\e18b";
}
.icon-wind:before {
  content: "\e18c";
}
.icon-wine-bottle:before {
  content: "\e18d";
}
.icon-wine-glass:before {
  content: "\e18e";
}
.icon-world:before {
  content: "\e18f";
}
.icon-wrench:before {
  content: "\e190";
}
.icon-write-check:before {
  content: "\e191";
}
.icon-yoga:before {
  content: "\e192";
}
.icon-zip:before {
  content: "\e193";
}
.icon-zoom-in:before {
  content: $pika-zoom-in;
}
.icon-zoom-out:before {
  content: "\e195";
}

// TMPika icons
$icon-compass: '\e671';
$icon-compose: '\1f4dd';
$icon-bookmark: '\1f516';
$icon-open-book: '\1f4d6';
$icon-books: '\1f4da';
$icon-notice: '\e973';
$icon-analytics: '\e575';
$icon-user-groups: '\e400';
$icon-user-add: '\e401';
$icon-user: '\e406';
$icon-chevron-right: '\e607';
$icon-chevron-left: '\e608';
$icon-checkmark: '\2713';
$icon-star: '\22c6';
$icon-cross: '\2421';
$icon-lock: '\1f512';
$icon-lock-open: '\1f513';
$icon-arrow-right: '\e601';
$icon-arrow-left: '\e603';
$icon-arrow-up: '\e605';
$icon-arrow-down: '\e606';
$icon-close: '\e604';
$icon-search: '\1f50e';
$icon-bell: '\1f514';
$icon-download: '\eb01';
$icon-warning: '\26a0';
$icon-play: '\25b6';
$icon-document: '\e903';
$icon-check: '\e2b3';
$icon-retry: '\21bb';
$icon-undo: '\21ba';
$icon-zoom: '\e002';
$icon-reset: '\eb87';
$icon-options: '\2699';
$icon-pencil: '\270e';
$icon-speaker: '\1f50a';
$icon-play: '\25b6';
$icon-stop: '\25a0';
$icon-pause: '\e8a0';
$icon-settings: '\2699';
$icon-flag: '\2691';
$icon-timer: '\23f2';
$icon-trash: '\e0d0';
$icon-upload: '\eb41';
$icon-camera: '\1f4f7';
$icon-help: '\2753';
$icon-helpr: '\201c';
$icon-groups: '\1f465';

// TM Pika Icons
$tm-icons: (
  compass: $icon-compass,
  compose: $icon-compose,
  bookmark: $icon-bookmark,
  open-book: $icon-open-book,
  books: $icon-books,
  notice: $icon-notice,
  analytics: $icon-analytics,
  user-groups: $icon-user-groups,
  user-add: $icon-user-add,
  user: $icon-user,
  chevron-right: $icon-chevron-right,
  chevron-left: $icon-chevron-left,
  checkmark: $icon-checkmark,
  star: $icon-star,
  cross: $icon-cross,
  lock: $icon-lock,
  lock-open: $icon-lock-open,
  arrow-right: $icon-arrow-right,
  arrow-left: $icon-arrow-left,
  arrow-up: $icon-arrow-up,
  arrow-down: $icon-arrow-down,
  close: $icon-close,
  search: $icon-search,
  bell: $icon-bell,
  download: $icon-download,
  warning: $icon-warning,
  document: $icon-document,
  check: $icon-check,
  retry: $icon-retry,
  undo: $icon-undo,
  zoom: $icon-zoom,
  reset: $icon-reset,
  options: $icon-options,
  pencil: $icon-pencil,
  speaker: $icon-speaker,
  play: $icon-play,
  stop: $icon-stop,
  pause: $icon-pause,
  settings: $icon-settings,
  flag: $icon-flag,
  timer: $icon-timer,
  trash: $icon-trash,
  upload: $icon-upload,
  camera: $icon-camera,
  help: $icon-help,
  helpr: $icon-helpr,
  groups: $icon-groups,
);

[class^="icon-tm-"]:before,
[class*=" icon-tm-"]:before {
  font-family: $icon !important;
}

@each $name, $icon in $tm-icons {
  [class^="icon-tm-#{$name}"]:before, [class*=" icon-tm-#{$name}"]:before {
    content: $icon;
  }
}

.icon-tm-chevron-left:before,
.icon-tm-chevron-right:before {
  margin-left: -.3em;
  margin-right: -.3em;
}

// Redesign svg icons in simplified
.simplified-icon {
  fill: currentColor;
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

