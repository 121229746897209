@import "~izitoast/dist/css/iziToast.min.css";

$izitoast-default-padding: 15px;

.iziToast-wrapper-bottomCenter {
	padding-bottom: 0;
	z-index: 9999;
}

.iziToast--fullwidth {
	border-radius: 0;
	margin: 0 -#{$izitoast-default-padding} 0 -#{$izitoast-default-padding};
	width: 100vw;

	&::before,
	&::after {
		background-color: inherit;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		@include mobile() {
			z-index: -1;
		}
	}
}

.iziToast .iziToast-body .iziToast-message {
	color: $base-text-color;
}

// Success
.iziToast.iziToast-color-green {
	background-color: $tm-good;
	border-color: $tm-good-dark;
}

// Info
.iziToast.iziToast-color-blue {
  background-color: $tm-perfect;
  border-color: $tm-perfect-dark;
}

// Warning
.iziToast.iziToast-color-orange {
	background-color: $tm-unsatisfactory;
	border-color: $tm-unsatisfactory-dark;
}

// Error
.iziToast.iziToast-color-red {
	background-color: $tm-poor;
	border-color: $tm-poor-dark;
}

.iziToast.iziToast-color-red,
.iziToast.iziToast-color-orange {
	.iziToast-body .iziToast-message {
		color: $white;

		a {
			color: $white;
			text-decoration: underline;
		}
	}
}
