.results-bar {
  height: 8px;
  background: $white;
  border-radius: $radius-small;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;

  &--progress {
    flex-grow: 1;
    height: 16px;
    margin-top: 4px;
  }
}
.results-bar__category {
  transition: width 1s ease-in-out;
  width: 0;
  @include score-background();
}

.results-bar__category--disabled {
  margin-left: auto;
}
