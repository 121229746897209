// redesign score colors
$redesign-score-none: #D7D7D7;
$redesign-score-poor: #E3024F;
$redesign-score-unsatisfactory: #F39200;
$redesign-score-good: #97BE0C;
$redesign-score-perfect: #018B36;
$redesign-score-archived: #D2CEC4; // Gray.475
$redesign-score-disabled: #D7D7D7; // Gray.400

$redesign-score-medium-none: #C9C5BC;
$redesign-score-medium-poor: #FFD7E3;
$redesign-score-medium-unsatisfactory: #F2DC8F;
$redesign-score-medium-good: #CDF0B1;
$redesign-score-medium-perfect: #CDF0B1;
$redesign-score-medium-archived: #D7D7D7; // Gray.400

$redesign-score-archived-text: #8B867B; // Gray.600



// score categories
$score-categories: (
  poor: (
    color: $white,
    background-color-hover: $redesign-score-medium-poor,
    background-color: $redesign-score-poor,
    background-color-inverse: unset
  ),
  unsatisfactory: (
    color: $white,
    background-color-hover: $redesign-score-medium-unsatisfactory,
    background-color: $redesign-score-unsatisfactory,
    background-color-inverse: unset
  ),
  satisfactory: (
    color: $white,
    background-color-hover: $redesign-score-medium-good,
    background-color: $redesign-score-good,
    background-color-inverse: unset
  ),
  good: (
    color: $white,
    background-color-hover: $redesign-score-medium-good,
    background-color: $redesign-score-good,
    background-color-inverse: unset
  ),
  perfect: (
    color: $white,
    background-color-hover: $redesign-score-medium-perfect,
    background-color: $redesign-score-perfect,
    background-color-inverse: unset
  ),
  archived: (
    color: $redesign-score-archived-text,
    background-color-hover: $redesign-score-medium-archived,
    background-color: $redesign-score-archived,
    background-color-inverse: unset
  ),
  disabled: (
    color: $white,
    background-color-hover: rgba($redesign-score-disabled, 0.7),
    background-color: rgba($redesign-score-disabled, 0.7),
    background-color-inverse: unset
  ),
  reviewDisabled: (
    color: $tm-neutral-extraheavy,
    background-color-hover: $redesign-score-medium-archived,
    background-color: $white,
    background-color-inverse: $redesign-score-archived-text
  ),
  none: (
    color: $base-text-color,
    background-color-hover: $white,
    background-color: $white,
    background-color-inverse: unset
  )
);

@mixin score-background($prefix: '') {
  @each $category, $colors in $score-categories {
    &--#{$prefix}#{$category} {
      background-color: map-get($colors, background-color);
    }
  }
}

@mixin score-color() {
  @each $category, $colors in $score-categories {
    &--#{$category} {
      color: map-get($colors, background-color);
    }
  }
}
@mixin score-box-shadow($value-prefix) {
  @each $category, $colors in $score-categories {
    &--#{$category} {
      box-shadow: #{$value-prefix} darken(map-get($colors, background-color-hover), 30%);
    }
  }
}

@mixin score-label($prefix: '', $hover: true) {
  @each $category, $colors in $score-categories {
    &--#{$prefix}#{$category} {
      color: map-get($colors, color);
      background-color: map-get($colors, background-color);

      .score-label__education-level-marker {
        color: map-get($colors, background-color);
      }

      @if $hover == true {
        &:hover {
          color: map-get($colors, color);
          background-color: map-get($colors, background-color-hover);
        }
      }
    }

    &--#{$prefix}#{$category}-inverse {
      color: map-get($colors, background-color);
      background-color: map-get($colors, background-color-inverse);

      @if $hover == true {
        &:hover {
          color: map-get($colors, background-color-hover);
          background-color: unset;
        }
      }
    }
  }
}

.score-color {
  @include score-color();
}
