// QTI: Choice multiple

// for now only the student result page uses this component
// in due time it should also be used on the exercise page
// itself
.choice-multiple-input {
  margin: 1rem 0;

  .choice-multiple__option {
    margin: 0;
    position: relative;

    &:not(.choice-multiple__option--horizontal):not(:last-child) {
      margin-top: 1rem;
    }
  }

  input[type="checkbox"] {
    display: inline-block;
    position: absolute;
    left: .2em;
    top: .5em;
  }

  label {
    border: 1px solid transparent;
    display: inline-block;
    padding: 2px .3em 2px 2em;
    position: relative;
    border-top: 0 none;

    &:before {
      font-family: $icon;
      line-height: 1.95;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      vertical-align: middle;
      width: 1.5em;
    }
  }

  .correct + label,
  .predefined + label {
    background-color: $exercise-correct-light;
    color: $exercise-correct-dark;
    border-bottom-color: transparent;

    &:before {
      background-color: $tm-good;
      color: #fff;
      content: $icon-checkmark;
    }
  }

  .predefined + label {
    &:before {
      content: $icon-star;
    }
  }

  .incorrect + label {
    border-bottom: 1px solid $exercise-incorrect-light;
    color: $exercise-incorrect-dark;

    &:before {
      background-color: $exercise-incorrect-dark;
      border-bottom: 1px solid $exercise-incorrect-dark;
      color: #fff;
      content: $icon-cross;
    }
  }

  .missed + label {
    border: 1px dashed $exercise-correct-dark;
    color: $exercise-correct-dark;

    &:before {
      background-color: $exercise-correct-light;
      border-right: 1px dashed $exercise-correct-dark;
      color: $exercise-correct-dark;
      content: $icon-checkmark;
    }
  }
}

// the QTI Multiple Choice ordered list
.choice-multiple {
  list-style: none !important;
  margin: 0;

  &.list--horizontal {
    @media (min-width: 768px) {
      display: flex;
    }
  }

  .choice-multiple__option {
    list-style: none;
    margin: 0;
    padding: 0;

    &:not(.list-item--horizontal):not(:last-child) {
      margin-bottom: 1rem;
    }

    &.list-item--horizontal {
      @media (min-width: 768px) {
        margin-top: 0 !important;

        &:not(:last-child) {
          margin-right: 1rem !important;
        }
      }
    }
  }

  .selected-answer,
  .predefined-answer {
    color: $exercise-incorrect-dark;
    border-bottom-color: $exercise-incorrect-light;
  }

  .selected-answer.correct,
  .predefined-answer {
    background-color: $exercise-correct-light;
    border-bottom-color: transparent;
    border-top: 0 none;
    color: $exercise-correct-dark;
  }

  .selected-answer.missed {
    background-color: none;
    border: 1px dashed $exercise-correct-dark;
    color: $exercise-correct-dark;
  }

  .selected-answer:before,
  .predefined-answer:before {
    background-color: $exercise-incorrect-dark;
    color: #fff;
    content: $icon-cross;
    font-family: $icon;
    line-height: 1.8;
    padding-top: 2px; // the label also has 2px padding at the top
    position: absolute;
    top: 0;
    bottom: -1px; // pull it over the bottom border of the parent label
    left: 0;
    text-align: center;
    vertical-align: top;
    width: 1.5em;
  }

  .selected-answer.correct:before,
  .predefined-answer:before {
    background-color: $tm-good;
    content: $icon-checkmark;
  }

  .predefined-answer:before {
    content: $icon-star;
  }

  .selected-answer.missed:before {
    background-color: $exercise-correct-light;
    border-right: 1px dashed $exercise-correct-dark;
    bottom: 0;
    color: $exercise-correct-dark;
    content: $icon-checkmark;
  }

  .choice-multiple__option-content {
    border: 1px solid transparent;
    display: inline-block;
    padding-left: 2rem;
    position: relative;
  }

  input.choice_multiple__checkbox {
    position: absolute;
    top: .5rem !important;
    left: 5px;
  }

  .selected-answer input.choice_multiple__checkbox {
    left: -9999em;
  }
}
