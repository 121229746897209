$card-background-color: $white;
$card-border-color: $tm-neutral;

.content-card {
  background: $card-background-color;
  position: relative;
}

.content-card--stacked {
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $card-background-color;
    transition: all .2s;
    border: 1px solid $card-border-color;
  }

  &:before {
    top: 6px;
    left: 6px;
    z-index: -1;
    width: calc(100% - 9px);
  }

  &:after {
    top: 12px;
    left: 11px;
    z-index: -2;
    width: calc(100% - 18px);
  }
}

.content-card__header {
  padding: 0.75rem 0.75rem 0 0.75rem;
  border: 1px solid $card-border-color;
  border-bottom: 0;
  .labeled-title {
    font-size: 1.35rem;
  }
  .labeled-title__text {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.content-card__header--image {
  padding-bottom: 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: linear-gradient(to top, transparent 0%, rgba($tm-neutral-extrablack, .9) 100%);
}

.content-card__image {
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: $tm-neutral-extrablack;

  &--shorter { padding-top: calc(180 / 460 * 100%); }
}

.content-card__body {
  border: 1px solid $card-border-color;
  border-top: none;
  padding: 1rem;

  .labeled-title {
    font-size: 1.5rem;
    @include from($tablet) { font-size: 1.25rem; }
  }
}

.content-card__list-item {
  margin-bottom: 0.5rem;
  &:last-child { margin-bottom: 0; }
  .labeled-title { font-size: 1rem; }
}

