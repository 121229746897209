.content-navigation-bar {
  border-top: 1px solid $tm-neutral-light;

  @include tablet() {
    background: $white;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1.5rem;
  @include tablet() {
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
    min-height: $navbar-height;
  }
}

.content-navigation-bar__previous,
.content-navigation-bar__next {
  padding: 0.5rem 0;
  @include tablet() { max-width: 45%; }

  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-navigation-bar__chevron {
  font-size: 1.5rem;
}

.content-navigation-bar__next { margin-left: auto; }
.content-navigation-bar__previous { margin-right: auto; }
