//
// Modify Groupservices styling
//

#gsGui div.gsgui-main-column div.row div.col-1.text-right button {
  display: none !important;
}

#gsGui.bootstrap .modal-dialog {
  margin-top: 120px;
}
