$search-facet-active-color: $link-color;
$search-highlight-color: $link-color;

/* Navbar */

.search-form__dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;

  /* Radius for drop-down menus */
  border-radius: 0 0 0.5rem 0.5rem;
}

/* Modal */

.search-modal {
  background-color: $tm-neutral-light;

  &:after {
    background: url(../images/logo-colour-bar.png) no-repeat center bottom;
    background-size: cover;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
  }
}

/* Form */
.search-form__input {
  width: 25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  outline-style: none;
  -webkit-appearance: none;
}

.search-form__method {
  font-size: 0.9rem;
}

/* Facets */

.search-modal-facets {
  display: flex;
  flex-direction: row;
}

.search-modal-facet {
  line-height: 3rem;
  flex: 1;
  text-align: center;
  font-size: 0.85rem;
  font-weight: bold;

  border: 1px $tm-neutral-dark solid;
  background: $tm-neutral-pale;

  &:hover {
    background: $tm-neutral-light;
  }

  cursor: pointer;

  &--active {
    background: $tm-neutral-dark;
  }
}

.search-highlight {
  color: $search-highlight-color;
  font-weight: bold
}

.search-modal-result {
  background-color: $white;
  color: $body-color;
  display: block;
  border: 1px $tm-neutral-dark solid;
  &:hover {
    background-color: $tm-neutral-light;

    .search-modal-result__breadcrumbs {
      background-color: darken($tm-neutral-pale, 10%);
    }
  }

  &__breadcrumbs {
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
    color: $tm-neutral-extraheavy;
    background-color: $tm-neutral-pale;
  }
  &__breadcrumb {
    font-weight: bold;
    &:after {
      content: '>';
      margin: 0 0.5rem;
    }
    &:last-child {
      font-weight: normal;
      &:after {
        content: '';
        margin: 0;
      }
    }
  }
  &__content {
    padding: 0.2rem 0.5rem 0.5rem;
    font-size: 0.9rem;
  }
  &__title {
    font-weight: bold;
  }
  &__snippet {
    font-size: 0.8rem;
  }
}

.search-modal-no-results, .search-modal-loader {
  text-align: center;

  &__icon {
    font-size: 5rem;
    line-height: 1;
  }
}
.search-modal-results {
  max-height: 50vh;
  overflow: auto;
}

/* Loader */
.search-modal-loader {
  &__icon {
    animation-name: colorFade;
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;

    .search-modal-loader--load-more & {
      font-size: 3rem;
    }
  }

  &--load-more {
    background: $white;
  }
}

@-webkit-keyframes colorFade {
  0% {
    color: $base-text-color;
  }
  50.0% {
    color: $search-facet-active-color;
  }
  100.0% {
    color: $base-text-color;
  }
}

@keyframes colorFade {
  0% {
    color: $base-text-color;
  }
  50.0% {
    color: $search-facet-active-color;
  }
  100.0% {
    color: $base-text-color;
  }
}
